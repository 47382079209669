import { EditorForm } from './editor';
import { Dato, Repositorio } from './dato';
import { NavController } from '@ionic/angular';
import { EventsService } from '../servicios/events.service';

/** Esta clase define a una opcion del app */
export class ExpoConfigMenuOpcion {

  // ------------- Opciones para el menu ---------------------------------------
  id: string;
  titulo: string;
  tituloId?: string;
  desc?: string;
  ionicon: string;
  ruta: string;
  ocultar?: boolean;
  interno ?: boolean; // no de menu
  disabled?: boolean;
  accion?: string;
  activada?: boolean;
  grupos?: string[];
  badge?: boolean;
  badgeContent?: any;
  onMenuClick?: (events: EventsService, navCtrl: NavController) => boolean;

  // ------------- Opciones para el editor ---------------------------------------
  reloadEvent?: string;
  repositorio?: Repositorio;
  datoUnico?: boolean;
  endpoint?: string;
  ctor?: new () => Dato;
  editorActions?: EditorOption[];
  disableDelete?: boolean;
  disableAdd?: boolean;
  disableEdit?: boolean;
  noDialogs?: boolean;
  saveWithPatch?: boolean;
  editVars?: {};
  backUrl?: string;
  onDatoSaved?: (dato: Dato, events: EventsService, navCtrl: NavController) => boolean;
  onDatoClick?: (dato: Dato, events: EventsService, navCtrl: NavController) => boolean;
  onLoad?: (paginaEditora: any) => Promise<void>;
  onCancelOverride?: () => void;
  customDialogues?: { savedText: string, failedText: string };
}

export class ExpoConfigMenu {
  id: string;
  titulo: string;
  ionicon: string;
  perfil: string;
  disabled?: boolean;
  onClose?: (menu: ExpoConfigMenu, events: EventsService) => void;
  opciones: ExpoConfigMenuOpcion[];
}

export class ExpoConfig {
  version: string;
  menus: ExpoConfigMenu[];
}

export class EditorOption {
  id: string;
  title: string;
  disabled?: boolean;
  inList?: boolean;
  action: (dato: Dato, src: any, events: EventsService, navCtrl: NavController) => void;
  isVisible: (dato: Dato, action: EditorOption) => boolean;
}
