import { Injectable, PLATFORM_ID } from '@angular/core';
import { WebapiService } from './webapi.service';
import { Storage } from '@ionic/storage';
import { ulid } from 'ulid';
// import * as lzutf8 from 'lzutf8';
import { version as appVersion } from '../../../package.json';
import { AsyncValue } from './tools';
const lzutf8: any = {};

@Injectable({
  providedIn: 'root'
})
export class CachedWebapiService {
  private cacheStores: { [name: string]: any; } = {};
  private cacheStoresArray: any[] = [];
  //   private storage: Storage;
  private enableCompression = false;
  private testCache = false;
  private dbName: string;
  private version: string;
  private storageAsync: AsyncValue<Storage> = new AsyncValue();

  constructor(private webApi: WebapiService) {
    this.version = '__' + appVersion;
    this.dbName = 'expo_cache';
    setInterval(() => {
      for (const store of this.cacheStoresArray) {
        store.retryNextInQueue();
      }
    }, 2000);
    const commonStorage = new Storage({
      name: this.dbName,
      storeName: this.dbName,
      driverOrder: ['indexeddb', 'localstorage']
    }, PLATFORM_ID);
    commonStorage.ready()
      .then((thing) => {
        // console.log(thing);
        this.storageAsync.setValue(commonStorage);
        this.removeOldData(commonStorage).then(() => { }).catch((err) => { console.error(err); });
      })
      .catch((err) => {
        console.error(err);
        this.storageAsync.setValue(null);
        console.error('Failed creating cache storage, caching will be disabled');
      });
  }

  private async removeOldData(storage: Storage) {
    try {
      await storage.forEach((val, key, iter) => {
        if (key.indexOf(this.version) <= 0) {
          storage.remove(key)
            .then(() => { console.log('Removing cache for "' + key + '" version not found'); })
            .catch((err) => { console.log('Failed clearing old cache'); });
        } else {
          // console.log('Cache for "' + key + '" is still valid. Version found in string.');
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  async get<T>(endpoint: string, type: new () => T, params?: any): Promise<any[]> {
    try {
      if (this.testCache) { throw new Error('Probando cache, este no es un error'); }
      const results = await this.webApi.get(endpoint, params);
      await this.updateCache(endpoint, results);
      return this.resultsToArray(results, type);
    } catch (err) {
      console.error(err);
      try {
        const store = await this.storageAsync.getValue();
        if (store) {
          const rawResults = await store.get(endpoint + this.version);
          if (rawResults) {
            console.log('Usando cache para ', endpoint);
            let parsedResults = [];
            if (this.enableCompression) {
              const promise = new Promise<string>((resolve, reject) => {
                lzutf8.decompressAsync(rawResults, { inputEncoding: 'StorageBinaryString' }, (res, err) => {
                  resolve(res);
                });
              });
              const compResult = await promise;
              parsedResults = JSON.parse(compResult);
            } else {
              parsedResults = JSON.parse(rawResults);
            }
            return this.resultsToArray(parsedResults, type);
          }
        }
      } catch (err) {
        console.error('Falló lectura de cache de' + endpoint, err);
      }
    }
    return [];
  }

  private async updateCache(endpoint: string, results: any) {
    try {
      const store = await this.storageAsync.getValue();
      if (store) {
        const rawResult = JSON.stringify(results);
        if (this.enableCompression) {
          lzutf8.compressAsync(rawResult, { outputEncoding: 'StorageBinaryString' }, async (compResult, error) => {
            await store.set(endpoint + this.version, compResult);
            // console.log('Cache actualizado para ', endpoint);
          });
        } else {
          await store.set(endpoint + this.version, rawResult);
          // console.log('Cache actualizado para ', endpoint);
        }
      }
    } catch (err) {
      console.error('Falló actualización de cache de' + endpoint, err);
    }
  }

  private resultsToArray<T>(data: any, type: new () => T): T[] {
    if (data.length !== undefined && data.length > 0) {
      // Returned array
      const retArray: T[] = [];
      for (const obj of data) {
        const instance = new type();
        Object.assign(instance, obj);
        retArray.push(instance);
      }
      return retArray;
    } else if (data && data.length === undefined) {
      // Convert returned object to array
      const instance = new type();
      Object.assign(instance, data);
      const retArray: T[] = [];
      retArray.push(instance);
      return retArray;
    } else {
      return [];
    }
  }

  async clearCache(name?: string) {
    try {
      const store = await this.storageAsync.getValue();
      if (name) {
        await store.remove(name);
      } else {
        await store.clear();
      }
    } catch (e) {
      console.error(e);
    }
  }

  async getStore<T>(name: string, endpoint: string, ctr: new () => any): Promise<CacheStore<T>> {
    if (this.cacheStores[name]) { return this.cacheStores[name]; }
    const newStore = new CacheStore<T>(this, this.webApi, name, endpoint, ctr);
    this.cacheStores[name] = newStore;
    this.cacheStoresArray.push(newStore);
    await newStore.setup();
    return newStore;
  }
}

export class CacheStore<T> {
  results: T[] = [];
  uploadedStore: Storage;
  pendingStore: Storage;
  pending: T[] = [];
  logEnabled = true;
  saveInterval = 1000;
  saving = false;
  onSaved: (obj: T) => void;

  // tslint:disable-next-line: max-line-length
  constructor(public webapiCached: CachedWebapiService, public webapi: WebapiService, public name: string, public endpoint: string, public ctr: new () => T) {
  }

  async setup() {
    this.uploadedStore = new Storage({
      name: 'expo_' + this.name + '_stored',
      storeName: 'expo_' + this.name + '_stored',
      driverOrder: ['sqlite', 'indexeddb', 'localstorage']
    }, PLATFORM_ID);
    await this.uploadedStore.ready();
    this.pendingStore = new Storage({
      name: 'expo_' + this.name + '_pend',
      storeName: 'expo_' + this.name + '_pend',
      driverOrder: ['sqlite', 'indexeddb', 'localstorage']
    }, PLATFORM_ID );
    await this.pendingStore.ready();
    this.pending = [];
    try {
      await this.pendingStore.forEach((val, key, iterNumb) => {
        this.pending.push(val);
      });
    } catch (err) {
      this.logError(err);
    }
    this.log('Hay ' + this.pending.length + ' pendientes por enviar');
    if (this.pending.length) {
      this.retryNextInQueue();
    }
  }

  async save(object: T) {
    const obj = object as any;
    try {
      // # Save object
      let res: T = null;
      if (obj.id !== undefined) {
        res = await this.webapi.put(this.endpoint + obj.id + '/', object, { noRetries: true });
      } else {
        res = await this.webapi.post(this.endpoint, object, { noRetries: true });
      }
      Object.assign(obj, res);
      if (this.onSaved) { this.onSaved(obj); }

    } catch (err) {
      this.logError('Failed remote save ', err);
      if (!obj.ulid) {

        // # Add to queue (and dont raise error)
        try {
          await this.pendingStore.set(this.getObjectID(obj), obj);
          this.pending.push(obj);
          this.log('Fallo guardado, objeto en cola', obj);

        } catch (err) {
          console.error('Failed local save', err);
          throw err;
        }
      } else {

        // # Pass error
        throw new Error('No se pudo guardar');
      }
    }
  }

  async getAsync(): Promise<T[]> {
    const result = await this.webapiCached.get(this.endpoint, this.ctr);
    // return result;
    for (const pending of this.pending) {
      result.push(pending);
    }
    return result;
  }

  private log(stuff: any, obj?: any) {
    if (this.logEnabled) {
      if (obj) {
        console.log('[' + this.name + '] ' + stuff, obj);
      } else {
        console.log('[' + this.name + '] ' + stuff);
      }
    }
  }

  private logError(stuff: any, obj?: any) {
    if (this.logEnabled) {
      if (obj) {
        console.log('[' + this.name + '] ' + stuff, obj);
      } else {
        console.error('[' + this.name + '] ' + stuff);
      }
    }
  }

  public async retryNextInQueue() {
    if (!this.pending.length || this.saving || !this.webapi.hasToken()) { return; }
    this.saving = true;
    const val = this.pending[0];
    try {
      await this.save(val);

      // # Remove from queue
      const obj = val as any;
      if (obj.ulid) {
        try {
          await this.pendingStore.remove(obj.ulid);
          const indx = this.pending.findIndex(objIter => (objIter as any).ulid === obj.ulid);
          if (indx >= 0) {
            this.pending.splice(indx, 1);
          }
        } catch (err) {
          this.logError(err);
        }
      }
    } catch (err) {
      this.logError('Error subiendo objeto, moviendo al final de cola.', err);
      this.pending.push(this.pending.shift());
    }
    this.saving = false;
  }

  private getObjectID(obj: any): any {
    if (obj.id) {
      return obj.id;
    } else {
      if (obj.ulid) {
        return obj.ulid;
      } else {
        return obj.ulid = ulid();
      }
    }
  }
}



/*console.log(jsonResult);
// Cache results
const cachedObject = { endpoint: '', data: '' };
cachedObject.endpoint = endpoint;
cachedObject.data = JSON.stringify(jsonResult);
nSQL(this.tableName).useDatabase(this.dbName).query('upsert', cachedObject).exec()
  .then(() => {
    console.log('Cache actualizado para ' + endpoint);
  })
  .catch((err) => {
    console.error(err);
  });


let jsonResult = null;
try {

} catch (e) {
  console.error('Falló obtencion de datos remotosF:', e);
  // From cache
  try {
    const rows = await nSQL(this.tableName).useDatabase(this.dbName).query('select').where(['endpoint', '=', endpoint]).exec();
    if (rows.length > 0) {
      jsonResult = JSON.parse(rows[0].data);
    }
  } catch (d) {
    console.error('Falló obtencion de datos de cache:', d);
  }
}
// Deserialize JSON into app structs
if (jsonResult) {
  // const rawSet = JSON.parse(jsonResult);
  for (const iter in jsonResult) {
    if (!jsonResult.hasOwnProperty(iter)) { continue; }
    const newTObj = new classType();
    Object.assign(newTObj, jsonResult[iter]);
    result.push(newTObj);
  }
}
return result;*/