import { Component, NgZone } from '@angular/core';

import { Platform, NavController, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UiService } from './servicios/ui.service';
import { WebapiService } from './servicios/webapi.service';
import { ConfigService } from './servicios/config.service';
import { ExpoConfigMenuOpcion } from './models/menu';
import { CachedWebapiService } from './servicios/cached-webapi.service';
import { CommandsService } from './servicios/commands.service';
import { CloudService } from './servicios/cloud.service';
import { EventsService } from './servicios/events.service';
import { GlobalFuncsService } from './servicios/global-funcs.service';
import { TranslateService } from '@ngx-translate/core';
import { BadgeService } from './servicios/badge.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  get lang() { return this.uiServ.lang; }
  set lang(value: string) { this.zone.run(() => { this.uiServ.lang = value; }); }

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    public uiServ: UiService,
    private events: EventsService,
    private webapiServ: WebapiService,
    public configServ: ConfigService,
    private cachedWebapiServ: CachedWebapiService,
    private navCtrl: NavController,
    private menuCtrl: MenuController,
    private statusBar: StatusBar,
    private zone: NgZone,
    private cmdServ: CommandsService,
    private cloudServ: CloudService,
    private globalFuncs: GlobalFuncsService,
    private translateServ: TranslateService,
    private badgeServ: BadgeService,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      // await this.cachedWebapiServ.setup();
      this.cloudServ.init();
      await this.uiServ.verificaSesionIniciada();
      if (this.configServ.esCordova) {
        this.statusBar.styleDefault();
        // let status bar overlay webview
        // this.statusBar.overlaysWebView(true);
        // set status bar to white
        this.statusBar.backgroundColorByHexString('#007d38');
        this.splashScreen.hide();
      }
    });
  }

  abrirOpcion(opc: ExpoConfigMenuOpcion) {
    this.menuCtrl.close('menu_lateral');
    if (opc.onMenuClick) {
      if (!opc.onMenuClick(this.events, this.navCtrl)) {
        return;
      }
    }
    this.navCtrl.navigateForward(opc.ruta);
  }

  async terminaSesion() {
    await this.uiServ.terminaSesion();
  }

  openFacebook() {
    const facebookProfileNumber = '335637589899115';
    if (this.configServ.esCordova) {
      this.uiServ.abreLink('https://m.facebook.com/expoceres/');
      return;
    }
    /*if (this.platform.is('android')) {
      window.open('fb://page/' + facebookProfileNumber);
      return;
    }
    if (this.platform.is('ios')) {
      window.open('fb://profile/' + facebookProfileNumber);
      return;
    }*/
    this.uiServ.abreLink('https://www.facebook.com/expoceres/');
  }

  /** Used to fix the strage logo position on iphones */
  getImageTop(): string {
    if (this.platform.is('cordova') && this.platform.is('iphone')) {
      return '-12px';
    } else {
      return '8px';
    }
  }
}
