import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as L from 'leaflet';
import { ConfigService } from 'src/app/servicios/config.service';
import { UiService } from 'src/app/servicios/ui.service';
import * as GJSON from 'geojson';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { WebapiService } from 'src/app/servicios/webapi.service';
import { UbicacionParticipacion } from 'src/app/models/expositores';

@Component({
  selector: 'app-modal-ubicaciones',
  templateUrl: './modal-ubicaciones.component.html',
  styleUrls: ['./modal-ubicaciones.component.scss'],
})
export class ModalUbicacionesComponent implements OnInit {
  @ViewChild('leafletmap', { static: false }) leafletmap: any;
  @Input() geoJson: string;
  @Input() partId: number;
  map: L.Map;
  geoLayer: L.GeoJSON;

  constructor(
    public uiServ: UiService,
    private configServ: ConfigService,
    private webapiServ: WebapiService,
    private modalCtrl: ModalController) {
    L.Icon.Default.imagePath = 'assets/maps/icons/';
  }

  async ngOnInit() {
    await this.uiServ.wait(100);
    await this.render();
  }

  async render() {

    const edicion = await this.configServ.edicionAsync.getValue();
    console.log(edicion);

    const mapOpts: any = {
      crs: L.CRS.Simple,
      minZoom: -5,
      attributionControl: false,
      editable: true
    };
    // this.map = L.map(this.leafletmap.nativeElement, mapOpts); // .setView([this.center.lat, this.center.lng], 9);
    this.map = L.map(this.leafletmap.nativeElement, mapOpts);
    var bounds: any = [[0, 0], [1000, 1000]];
    var image = L.imageOverlay(edicion.imagen_mapas1, bounds).addTo(this.map);
    this.map.fitBounds(bounds);
    this.map.setView([400, 400], 2);

    if (this.geoLayer) {
      this.geoLayer.removeFrom(this.map);
    }

    let useGeo = false;
    let geoData = new L.GeoJSON();
    if (this.geoJson) {
      geoData = L.geoJSON(JSON.parse(this.geoJson));//  as L.GeoJSON;
      useGeo = true;
    }

    const ubicaciones = await this.webapiServ.getObjects('ubicaciones/?participacion=' + this.partId, UbicacionParticipacion);
    for (const ub of ubicaciones) {
      if (!ub.mapa_geo_json) { continue; }
      let ubGeo = JSON.parse(ub.mapa_geo_json) as GJSON.GeoJsonObject;
      console.log(ubGeo);
      geoData.addData(ubGeo);
      useGeo = true;
    }

    this.geoLayer = geoData.addTo(this.map);
    this.map.invalidateSize();
    await this.uiServ.wait(1000);
    if (useGeo)
      this.map.fitBounds(this.geoLayer.getBounds());

    this.map.pm.setLang('es');

    await this.uiServ.wait(1000);

  }

  close() {
    this.modalCtrl.dismiss();
  }

}
