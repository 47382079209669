import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UiService } from 'src/app/servicios/ui.service';
import { MenuController, IonRouterOutlet, NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { WebapiService } from 'src/app/servicios/webapi.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() titulo = 'Página sin titulo';
  @Input() back = undefined;
  @Input() showMenu = true;
  @Input() showClose = false;
  @Input() useFullPageMargin = true;
  @Output() closed = new EventEmitter();

  constructor(
    public uiServ: UiService,
    public navCtrl: NavController,
    private router: Router,
    public webapiServ: WebapiService,
    public menuCtrl: MenuController) { }

  ngOnInit() { }

  abreMenu() {
    this.menuCtrl.open('menu_lateral');
  }

  abreNotificaciones() {

  }

  goBack() {
    // this.router.
    this.navCtrl.navigateBack(this.back);
    // console.log('Moving to ' + this.back);
  }

  cancelarModal() {
    this.closed.emit();

  }
}
