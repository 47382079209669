// tslint:disable: member-ordering
// tslint:disable: variable-name
// tslint:disable: max-line-length

import { Dato, Repositorio } from './dato';
import { EditorForm } from './editor';
import { Tools } from '../servicios/tools';

// ========================================================================== //

export class EdicionExpoceres extends Dato {
  edicion: number;
  fecha_inicio: string;
  fecha_fin: string;
  imagen_portada?: string;
  imagen_sponsors?: string;
  imagen_sponsors_min?: string;
  imagen_conferencias?: string;
  imagen_conferencias_min?: string;
  imagen_mapas1?: string;
  imagen_mapas1_min?: string;
  imagen_mapas2?: string;
  imagen_mapas2_min?: string;
  toString(): string { return this.edicion.toString(); }

  public static Editor: EditorForm = {
    newObjectTitle: 'Nueva edición',
    limitText: '',
    inputs: [
      { titulo: 'Año:', property: 'edicion', type: 'number', placeholder: '', required: true },
      { titulo: 'Inicio:', property: 'fecha_inicio', type: 'date', placeholder: '', required: true },
      { titulo: 'Fin:', property: 'fecha_fin', type: 'date', placeholder: '', required: true },
      { titulo: 'Imagen portada:', property: 'imagen_portada', type: 'image', placeholder: '', required: false, max: 1500000 },
      { titulo: 'Imagen sponsors (ancha):', property: 'imagen_sponsors', type: 'image', placeholder: '', required: false, max: 1500000 },
      { titulo: 'Imagen sponsors (cuadrada):', property: 'imagen_sponsors_min', type: 'image', placeholder: '', required: false, max: 1500000 },
      { titulo: 'Imagen conferencia magistral (ancha):', property: 'imagen_conferencias', type: 'image', placeholder: '', required: false, max: 1500000 },
      { titulo: 'Imagen conferencia magistral (cuadrada):', property: 'imagen_conferencias_min', type: 'image', placeholder: '', required: false, max: 1500000 },
      { titulo: 'Imagen mapa 1:', property: 'imagen_mapas1', type: 'image', placeholder: '', max: 3000000 },
      { titulo: 'Imagen mapa 1 (miniatura):', property: 'imagen_mapas1_min', type: 'image', placeholder: '', max: 30000 },
      { titulo: 'Imagen mapa 2:', property: 'imagen_mapas2', type: 'image', placeholder: '', max: 3000000 },
      { titulo: 'Imagen mapa 2 (miniatura):', property: 'imagen_mapas2_min', type: 'image', placeholder: '', max: 30000 },
    ]
  };

  public static Repo = new Repositorio('EdicionExpoceres', 'edicionexpoceres/', EdicionExpoceres.Editor, EdicionExpoceres);
}

// ========================================================================== //

export class Giro extends Dato {
  value = '';
  nombre = '';
  mostrar = true;

  toString(): string { return this.nombre; }

  public static Editor: EditorForm = {
    newObjectTitle: 'Nuevo giro',
    limitText: 'Giros',
    inputs: [
      { titulo: 'Nombre:', property: 'nombre', type: 'text', placeholder: '', max: 50, required: true },
      { titulo: 'Mostrar:', property: 'mostrar', type: 'bool', placeholder: '', required: true },
    ]
  };

  public static Repositorio = new Repositorio('Giro', 'giros/', Giro.Editor, Giro);
}

// ========================================================================== //

export class Gastronomico extends Dato {
  id: number;
  nombre: string;
  contacto: string;
  ubicacion: string;
  logo: string;
  menu: string;
  edicion: number;
  toString() { return this.nombre; }

  static Editor: EditorForm = {
    newObjectTitle: 'Nuevo restaurante',
    limitText: '',
    inputs: [
      { titulo: 'Nombre:', property: 'nombre', type: 'text', placeholder: 'Nombre', max: 60, required: true, },
      { titulo: 'Contacto:', property: 'contacto', type: 'text', placeholder: 'Contacto', max: 30, required: true, },
      { titulo: 'Ubicación:', property: 'ubicacion', type: 'text', placeholder: 'Ubicacion', max: 50, required: true, },
      { titulo: 'Logo:', property: 'logo', type: 'image', required: true, writeable: false, helpText: 'El formato de la imagen deberá ser .PNG, o .JPG con fondo blanco, dimensiones de 500 x 500 pixeles, y una resolución mínima de 150 ppp.' },
      { titulo: 'Menú:', property: 'menu', type: 'image', writeable: false },
      { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
    ]
  };
  static Repo: Repositorio = new Repositorio('Gastronomico', 'gastronomico/', Gastronomico.Editor, Gastronomico);
}


// ========================================================================== //

export class CategoriasFotografias extends Dato {
  id: number;
  nombre: string;
  imagen_portada: string;
  edicion: number;
  sponsor = false;
  toString() { return this.nombre; }

  static Editor: EditorForm = {
    newObjectTitle: 'Nuevo registro',
    limitText: '',
    inputs: [
      { titulo: 'Sponsor', property: 'sponsor', type: 'bool', required: true },
      { titulo: 'Nombre:', property: 'nombre', type: 'text', placeholder: 'Nombre', max: 100, required: true, },
      { titulo: 'Portada:', property: 'imagen_portada', type: 'image', writeable: false, required: true, max: 100000, helpText: 'El formato de la imagen deberá ser .PNG, o .JPG con fondo blanco, dimensiones de 200 x 200 pixeles, y una resolución mínima de 150 ppp.' },
      { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
    ]
  };
  static Repo: Repositorio = new Repositorio('CategoriasFotografias', 'categoriasfotografias/', CategoriasFotografias.Editor, CategoriasFotografias);
}

// ========================================================================== //

export class Fotografias extends Dato {
  id: number;
  titulo: string;
  archivo: string;
  imagen_minuatura: string;
  descripcion: string;
  categoria: any;
  edicion: number;
  toString() { return this.titulo; }

  static Editor: EditorForm = {
    newObjectTitle: 'Nuevo registro',
    limitText: '',
    inputs: [
      { titulo: 'Título:', property: 'titulo', type: 'text', max: 100 },
      { titulo: 'Imagen en miniatura:', property: 'imagen_minuatura', type: 'image', max: 100000, writeable: false, helpText: 'El formato de la imagen deberá ser .PNG, o .JPG con fondo blanco, dimensiones de 200 x 200 pixeles, y una resolución mínima de 150 ppp.', required: true },
      { titulo: 'Imagen:', property: 'archivo', type: 'image', max: 500000, writeable: false, helpText: 'El formato de la imagen deberá ser .PNG, o .JPG, dimensiones de 1280 x 720pixeles, y una resolución mínima de 720 ppp.', required: true },
      { titulo: 'Descripcion:', property: 'descripcion', type: 'text', max: 200 },
      { titulo: 'Galería:', property: 'categoria', type: 'dato', repo: CategoriasFotografias.Repo, required: true },
      { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
    ]
  };
  static Repo: Repositorio = new Repositorio('Fotografias', 'fotografias/', Fotografias.Editor, Fotografias);
}

// ========================================================================== //

export class CategoriasProductos extends Dato {
  id: number;
  nombre: string;
  imagen: any;
  edicion: number;
  no_mostrar_en_tienda = false;
  permite_pedidos: boolean;

  toString() { return this.nombre; }

  static Editor: EditorForm = {
    newObjectTitle: 'Nuevo registro',
    limitText: '',
    inputs: [
      { titulo: 'Nombre:', property: 'nombre', type: 'text', placeholder: 'Nombre', max: 100, required: true, },
      { titulo: 'Portada:', property: 'imagen', type: 'image', writeable: false, required: true, max: 100000, helpText: 'El formato de la imagen deberá ser .PNG, o .JPG con fondo blanco, dimensiones de 200 x 200 pixeles, y una resolución mínima de 150 ppp.' },
      { titulo: 'No mostrar', property: 'no_mostrar_en_tienda', type: 'bool', },
      { titulo: 'Productos son para pedidos', property: 'permite_pedidos', type: 'bool', },
      { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
    ]
  };
  static Repo: Repositorio = new Repositorio('CategoriasProductos', 'categorias_productos/', CategoriasProductos.Editor, CategoriasProductos);
}

// ========================================================================== //

export class Producto extends Dato {
  nombre: string;
  precio: string;
  cantidad_disponible: number;
  tiene_boletos: boolean;
  edicion: number;
  categoria: number;
  archivo: string;

  toString() { return this.nombre; }

  static Editor: EditorForm = {
    newObjectTitle: 'Nuevo registro',
    limitText: '',
    inputs: [
      { titulo: 'Nombre:', property: 'nombre', type: 'text', placeholder: 'Nombre', max: 100, required: true },
      { titulo: 'Imagen:', property: 'archivo', type: 'image', writeable: false, max: 500000, helpText: 'El formato de la imagen deberá ser .PNG, o .JPG con fondo blanco.' },
      { titulo: 'Precio:', property: 'precio', type: 'number' },
      { titulo: 'Cantidad disponible:', property: 'cantidad_disponible', type: 'number', required: true },
      { titulo: 'Tiene boletos', property: 'tiene_boletos', type: 'bool' },
      { titulo: 'Categoría:', property: 'categoria', type: 'dato', repo: CategoriasProductos.Repo, required: true },
      { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
    ]
  };

  static Repo: Repositorio = new Repositorio('Producto', 'productos/', Producto.Editor, Producto);
}

// ========================================================================== //

export enum BoletoStatus {
  LIBRE = 1, APARTADO = 2, VENDIDO = 3, BLOQUEADO = 4
}

export class Boleto {
  asiento: string;
  fila: string;
  id: number;
  producto: Producto;
  seleccionado: boolean;
  status: BoletoStatus;

  posX: number;
  posY: number;
  calcXY() {
    this.posX = parseInt(this.asiento, 10);   // Numero, Columna, Asiento en fila
    this.posY = this.fila.charCodeAt(0) - 64; // Letra, Renglon, Fila
  }
}

export class CompraARealizarConcepto extends Dato {
  producto: Producto;
  cantidad: number;
  boleto: Boleto;
  subtotal: number;
}

export class CompraARealizar extends Dato {
  articulos: string; //JSON
  requiere_factura: boolean;
  razon_social: string;
  rfc: string;
}

// ========================================================================== //

export class BitacoraEvento extends Dato {
  codigo: number;     // numero entero 32bits
  usuario: number;    // Usuario (valor automatico, no obligatorio)
  fecha: string;      // fecha (asignado)
  mensaje: string;    // TextField
  stackTrace: string; // TextField

  constructor() {
    super();
    this.fecha = Tools.Fecha_YYYY_MM_DD();
  }

  static Repo: Repositorio = new Repositorio('BitacoraEvento', 'bitacora_eventos/', null, BitacoraEvento);
}


// ========================================================================== //

// ========================================================================== //

export class BoletoJsonQR {
  edicion: number;
  producto_id: number;
  producto: string; // Diamante, Oro, etc.
  boleto_id: number;
  fila: string;
  asiento: number;
}
