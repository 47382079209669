import { Component, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Repositorio, Dato } from 'src/app/models/dato';
import { ExpoConfigMenuOpcion } from 'src/app/models/menu';
import { EditorComponent } from '../editor/editor.component';
import { EventEmitter } from '@angular/core';
import { UiService } from 'src/app/servicios/ui.service';

@Component({
  selector: 'app-crud',
  templateUrl: './crud.component.html',
  styleUrls: ['./crud.component.scss'],
})
export class CrudComponent implements OnInit {
  @Input() loadFromRepo = true;
  @Input() datos: Dato[] = [];
  @Input() templateDato?: Dato;
  @Input() opc: ExpoConfigMenuOpcion;
  @Input() repo: Repositorio;
  @Output() onChanged = new EventEmitter<string>();
  failedLoading = false;
  editingData: Dato;

  constructor(
    private modalCtrl: ModalController,
    private uiServ: UiService,
  ) { }

  async ngOnInit() {
    if (this.loadFromRepo) {
      this.reload();
    }
  }

  async reload() {
    try {
      this.datos = await this.repo.get();
    } catch (err) {
      console.error(err);
    }
  }

  async edit(dato?: Dato) {
    this.editingData = dato ? dato :
      (this.templateDato ? Object.assign(new this.repo.ctr(), this.templateDato) : new this.repo.ctr());
    const dlg = await this.modalCtrl.create({
      component: EditorComponent, componentProps: {
        dato: this.editingData,
        opc: this.opc,
        isModal: true,
        repo: this.repo,
        editor: this.repo.editor
      }
    });
    await dlg.present();
    const res = await dlg.onDidDismiss();
    console.log(res);
    if (res.data && res.data.status) {
      if (this.loadFromRepo) {
        this.reload();
      } else {
        this.onChanged.emit('changed');
      }
    }
  }

  async del(itm: Dato) {
    if (await this.uiServ.pregunta('¿Desea borrar este dato: "' + itm.toString() + '"?')) {
      try {
        await this.repo.delete(itm);
        this.onChanged.emit('changed');
      } catch (err) {
        this.uiServ.error(err);
      }
    }
  }

}
