import { Component, OnInit, ViewChild } from '@angular/core';
import { WebapiService } from 'src/app/servicios/webapi.service';
import { ModalController } from '@ionic/angular';
import { CatalogoSAT, PagosParticipacion, Participacion, Expositor } from 'src/app/models/expositores';
import { UiService } from 'src/app/servicios/ui.service';
import { EditorForm } from 'src/app/models/editor';
import { ExpoConfigMenuOpcion } from 'src/app/models/menu';
import { Repositorio } from 'src/app/models/dato';
import { EditorComponent } from '../editor/editor.component';
import { ConfigService } from 'src/app/servicios/config.service';



@Component({
  selector: 'app-facturar',
  templateUrl: './facturar.component.html',
  styleUrls: ['./facturar.component.scss'],
})
export class FacturarComponent implements OnInit {
  @ViewChild('appEditor', { static: false }) editorDePago: EditorComponent;
  cargando = true;
  errorCarga = false;
  formasDePago: CatalogoSAT[] = [];
  usoDeCFDI: CatalogoSAT[] = [];
  metodoDePagoCFDI: CatalogoSAT[] = [];
  yaProcesada = false;

  expositor: Expositor;

  pago: PagosParticipacion;
  participacion: Participacion;

  editor: EditorForm;
  opc: ExpoConfigMenuOpcion;
  repo: Repositorio;

  ofrecerValidarComprobante = false;

  constructor(
    private webapiServ: WebapiService,
    private modalCtrl: ModalController,
    private uiServ: UiService,
    public configServ: ConfigService,
  ) { }

  async ngOnInit() {
    try {

      this.expositor = await this.webapiServ.getObject('expositores/' + this.participacion.expositor + '/', Expositor);
      console.log(this.expositor);

      // this.pago = Object.assign(new PagosParticipacion(), this.pago);
      this.pago = await this.webapiServ.getObject('pagos_participacion/' + this.pago.id + '/', PagosParticipacion);
      this.pago['razon_soxial'] = this.expositor.razon_social;
      this.pago['rfc'] = this.expositor.rfc;

      this.formasDePago = await this.webapiServ.getObjects('forma_de_pago/', CatalogoSAT);
      this.usoDeCFDI = await this.webapiServ.getObjects('uso_del_cfdi/', CatalogoSAT);
      this.metodoDePagoCFDI = await this.webapiServ.getObjects('metodo_de_pago/', CatalogoSAT);

      this.editor = new EditorForm();
      this.editor.inputs = [
        { titulo: 'Razon social:', property: 'razon_soxial', type: 'text', max: 999, disabled: true },
        { titulo: 'RFC:', property: 'rfc', type: 'text', max: 999, disabled: true },
        { titulo: 'Fecha:', property: 'fecha', type: 'date', disabled: true },
        { titulo: 'Importe:', property: 'importe', type: 'number', disabled: true },
        { titulo: 'IVA:', property: 'iva', type: 'number', disabled: true },
        { titulo: 'Forma de pago:', property: 'forma_de_pago', type: 'select', required: !this.configServ.isStaff, options: this.formasDePago },
        { titulo: 'Uso del CFDI:', property: 'uso_del_cfdi', type: 'select', required: !this.configServ.isStaff, options: this.usoDeCFDI },
        { titulo: 'Método de pago:', property: 'metodo_de_pago', type: 'select', required: !this.configServ.isStaff, options: this.metodoDePagoCFDI },
        { titulo: 'Comprobante:', property: 'comprobante', type: 'file', required: true },
        { titulo: 'xx', property: 'participacion', type: 'hidden' },
        { titulo: 'Solicitar factura', property: 'factura_solicitada', type: 'bool' },
        { titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
      ];
      console.log(this.participacion);
      this.pago.participacion = this.participacion.id;
      this.opc = new ExpoConfigMenuOpcion();
      this.opc.noDialogs = true;
      this.opc.disableDelete = true;
      this.repo = PagosParticipacion.Repo;
      // this.opc.disableEdit = this.pago.factura_solicitada;
      this.opc.disableEdit = this.pago.validado;
      this.opc.saveWithPatch = true;

      //if(!this.configServ.isStaff){
      //this.pago.factura_solicitada = true;
      //}
      // await this.editorDePago.ngOnInit();

      // console.log(this.formasDePago, this.usoDeCFDI, this.metodoDePagoCFDI);
      this.cargando = false;
    } catch (err) {
      this.uiServ.error(err);
      this.errorCarga = true;
    }
  }

  async cancel() {
    await this.modalCtrl.dismiss(null, 'cancel');
  }

  async saved() {
    this.opc.disableEdit = this.pago.factura_solicitada;
    if (this.configServ.noEsStaff) {
      // tslint:disable-next-line: max-line-length
      await this.uiServ.ok('Su comprobante será revisado por su asesor, en las próximas horas recibirá una copia de su factura o bien podrá consultarla en el apartado de facturación.');
    } else {
      await this.uiServ.ok('Comprobante guardado.');
    }
    await this.modalCtrl.dismiss(null, 'ok-saved');
  }

  async validarPago(pago: PagosParticipacion) {
    if (await this.uiServ.pregunta('Se tomará como válido el comprobante de pago ¿Desea continuar?')) {
      const jif = await this.uiServ.cargando();
      try {
        const res = await this.webapiServ.get('pagos_participacion/' + pago.id + '/validar/');
        await jif.dismiss();
        await this.uiServ.ok('El comprobante de pago se tomará como válido.');
        pago.validado = true;
        await this.modalCtrl.dismiss(null, 'ok-validado');
      } catch (err) {
        await jif.dismiss();
        this.uiServ.error(err);
      }
    }
  }


}
