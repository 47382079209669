

export class AsyncValue<T> {
  value: T;
  private waitingForValue: (() => void)[] = [];

  getValue(): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      if (this.value) {
        resolve(this.value);
      } else {
        this.waitingForValue.push(() => {
          resolve(this.value);
        });
      }
    });
  }

  getValueTimeout(ms: number): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      if (this.value) {
        resolve(this.value);
      } else {
        this.waitingForValue.push(() => {
          resolve(this.value);
        });
        setTimeout(() => {
          // this.waitingForValue.re
          reject();
        }, ms);
      }
    });
  }

  setValue(value: T) {
    this.value = value;
    for (const waiter of this.waitingForValue) {
      waiter();
    }
    this.waitingForValue = [];
  }
}

export const Tools = {
  dateMMDDYYYYtoDate(date: string): Date {
    if (!date || !date.length || date.length !== 10) { return null; }
    let split: string[] = date.split('/');
    if (split.length !== 3) { split = date.split('-'); }
    if (split.length !== 3) { return null; }
    return new Date(parseInt(split[0], 10), parseInt(split[1], 10), parseInt(split[2], 10));
  },

  expandArrayToFit<T>(length: number, array: T[], newValueCtr: () => T) {
    while (length > array.length) {
      array.push(newValueCtr());
    }
  },

  Fecha_YYYY_MM_DD(): string {
    let date = new Date();
    let day: any = date.getDate();
    if (day < 10) day = "0" + day;
    let mnth: any = date.getMonth() + 1;
    if (mnth < 10) mnth = "0" + mnth;
    return date.getFullYear() + "-" + mnth + "-" + day;
  },

  replaceAll(word: string, terms: string[], withd: string = ''): string {
    let replaced = word;
    for (const term of terms) {
      const regex = new RegExp(term, 'g');
      replaced = replaced.replace(regex, withd);
    }
    return replaced;
  },

  cleanEmail(email: string): string {

    // value = value.trim().toLowerCase().replace(/[^\w@.]/gi, '');
    // value = Tools.replaceAll(value.trim().toLowerCase(), this.aLimpiar, '');
    return email.trim().toLowerCase().replace(/[^a-zA-Z0-9_\/\-@.]/g, ''); ///.replace(/=/g, '').replace(/?/g, '');
  },

  bytesToSize(bytes, decimals = 2): string {
    if (bytes === 0) { return '0 Bytes'; }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  },

  strNormalize(term: any): string {
    let rterm: string = '' + term;
    rterm = rterm.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();
    return rterm;
  },

  strCompare(haystack: string, needle: string): boolean {
    const res = haystack.indexOf(needle);
    console.log(haystack, needle, res);
    return res >= 0;
  }
};


