import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurtainService {
  curDiv: HTMLElement;
  baseClass = 'app-curtain ';

  constructor() {
    const l = setInterval((serv) => {
      // console.log(serv);
      serv.curDiv = document.getElementById('app-curtain');
      if (serv.curDiv) {
        clearInterval(l);
        if (localStorage.getItem('skipCurtain')) {
          this.hideQuick();
          localStorage.removeItem('skipCurtain');
        }
      }
    }, 1, this);
  }

  show() {
    this.curDiv.className = this.baseClass + 'curtain-fade-in';
    // console.log(this.curDiv.className);
  }

  showAndHide() {
    this.curDiv.className = this.baseClass + 'curtain-fade-inOut';
    // console.log(this.curDiv.className);
  }

  hide() {
    this.curDiv.className = this.baseClass + 'curtain-fade-out';
    // console.log(this.curDiv.className);
  }

  hideQuick() {
    this.curDiv.className = this.baseClass + 'curtain-fade-out-quick';
    // console.log(this.curDiv.className);
  }

  skipCurtain() {
    localStorage.setItem('skipCurtain', '1');
  }
}
