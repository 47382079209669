import { Injectable, ErrorHandler } from '@angular/core';
import { UiService } from './ui.service';
import { BitacoraEvento } from '../models/comunes';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  private lock = false;

  constructor(private uiServ: UiService) { }

  handleError(error: any): void {
    if (this.lock) {
      return;
    }
    this.lock = true;

    if(!environment.production) {
      console.error(error);
    }

    this.uiServ.enviarEventoError('Unhandheld Error', error);

    this.lock = false;

    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    throw error;
  }

}
