
// Angular & Ionic
import { NgModule, LOCALE_ID, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

// Components
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ExpoceresModule } from './componentes/expoceres.module';

// Plugins
import { QuillModule } from 'ngx-quill';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { File } from '@ionic-native/file/ngx';
// import { Firebase } from '@ionic-native/firebase/ngx';
// import { FCM } from '@ionic-native/fcm/ngx';
import { AngularFireModule } from '@angular/fire';
// import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAnalyticsModule, APP_NAME, APP_VERSION, COLLECTION_ENABLED } from '@angular/fire/analytics';
import * as PluginConfigs from './plugin.configs';

// Localization
import localeMX from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { UiService } from './servicios/ui.service';
import { ErrorHandlerService } from './servicios/error-handler.service';
registerLocaleData(localeMX);
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
// import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { version as appVersion } from '../../package.json';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// let firebaseModules = [];
// let firebaseProviders = [];
// const isNative = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/);
// const cordova = (window as any).cordova;
// console.log(cordova);
// if (!cordova) {
const  firebaseModules = [
    AngularFireModule.initializeApp(environment.firebaseWebConfig),
    AngularFireAnalyticsModule
  ];
  // console.log('Init firebase web');
  // AngularFirestoreModule,
// }

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    IonicModule.forRoot({ mode: 'md' }),
    QuillModule.forRoot(PluginConfigs.ngquillConfig),
    AppRoutingModule,
    firebaseModules,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    File,
    // FCM,
    // Firebase,
    // FileTransfer,
    // FirebaseX,
    BarcodeScanner,
    FileOpener,
    InAppBrowser,
    { provide: LOCALE_ID, useValue: 'es-MX' }, //replace "de-at" with your locale
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: APP_VERSION, useValue: appVersion },
    { provide: APP_NAME, useValue: 'Expoceres' }
    /*{
      provide: APP_INITIALIZER,
      deps: [Platform],
      multi: true,
      useFactory: (platform: Platform) => {
        return platform.ready();
      }
    },
    {
      provide: COLLECTION_ENABLED, useValue: () => {
        console.log('PULLED VALUE!');
        return false;
      }
    },*/
  ],
  bootstrap: [AppComponent],
  exports: [ExpoceresModule]
})
export class AppModule { }
