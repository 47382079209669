// tslint:disable: variable-name

import { Visitante } from './visitantes';
import { Expositor } from './expositores';

/** Este modelo se utiliza para ambos APIs */
export class Usuario {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  is_staff: boolean;
  // aplicacion: { expositores: boolean; visitantes: boolean; };

  /** Solo utilizado cuando se crea un usuario visitante */
  password: string;

  /** Visitante asignado al usuario, todos los usuarios tienen uno excepto el staff. */
  visitante: Visitante;

  /** Solo es valido si el usuario es expositor */
  perfil?: { expositor: Expositor };

  /** Código QR de la participacion para la expo actual */
  participacion_actual?: { codigo_qr: string; };

  /** Grupo al que pertenece el usuario */
  groups: [{ name: string; }];

  obtenNombreDeUsuario() {
    if (this.first_name) {
      let ret = this.first_name;
      if (this.last_name) {
        ret += ' ' + this.last_name;
      }
      return ret;
    } else {
      if (this.visitante) {
        let ret = this.visitante.first_name;
        if (this.visitante.last_name) {
          ret += ' ' + this.visitante.last_name;
        }
        return ret;
      } else {
        return this.username;
      }
    }
  }

  tieneParticipacion(): boolean {
    if (!this.participacion_actual) {
      return false;
    }
    if (!this.participacion_actual.codigo_qr) {
      return false;
    }
    return true;
  }

  obtenerParticipacionDeExpositor(): any {
    if (!this.perfil || !this.perfil.expositor || !this.perfil.expositor.participacion_actual ||
      !this.perfil.expositor.participacion_actual.contrato_confirmado) {
      return null;
    }
    return this.perfil.expositor.participacion_actual;
  }

  obtenerParticipacionNoConfirmada(): any {
    if (!this.perfil || !this.perfil.expositor || !this.perfil.expositor.participacion_actual) {
      return null;
    }
    return this.perfil.expositor.participacion_actual;
  }

  hasGroup(groupName: string) {
    const found = this.groups.filter(grp => grp.name === groupName).length > 0;
    return found;
  }

}
