// tslint:disable: member-ordering
// tslint:disable: variable-name
// tslint:disable: max-line-length
import { Dato, Repositorio } from './dato';
import { EditorForm } from './editor';

export class GrupoSponsor extends Dato {
  nombre = '';
  edicion: number;

  toString(): string { return this.nombre; }

  public static Editor: EditorForm = {
    newObjectTitle: 'Nuevo grupo de sponsor',
    limitText: 'N/A',
    inputs: [
      { titulo: 'Nombre:', property: 'nombre', type: 'text', placeholder: '', required: true },
      { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
    ]
  };

  public static Repositorio = new Repositorio('GrupoSponsor', 'grupo_sponsor/', GrupoSponsor.Editor, GrupoSponsor);
}
