
export const Cantidades = [
    { id: 0, label: '1 – 5' },
    { id: 1, label: '6 – 10' },
    { id: 2, label: '11 – 20' },
    { id: 3, label: '21 – 30' },
    { id: 4, label: '31 – 50' },
    { id: 5, label: '51 – 70' },
    { id: 6, label: '71 – 100' },
    { id: 7, label: '101 – 150' },
    { id: 8, label: '151 – 200' },
    { id: 9, label: '201 – 350' },
    { id: 10, label: '351 – 500' },
    { id: 11, label: '501 – 700' },
    { id: 12, label: '701 – 1000' },
    { id: 13, label: '1000 – 1500' },
    { id: 14, label: '+ 1500' }
];

export const Ganado = [
    { id: 1, label: 'Bovino' },
    { id: 2, label: 'Ovino' },
    { id: 3, label: 'Porcino' },
    { id: 4, label: 'Caprino' },
    //{ id: 5, label: 'Acuícola' },
];
