import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class CommandsService {

  constructor(private configServ: ConfigService) {
    this.registerCommand(this, this.version);
  }

  registerCommand(context: any, func: () => void) {
    window[func.name] = (paramd: any) => { func.call(context); };
    // console.log(global[func.name]);
  }

  version() {
    console.log(this.configServ.version);
  }
}