// tslint:disable: max-line-length

import { ExpoConfig } from '../models/menu';
import { InvitacionRepo, DatosFiscalesRepo, GafeteRepo, ContactoRepo, DireccionRepo, DirectorioOficialRepo, ExpositorRepo, GrupoExpositorRepo, NotificacionRepo, MaterialOficialRepo, ExpositorUsuario, Proveedor, CategoriaProvedor, Oferton, Prospecto, ProspectoEditor, ProspectoRepo, ProveedorStandRepo, EncuentroDeNegocios, TipoDeContrato, Expositor } from '../models/expositores';
import { Dato } from '../models/dato';
import { NavController } from '@ionic/angular';
import { Giro, Gastronomico, EdicionExpoceres, CategoriasFotografias, Fotografias, CategoriasProductos, Producto } from '../models/comunes';
import { Conferencia } from '../models/visitantes';
import { GrupoSponsor } from '../models/sponsors';
import { EventsService } from '../servicios/events.service';
import { EditorPageTemplate, EditorPageTemplates, ModelEditorTemplates } from '../models/editorTemplate';

// Archivo de configuriaciones y opciones del app
export let Configs: ExpoConfig = {
  version: '1',
  menus: [
    // Visitante (usuario)
    {
      id: 'expoceres_0',
      titulo: 'Expoceres',
      ionicon: '',
      perfil: 'visitante',
      opciones: [
        {
          id: 'CodigoQR',
          titulo: 'MENUS.EXPOCERES.CODIGO_QR',
          ionicon: 'qr-code',
          ruta: 'mi-pase',
        },
        {
          id: 'VisitaStandLobby',
          titulo: 'MENUS.EXPOCERES.STAND_VIRTUAL',
          ionicon: 'home',
          ruta: 'stand-lobby',
        },
        {
          id: 'VisitaNoticias',
          titulo: 'MENUS.EXPOCERES.NOTICIAS',
          ionicon: 'newspaper',
          ruta: 'noticias',
          accion: 'noticias'
        },
        {
          id: 'ContactarExpositor',
          titulo: 'MENUS.EXPOCERES.CATALAGO_EMPRESA',
          ionicon: 'chatbubbles',
          ruta: 'directorio-de-expositores',
          disabled: false,
        },
        {
          id: 'VisitaEditarPerfil',
          titulo: 'MENUS.EXPOCERES.PERFIL',
          ionicon: 'person',
          ruta: 'registro'
        },
        {
          id: 'ProgramaGeneral',
          titulo: 'PROGRAMA_GENERAL.PROGRAMA',
          ionicon: 'calendar',
          ruta: 'programa-general',
          ocultar: true
        },
        {
          id: 'VisitaConferencias',
          titulo: 'PROGRAMA_GENERAL.CONFERENCIAS',
          ionicon: 'calendar',
          ruta: 'conferencias'
        },
        {
          id: 'VisitaOferton',
          titulo: 'Ofertón Expoceres',
          ionicon: 'cash',
          ruta: 'oferton',
          // disabled: true
        },
        {
          id: 'VisitaMapa',
          titulo: 'MENUS.EXPOCERES.MAPAS',
          ionicon: 'map',
          ruta: 'mapas',
          disabled: false,
        }
        /*
        {
          id: 'VisitaRestaurantes',
          titulo: 'MENUS.EXPOCERES.GASTRONIMICO',
          ionicon: 'restaurant',
          ruta: 'restaurantes',
          disabled: false,
        }
        */
        /*,
        {
          id: 'Galeria',
          titulo: 'MENUS.EXPOCERES.GALERIA',
          ionicon: 'images',
          ruta: 'galeria',
          disabled: false,
        }*/,
        {
          id: 'PlaneaTuRecorrido',
          titulo: 'MENUS.EXPOCERES.RECOMENDACIONES',
          ionicon: 'thumbs-up',
          ruta: 'recomendaciones',
          disabled: false,
          ocultar: true
        }

      ]
    },
    // Visitante (Tienda)
    {
      id: 'visitante_tienda',
      titulo: 'Tienda',
      ionicon: '',
      perfil: 'visitante-oculto',
      opciones: [
        {
          id: 'Visitante-tienda',
          titulo: 'Tienda',
          desc: '',
          ionicon: 'home',
          ruta: 'tienda',
          disabled: false
        },
        {
          id: 'Visitante-carrito',
          titulo: 'Carrito',
          desc: '',
          ionicon: 'cart',
          ruta: 'tienda/carrito',
          disabled: false
        },
        {
          id: 'Visitante-tienda-mis-compras',
          titulo: 'Mis Compras',
          desc: '',
          ionicon: 'gift',
          ruta: 'tienda/mis-compras',
          disabled: false
        }
      ]
    },
    // Expositor (usuario)
    {
      id: 'expositor_datos_generales',
      titulo: 'Expoceres',
      ionicon: '',
      perfil: 'expositor',
      opciones: [
        {
          id: 'VisitaStandLobby',
          titulo: 'MENUS.EXPOCERES.EXPODIGITAL',
          ionicon: 'home',
          ruta: 'stand-lobby',
          ocultar: true
        },
        {
          id: 'CodigoQR',
          titulo: 'MENUS.EXPOCERES.CODIGO_QR',
          ionicon: 'qr-code',
          ruta: 'mi-pase',
        },
        {
          id: 'Notificaciones',
          titulo: 'MENUS.EXPOCERES.NOTICIAS',
          ionicon: 'newspaper',
          ruta: 'noticias'
        },
        {
          id: 'ContactarExpositor',
          titulo: 'MENUS.EXPOCERES.CATALAGO_EMPRESA',
          ionicon: 'chatbubbles',
          ruta: 'directorio-de-expositores',
          disabled: false,
        },
        {
          ocultar: true, // OPCION OCULTADA:
          id: 'encuentro-de-negocios',
          titulo: 'Solicitudes de contacto',
          ionicon: 'chatbubbles',
          ruta: '**',
          backUrl: 'directorio-de-expositores',

          disableAdd: true,
          disableDelete: true,
          disableEdit: true,
          activada: false,
          repositorio: EncuentroDeNegocios.Repo,
          editorActions: [
            {
              action: (dato: Dato, src: any, events: EventsService, navCtrl: NavController) => {
                console.log('marcando usuario', dato);
                events.publish('GlobalFuncsService.marcarAtendidoEncuentro', dato);
              },
              id: 'Atendido',
              title: 'Atendido',
              isVisible: (dato: Dato) => !dato['atendido']
            }
          ]
        },
        {
          id: 'ExpositorEditarPerfil',
          titulo: 'MENUS.EXPOCERES.PERFIL',
          ionicon: 'person',
          ruta: 'registro'
        },
        {
          id: 'VisitaStandLobby',
          titulo: 'MENUS.EXPOCERES.STAND_VIRTUAL',
          ionicon: 'home',
          ruta: 'stand-lobby',
          // ocultar: true
        },/*
        {
          id: 'ProgramaGeneral',
          // titulo: 'Programa de Actividades',
          titulo: 'MENUS.PROGRAMA_GENERAL.CONFERENCIAS',
          ionicon: 'calendar',
          ruta: 'programa-general',
          ocultar: true
        },*/
        {
          id: 'VisitaConferencias',
          titulo: 'PROGRAMA_GENERAL.CONFERENCIAS',
          ionicon: 'calendar',
          ruta: 'conferencias'
        },
        {
          id: 'VisitaMapa',
          titulo: 'MENUS.EXPOCERES.MAPAS',
          ionicon: 'map',
          ruta: 'mapas'
        },
        /*Se ocultó por Isai López el 17/febrero/2023 relacionado al issue #242 de mesa de ayuda*/ 
        /*{
          id: 'VisitaRestaurantes',
          titulo: 'Gastronómico',
          ionicon: 'restaurant',
          ruta: 'restaurantes',
          disabled: false,
        },*/
        {
          id: 'materialoficial',
          titulo: 'Material oficial',
          ionicon: 'copy',
          ruta: 'material-oficial'
        },
        /*
        {
          id: 'proveedores',
          titulo: 'Proveedores',
          ionicon: 'cart',
          ruta: 'proveedores'
        },*/
        {
          id: 'Prospectos',
          titulo: 'Prospectos',
          ionicon: 'people',
          ruta: 'prospectos',
          disabled: false
        },
        /*{
          id: 'Galeria',
          titulo: 'Galería',
          ionicon: 'images',
          ruta: 'galeria',
          // disabled: false,
        }*/
        
        {
          id: 'VisitaOferton',
          titulo: 'Ofertón Expoceres',
          ionicon: 'cash',
          ruta: 'oferton',
          // disabled: true
        },
        /*
        {
          id: 'Comentarios',
          titulo: 'Comentarios',
          ionicon: 'mail',
          ruta: 'ComentariosPage'
        },
        {
          id: 'Consumos',
          titulo: 'Consumos',
          ionicon: 'beer',
          ruta: 'ConsumosPage'
        }*/
      ]
    },
    // Expositor (Tienda)
    {
      id: 'expositor-tienda',
      titulo: 'Tienda',
      ionicon: '',
      perfil: 'expositor-oculto',
      opciones: [
        {
          id: 'Expositor-tienda',
          titulo: 'Tienda',
          desc: '',
          ionicon: 'home',
          ruta: 'tienda',
          disabled: false
        },
        {
          id: 'Expositor-carrito',
          titulo: 'Carrito',
          desc: '',
          ionicon: 'cart',
          ruta: 'tienda/carrito',
          disabled: false
        },
        {
          id: 'Expositor-tienda-mis-compras',
          titulo: 'Mis Compras',
          desc: '',
          ionicon: 'gift',
          ruta: 'tienda/mis-compras',
          disabled: false
        }
      ]
    },
    // Expositor (Editar datos)
    {
      id: 'expoceres_2',
      titulo: 'Perfil',
      ionicon: '',
      perfil: 'expositor',
      opciones: [
        {
          id: 'contratoExpositor',
          titulo: 'Contrato',
          desc: '',
          ionicon: 'copy',
          ruta: 'contrato',
          ocultar: false,
        },
        {
          id: 'datosGenerales',
          titulo: 'Datos de expositor',
          desc: '',
          ionicon: 'document-text',
          ruta: 'editar/DatosExpositor',
          /*ruta: 'editar-datos/datosGenerales',
          /*repositorio: ExpositorRepo,
          datoUnico: true,
          onLoad: async (pagina) => {
            let confirmada = false;
            if (pagina.editing.participacion_actual) {
              confirmada = pagina.editing.participacion_actual.contrato_confirmado;
            }
            pagina.opc.disableEdit = confirmada;
          }*/
        },
        {
          id: 'Participacion',
          titulo: 'Participación',
          desc: '.',
          ionicon: 'ribbon',
          ruta: 'participacion',
        },
        {
          id: 'ExpoceresDigital_Expositor',
          titulo: 'Editar micrositio',
          desc: 'Apartado para editar datos de expositor utilizados para Expoceres Digital y Stand virtual.',
          ionicon: 'home',
          ruta: 'editar-stand'
        },
        {
          id: 'DatosFiscales',
          titulo: 'Datos fiscales',
          desc: 'En este apartado puedes dar de alta tus datos de facturación. En caso requerir factura para varias razones sociales, favor de darlas de alta e informar al Comité Organizador de Expoceres.',
          ionicon: 'logo-usd',
          ruta: 'editar-datos/DatosFiscales',
          repositorio: DatosFiscalesRepo
        },
        {
          id: 'factura',
          titulo: 'Facturas',
          ionicon: 'today',
          ruta: 'facturas'
        },
        /*
        {
          id: 'Direccion',
          titulo: 'Direcciones',
          desc: 'Direcciones registradas para facturación o recibir impresos.',
          ionicon: 'bookmarks',
          ruta: 'editar-datos/Direccion',
          repositorio: DireccionRepo
        },*/
        {
          id: 'Gafete',
          titulo: 'Gafetes',
          desc: 'En este apartado puede dar de alta a las personas que portarán gafetes de su empresa en el evento.',
          ionicon: 'pricetags',
          ruta: 'editar-datos/Gafete',
          repositorio: GafeteRepo
        },
        {
          id: 'Oferton',
          titulo: 'Ofertón Expoceres',
          desc: 'menu/Oferton/desc',
          ionicon: 'cash',
          ruta: 'editar-datos/Oferton',
          repositorio: Oferton.Repo
        },
        {
          id: 'Invitacion',
          titulo: 'Invitaciones',
          desc: 'Si deseas que tus clientes reciban invitación personalizada, dalos de alta en este apartado. Comité organizador les hará llegar una invitación electrónica a las personas que aquí agregues, con el único objetivo de darle mayor difusión a tu participación en nuestro magno evento.',
          ionicon: 'mail',
          ruta: 'editar-datos/Invitacion',
          repositorio: InvitacionRepo
        }
      ]
    },
    // Staff de expositores
    {
      id: 'staff-expositores',
      titulo: 'Staff Expositores',
      ionicon: '',
      perfil: 'staff_expositor',
      opciones: [
        {
          id: 'staff-expositor-notificaciones',
          titulo: 'MENUS.EXPOCERES.NOTICIAS',
          ionicon: 'newspaper',
          ruta: 'noticias',
          accion: 'noticias'
        },
        {
          id: 'VisitaStandLobby',
          titulo: 'Micrositios',
          ionicon: 'home',
          ruta: 'stand-lobby',
          // ocultar: true
        },
        {
          id: 'staff-expositor-notificaciones-editar',
          titulo: 'Editar notificaciones',
          desc: '',
          ocultar: true,
          ionicon: 'newspaper',
          ruta: '**',
          repositorio: NotificacionRepo
        },
        {
          id: 'ContactarExpositor',
          titulo: 'Directorio de expositores',
          ionicon: 'chatbubbles',
          ruta: 'directorio-de-expositores',
          disabled: false,
          ocultar: true,
          grupos: ['negocios']
        },
        {
          id: 'expositores',
          titulo: 'Expositores',
          ionicon: 'people',
          ruta: 'editar-datos/expositores',
          repositorio: Expositor.ExpositoresEditorRepo,
          reloadEvent: 'ConfigService.editarExpositor',
          onMenuClick: (events: EventsService, navCtrl: NavController) => {
            events.publish('ConfigService.editarExpositor', null);
            ExpositorRepo.get();
            return true;
          },
          onDatoClick: (dato: Dato, events: EventsService, navCtrl: NavController) => {
            console.log('CLICK EN DATO', dato);
            events.publish('ConfigService.editarExpositor', dato);
            navCtrl.navigateForward('editar/DatosExpositor');
            return false;
          },
          onDatoSaved: (dato: Dato, events: EventsService, navCtrl: NavController) => {
            events.publish('ConfigService.editarExpositor', dato);
            navCtrl.navigateForward('participacion');
            return false;
          },
          editorActions: [
            {
              action: (dato: Dato, src: any, events: EventsService, navCtrl: NavController) => {
                console.log('CLICK 2021', dato);
                events.publish('ConfigService.editarExpositor', dato);
                navCtrl.navigateForward('participacion');
              },
              id: 'registrar',
              title: 'ERR',
              isVisible: (dato: Dato) => dato['participacion_actual'] === null
            }
          ]
        },
        {
          id: 'staff-tipos-de-contratos',
          titulo: 'Tipos de contratos',
          desc: '',
          ionicon: 'document-text',
          ruta: 'editar-datos/staff-tipos-de-contratos',
          repositorio: TipoDeContrato.Repo
        },
        {
          id: 'materialoficial',
          titulo: 'Material oficial',
          ionicon: 'copy',
          ruta: 'material-oficial'
        },
        {
          id: 'materialoficial-editar',
          titulo: 'Material oficial',
          ionicon: 'copy',
          ruta: '**',
          ocultar: true,
          repositorio: MaterialOficialRepo
        },
        {
          id: 'staff-expositor-grupos',
          titulo: 'Grupos',
          desc: '',
          ionicon: 'people',
          ruta: 'editar-datos/staff-expositor-grupos',
          repositorio: GrupoExpositorRepo
        },
        {
          id: 'staff-expositor-giros',
          titulo: 'Giros',
          desc: '',
          ionicon: 'briefcase',
          ruta: 'editar-datos/staff-expositor-giros',
          repositorio: Giro.Repositorio
        },
        {
          id: 'staff-grupos-sponsor',
          titulo: 'Grupos Sponsors',
          ionicon: 'people',
          ruta: 'editar-datos/staff-grupos-sponsor',
          repositorio: GrupoSponsor.Repositorio,
        },
        /*
        {
          id: 'staff-expositor-categorias-proveedores',
          titulo: 'Categoría de proveedores',
          ionicon: 'cube',
          ruta: 'editar-datos/staff-expositor-categorias-proveedores',
          repositorio: CategoriaProvedor.Repo,
        },
        {
          id: 'staff-expositor-proveedores',
          titulo: 'Proveedores',
          ionicon: 'cube',
          ruta: 'editar-datos/staff-expositor-proveedores',
          repositorio: Proveedor.Repo,
        },
        {
          id: 'staff-visitante-restaurantes-editar',
          titulo: 'Gastronómico',
          desc: '',
          ocultar: false,
          ionicon: 'restaurant',
          ruta: 'editar-datos/staff-visitante-restaurantes-editar',
          repositorio: Gastronomico.Repo
        },*/
        {
          id: 'staff-reportes',
          titulo: 'Reportes',
          desc: '',
          ionicon: 'copy',
          ruta: 'reportes'
        },
      ]
    },
    // Staff de visitantes
    {
      id: 'staff-visitantes',
      titulo: 'Staff Visitantes',
      ionicon: '',
      perfil: 'staff_visitante',
      opciones: [
        {
          id: 'Notificaciones',
          titulo: 'MENUS.EXPOCERES.NOTICIAS',
          ionicon: 'newspaper',
          ruta: 'noticias'
        },
        {
          id: 'RegistrarVisitante',
          titulo: 'Registrar entrada',
          ionicon: 'people',
          ruta: 'registrar-entrada',
        },
        {
          id: 'RegistrarEnConferencia',
          titulo: 'Registrar en conferencia',
          ionicon: 'people',
          ruta: 'registrar-en-conferencia',
        },
        {
          id: 'EditarEdiciones',
          titulo: 'Editar ediciones',
          ionicon: 'calendar',
          ruta: 'editar-datos/EditarEdiciones',
          repositorio: EdicionExpoceres.Repo,
          saveWithPatch: true
        },
        {
          id: 'EditarEventos',
          titulo: 'Editar eventos',
          ionicon: 'calendar',
          ruta: 'editar-datos/EditarEventos',
          repositorio: Conferencia.Repo
        },
        /*{
          id: 'staff-visitante-restaurantes-editar',
          titulo: 'Gastronómico',
          desc: '',
          ocultar: false,
          ionicon: 'restaurant',
          ruta: 'editar-datos/staff-visitante-restaurantes-editar',
          repositorio: Gastronomico.Repo
        },
        {
          id: 'staff-visitante-CategoriasFotografias-editar',
          titulo: 'Galerías',
          desc: '',
          ocultar: false,
          ionicon: 'images',
          ruta: 'editar-datos/staff-visitante-CategoriasFotografias-editar',
          repositorio: CategoriasFotografias.Repo
        },
        {
          id: 'staff-visitante-Fotografias-editar',
          titulo: 'Fotografías de Galerías',
          desc: '',
          ocultar: false,
          ionicon: 'images',
          ruta: 'editar-datos/staff-visitante-Fotografias-editar',
          repositorio: Fotografias.Repo
        },*/
        {
          id: 'staff-reportes',
          titulo: 'Reportes',
          desc: '',
          ionicon: 'copy',
          ruta: 'reportes'
        }
      ]
    },
    // Staff de visitantes (Tienda)
    /* ocultado por isaí lopez el miercole 22 de febrero del 2023 relacionado al issue #292
    {
      id: 'staff-visitante_tienda',
      titulo: 'Tienda',
      ionicon: '',
      perfil: 'staff_visitante',
      opciones: [
        {
          id: 'staff-visitante-conferencia-magistral',
          titulo: 'Conferencia magistral',
          desc: '',
          ionicon: 'cart',
          ruta: 'conferencia-magistral',
          // disabled: true
        },
        {
          id: 'staff-visitante-tienda',
          titulo: 'Boletos comprados',
          desc: '',
          ionicon: 'cart',
          ruta: 'tienda/mis-compras',
          // disabled: false
        },
      ]
    },
    */
    // Staff de visitantes
    {
      id: 'staff_visitante_entradas',
      titulo: 'Staff Entrada',
      ionicon: '',
      perfil: 'staff_visitante_entradas',
      opciones: [
        {
          id: 'staff-landing',
          titulo: 'staff-landing',
          ionicon: 'people',
          ruta: 'staff-landing',
          ocultar: true,
          interno: true
        },
        {
          id: 'RegistrarVisitante',
          titulo: 'Registrar entrada',
          ionicon: 'people',
          ruta: 'registrar-entrada',
          disabled: false
        },
        {
          id: 'RegistrarEnConferencia',
          titulo: 'Registrar en conferencia',
          ionicon: 'people',
          ruta: 'registrar-en-conferencia',
          disabled: false
        },
      ]
    },
    // Staff de Expositor (Edicion de perfil de expositor seleccionado)
    {
      id: 'editar-expositor',
      titulo: 'Nombre del expositor aqui',
      ionicon: '',
      disabled: true,
      perfil: 'staff_expositor',
      opciones: [
        {
          id: 'staff-expositor-contrato',
          titulo: 'Contrato',
          desc: '',
          ionicon: 'copy',
          ruta: 'contrato',
          ocultar: false,
        },
        {
          id: 'staff-expositor-datosGenerales',
          titulo: 'Datos de expositor',
          desc: '',
          ionicon: 'document-text',
          ruta: 'editar/DatosExpositor',
          // repositorio: ExpositorRepo,
          // datoUnico: true
        },
        {
          id: 'staff-expositor-participacion',
          titulo: 'Participación',
          desc: '',
          ionicon: 'ribbon',
          ruta: 'participacion',
          // repositorio: ParticipacionRepo,
          // datoUnico: true
        },
        {
          id: 'ExpoceresDigital_Staff',
          titulo: 'Expoceres digital',
          desc: 'Apartado para editar datos de expositor utilizados para Expoceres Digital y Stand virtual.',
          ionicon: 'home',
          ruta: 'editar-stand'
        },
        {
          id: 'staff-expositor-datosFiscales',
          titulo: 'Datos fiscales',
          desc: 'En este apartado puedes dar de alta tus datos de facturación. En caso requerir factura para varias razones sociales, favor de darlas de alta e informar al Comité Organizador de Expoceres.',
          ionicon: 'logo-usd',
          ruta: 'editar-datos/staff-expositor-datosFiscales',
          repositorio: DatosFiscalesRepo,
        },
        {
          id: 'factura',
          titulo: 'Facturas',
          ionicon: 'today',
          ruta: 'facturas'
        },
        {
          id: 'staff-expositor-direccion',
          titulo: 'Direcciones',
          desc: 'Direcciones registradas para facturación o recibir impresos.',
          ionicon: 'bookmarks',
          ruta: 'editar-datos/staff-expositor-direccion',
          repositorio: DireccionRepo,
        },
        /*{
          id: 'StaffExpositorStandVirtual',
          titulo: 'Stand virtual',
          ionicon: 'home',
          ruta: 'stand-editor',
          ocultar: true
        },*/
        {
          id: 'Contacto',
          titulo: 'Contactos',
          desc: 'Contactos registrados.',
          ionicon: 'people',
          ruta: 'editar-datos/Contacto',
          repositorio: ContactoRepo
        },
        {
          id: 'staff-expositor-proveedor-stand',
          titulo: 'Proveedor Stand',
          desc: 'Proveedor Stand.',
          ionicon: 'people',
          ruta: 'editar-datos/staff-expositor-proveedor-stand',
          repositorio: ProveedorStandRepo
        },
        {
          id: 'staff-expositor-gafete',
          titulo: 'Gafetes',
          desc: 'En este apartado puede dar de alta a las personas que portarán gafetes de su empresa en el evento.',
          ionicon: 'pricetags',
          ruta: 'editar-datos/staff-expositor-gafete',
          repositorio: GafeteRepo,
        },
        {
          id: 'staff-expositor-usuarios',
          titulo: 'Usuarios',
          desc: '',
          ionicon: 'person',
          ruta: 'editar-datos/staff-expositor-usuarios',
          disableEdit: true,
          repositorio: ExpositorUsuario.Repo,
          editorActions: [
            {
              action: (dato: Dato, src: any, events: EventsService, navCtrl: NavController) => {
                console.log('activando usuario', dato);
                events.publish('GlobalFuncsService.activarUsuarioExpositor', dato);
              },
              id: 'registrar',
              title: 'Activar',
              isVisible: (dato: Dato) => !dato['activo']
            },
            {
              action: (dato: Dato, src: any, events: EventsService, navCtrl: NavController) => {
                console.log('desactivando usuario', dato);
                events.publish('GlobalFuncsService.desactivarUsuarioExpositor', dato);
              },
              id: 'registrar',
              title: 'Desactivar',
              isVisible: (dato: Dato) => dato['activo']
            }
          ]
        },
        {
          id: 'staff-expositor-invitacion',
          titulo: 'Invitaciones',
          desc: 'Si deseas que tus clientes reciban invitación personalizada, dalos de alta en este apartado. Comité organizador les hará llegar una invitación electrónica a las personas que aquí agregues, con el único objetivo de darle mayor difusión a tu participación en nuestro magno evento.',
          ionicon: 'list-box',
          ruta: 'editar-datos/staff-expositor-invitacion',
          repositorio: InvitacionRepo,
          // noEditable: false
        }
      ]
    },
    /* Ocultado por Isaí Lopez el jueves 22 de febrero relacionado al issue #292 
    // Staff de Expositor (Tienda)
    {
      id: 'expoceres_3',
      titulo: 'Tienda',
      ionicon: '',
      perfil: 'staff_expositor',
      opciones: [
        {
          id: 'staff-expositores-consumos',
          titulo: 'Consumos',
          desc: '',
          ionicon: 'cart',
          ruta: 'consumos',
        },
        {
          id: 'Staff-Expositores-CategoriaProductos',
          titulo: 'Categorias',
          desc: '',
          ionicon: 'cart',
          ruta: 'editar-datos/Staff-Expositores-CategoriaProductos',
          repositorio: CategoriasProductos.Repo
        },
        {
          id: 'Staff-Expositores-Productos',
          titulo: 'Productos',
          desc: '',
          ionicon: 'cart',
          ruta: 'editar-datos/Staff-Expositores-Productos',
          repositorio: Producto.Repo
        },
      ]
    }
    */
  ],
};

// ------------------ Plantillas para paginas editoras ----------------------
EditorPageTemplates['DatosExpositor'] = new EditorPageTemplate({
  title: 'Datos de expositor',
  models: [
    ModelEditorTemplates['Expositor'],
    ModelEditorTemplates['ActaConstitucional'],
    ModelEditorTemplates['PoderLegal']
  ]
});
