// tslint:disable: align

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NavController, MenuController } from '@ionic/angular';
import { retry, delayWhen, tap, retryWhen, mergeMap, finalize } from 'rxjs/operators';
import { EventosService } from './eventos.service';
import { CommentStmt } from '@angular/compiler';

const networkRetryStrategy = ({
  maxRetryAttempts = 4,
  scalingDuration = 1000,
  excludedStatusCodes = [400, 401, 403, 404, 405, 500]
}: {
  maxRetryAttempts?: number,
  scalingDuration?: number,
  excludedStatusCodes?: number[]
} = {}) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      // if maximum number of retries have been met
      // or response is a status code we don't wish to retry, throw error
      if (
        retryAttempt > maxRetryAttempts ||
        excludedStatusCodes.find(e => e === error.status)
      ) {
        return throwError(error);
      }
      console.log(
        `Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`
      );
      // retry after 1s, 2s, etc...
      return timer(retryAttempt * scalingDuration);
    })
    // ,finalize(() => console.log('We are done!'))
  );
};

/** Tipo de request que se desea hacer al webapi */
export enum RequestType { POST, DELETE, GET, PUT, PATCH }

/** Opciones adicionales que se pueden utilizar para un request */
export class WebapiCallOptions {
  noToken?= false;
  responseType?: string;
  noRetries?= false;
  // timeout ?= 15000;
  // retries ?= 3;
}

/** Servicio que controla la comunicacion contra el backend del app. */
@Injectable({
  providedIn: 'root'
})
export class WebapiService {
  static Singleton: WebapiService;
  token = '';
  webapiUrl = 'https://plataforma.expoceres.com.mx/api';
  logeado = false;
  refreshToken = '';
  private antiCache = false;
  private activarLogeo = false;
  private timeout = 15000;

  constructor(
    public httpClient: HttpClient,
    public navCtrl: NavController,
    public eventServ: EventosService,
    private menuCtrl: MenuController) {
    this.webapiUrl = environment.backendApiURL;
    WebapiService.Singleton = this;
    this.token = localStorage.getItem('expo_token');
    this.refreshToken = localStorage.getItem('refreshToken');
    const cordova = window['cordova'];
    /*if (!environment.production && !cordova) {
      this.webapiUrl = 'api';
      // console.log("Reemplazado");
    }*/
    this.logeado = this.hasToken();
  }

  public login(tipo: string, user: string, pass: string): Promise<boolean> {
    // this.clearToken();
    localStorage.removeItem('staffExpositor');
    const url = 'api-token-auth/';
    const params = { username: user, password: pass };
    return new Promise<boolean>((resolve, reject) => {
      this.post(url, params)
        .then((response) => {
          if (response.token !== undefined) {
            this.token = 'bearer ' + response.token;
            localStorage.setItem('expo_token', this.token);
            localStorage.setItem('lastlogin', (new Date()).getTime().toString());
            this.logeado = true;
            resolve(true);
          } else {
            reject('Token not found in response!');
          }
        }, (what) => {
          console.log(what);
          reject(what);
        })
        .catch((what) => {
          console.log(what);
          reject(what);
        });
    });
  }

  public logout() {
    this.handleLogout().catch((err) => { console.error(err); });
  }

  private async handleLogout(): Promise<boolean> {
    try {
      await this.post('rest-auth/logout/');
    } catch (err) {
      console.error(err);
      return false;
    } finally {
      this.clearToken();
      this.logeado = false;
      await this.navCtrl.navigateRoot('inicio', {animated: false});
      localStorage.removeItem('tipo');
      localStorage.removeItem('expo_token');
      localStorage.removeItem('lastlogin');
      localStorage.removeItem('ultimoPerfil');
      location.reload();
    }
    return true;
  }

  public get(endpoint: string, params?: any, opts?: WebapiCallOptions): Promise<any> {
    return this.doRequest(RequestType.GET, endpoint, params, opts);
  }

  public post(endpoint: string, params?: any, opts?: WebapiCallOptions): Promise<any> {
    return this.doRequest(RequestType.POST, endpoint, params, opts);
  }

  public del(endpoint: string, id?: any, opts?: WebapiCallOptions): Promise<any> {
    if (id) {
      return this.doRequest(RequestType.DELETE, endpoint + id + '/', null, opts);
    } else {
      return this.doRequest(RequestType.DELETE, endpoint, null, opts);
    }
  }

  public put(endpoint: string, params?: any, opts?: WebapiCallOptions): Promise<any> {
    return this.doRequest(RequestType.PUT, endpoint, params, opts);
  }

  public patch(endpoint: string, params?: any, opts?: WebapiCallOptions): Promise<any> {
    return this.doRequest(RequestType.PATCH, endpoint, params, opts);
  }

  public doRequest(type: RequestType, endpoint: string, params: any, opts: WebapiCallOptions): Promise<any> {
    if (this.activarLogeo) {
      console.log('Haciendo peticion ' + type.toString() + ' a ' + endpoint);
    }

    let apiurl = this.webapiUrl;

    let header = new HttpHeaders(); // { 'Content-Type': 'application/json' });

    /*if (type === RequestType.GET) {
      header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    }*/

    // Token
    if (this.token && this.token.length > 0) { // && (opts && !opts.noToken)) {
      header = header.append('Authorization', this.token);
    }

    if (endpoint.toLowerCase().indexOf('http') >= 0) {
      apiurl = '';
    } else {
      endpoint = '/' + endpoint;
    }

    // Response type
    let respType: any;
    if (opts && opts.responseType) {
      respType = opts.responseType;
    }

    let request: Observable<any>;
    switch (type) {
      case RequestType.GET: {
        const httpparams = new HttpParams({ fromObject: params });
        header = header.append('Content-Type', 'application/x-www-form-urlencoded');
        // console.log(endpoint);
        request = this.httpClient.get(apiurl + endpoint, { headers: header, params: httpparams, responseType: respType });
      }
        break;
      case RequestType.POST: {
        // header = header.append('Content-Type', 'multipart/form-data');
        request = this.httpClient.post(apiurl + endpoint, params, { headers: header, responseType: respType });
      }
        break;
      case RequestType.DELETE: {
        request = this.httpClient.delete(apiurl + endpoint, { headers: header, responseType: respType });
      }
        break;
      case RequestType.PUT: {
        request = this.httpClient.put(apiurl + endpoint, params, { headers: header, responseType: respType });
      }
        break;
      case RequestType.PATCH: {
        request = this.httpClient.patch(apiurl + endpoint, params, { headers: header, responseType: respType });
      }
        break;
    }

    // No retries
    if (opts && opts.noRetries) {
      return new Promise<any>((resolve, reject) => {
        request.toPromise()
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            if (err.status === 403 || err.statusText === 'Forbidden') {
              // this.clearToken();
              // this.navCtrl.navigateRoot('inicio');
              // this.menuCtrl.enable(false, 'menu_lateral');
              console.error('DETECTADO TOKEN INVALIDO O NO TIENE PERMISOS!');
            }
            reject(err);
          });
      });
    }

    // 3 retries
    return new Promise<any>((resolve, reject) => {
      request.pipe(retryWhen(networkRetryStrategy())).toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          if (err.status === 403 || err.statusText === 'Forbidden') {
            this.clearToken();
            this.navCtrl.navigateRoot('inicio');
            this.menuCtrl.enable(false, 'menu_lateral');
            console.log('TOKEN INVALIDO O NO TIENE PERMISOS!');
          }
          reject(err);
        });
    });

    // TODO add retryWhen to the observable
    /*
    return new Promise<any>((resolve, reject) => {
      request.toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          if (err.status === 403 || err.statusText === 'Forbidden') {
            this.clearToken();
            this.navCtrl.navigateRoot('inicio');
            this.menuCtrl.enable(false, 'menu_lateral');
            console.log('TOKEN INVALIDO O NO TIENE PERMISOS!');
          }
          reject(err);
        });
    });
    */
    // return request.toPromise();
  }

  hasToken() {
    if (this.token !== null && this.token !== undefined && this.token.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  async checkToken() {
    if (!this.hasToken()) {
      return false;
    }
  }

  verifyToken(): Promise<boolean> {
    // Verificacion agregada para incluir la verificacion del token en AuthGuard
    if (!this.hasToken()) { return new Promise((resolve, reject) => resolve(false)); }

    const
      url = 'api-token-verify/',
      params = { token: localStorage.getItem('expo_token').slice(7) };

    return new Promise((resolve, reject) => {
      this.post(url, params)
        .then((response) => resolve(true))
        .catch(async (error) => {
          await this.logout();
          resolve(false);
        });
    });
  }


  clearToken() {
    this.logeado = false;
    this.token = '';
    localStorage.removeItem('tipo');
    localStorage.removeItem('expo_token');
    localStorage.removeItem('lastlogin');
    localStorage.removeItem('ultimoPerfil');
  }

  public async getObject<T>(endpoint: string, typeClass: new () => T, params?: any, opts?: WebapiCallOptions): Promise<T> {
    const result = await this.doRequest(RequestType.GET, endpoint, params, opts);
    if (result) {
      if (Array.isArray(result)) {
        const res: T[] = [];
        for (const obj in result) {
          if (result.hasOwnProperty(obj)) {
            const p = Object.assign(new typeClass(), result[obj]);
            // res.push(p);
            return p;
          }
        }
      } else {
        const p = Object.assign(new typeClass(), result);
        return p;
      }
    } else {
      return null;
    }
  }

  public async getObjects<T>(endpoint: string, typeClass: new () => T, params?: any, opts?: WebapiCallOptions): Promise<T[]> {
    const result = await this.doRequest(RequestType.GET, endpoint, params, opts);
    const res: T[] = [];
    if (result) {
      if (Array.isArray(result)) {
        for (const obj in result) {
          if (result.hasOwnProperty(obj)) {
            const p = Object.assign(new typeClass(), result[obj]);
            res.push(p);
          }
        }
      } else {
        const p = Object.assign(new typeClass(), result);
        res.push(p);
      }
    }
    return res;
  }

  /** Transforms a relative URL into a full URL using the current endpoint */
  apiURLtoFullURL(rawURL: string): string {
    if (!rawURL.startsWith('http')) {
      if (!rawURL.startsWith('/')) {
        rawURL = '/' + rawURL;
      }
      rawURL = this.webapiUrl + rawURL;
    }
    if (rawURL.indexOf('?') < 0) {
      if (!rawURL.endsWith('/')) {
        rawURL += '/';
      }
    }
    return rawURL;
  }

  /** Simple conversion of a django medial url into a full URL */
  fullMediaUrl(mediaUrl: string) {
    return environment.backendURL + mediaUrl;
  }
}



