// tslint:disable: no-output-on-prefix
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Injector } from '@angular/core';
import { EditorForm, EditorInput } from 'src/app/models/editor';
import { Dato, Repositorio } from 'src/app/models/dato';
import { FormGroup, FormBuilder, Validators, ValidatorFn, FormControl } from '@angular/forms';
import { ExpoConfigMenuOpcion, EditorOption } from 'src/app/models/menu';
import { AlertController, LoadingController, NavController, ModalController } from '@ionic/angular';
import { ConfigService } from 'src/app/servicios/config.service';
import { UiService } from 'src/app/servicios/ui.service';
import { Tools } from 'src/app/servicios/tools';
import { HttpErrorResponse } from '@angular/common/http';
import { isArray, isObject } from 'util';
import { ulid } from 'ulid';
import { EventsService } from 'src/app/servicios/events.service';
import { FileService } from 'src/app/servicios/file.service';
import { TranslateService } from '@ngx-translate/core';
import { EditorGeoComponent } from '../editor-geo/editor-geo.component';

// tslint:disable-next-line: max-line-length
const EMAILPATTERN = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
// tslint:disable-next-line: max-line-length
const URLPATTERN = /^(?:http|ftp)s?:\/\/(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\[?[A-F0-9]*:[A-F0-9:]+\]?)(?::\d+)?(?:\/?|[/?]\S+)$/i;

function NestedEditorValidator(root: EditorComponent, prop: string): ValidatorFn {

  // console.log('A> NestedEditorValidator: ', root.childEditors);
  return function doValidate(control: FormControl) {
    let tieneErrores = false;
    // DO check
    // console.log('A> NestedEditorValidator: ', root.childEditors);
    for (const editor of root.childEditors) {
      // console.log('B> NestedEditorValidator: ', editor);
      if (editor.nestedPropName === prop) {
        tieneErrores = !editor.formGroup.valid;
        // console.log('C> Validation ran, result ', tieneErrores);
        break;
      }
    }
    return tieneErrores ? { nested: { value: control.value } } : null;
  };
}

export class EditorEvent {
  component: EditorComponent;
  value: Dato;
  event: 'saved' | 'deleted';
  constructor(other?: Partial<EditorEvent>) {
    if (other) {
      Object.assign(this, other);
    }
  }
}

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit {
  @ViewChild('botonOcultoImagen', { static: false }) botonOcultoImagen: any;
  @Output() datoSaved = new EventEmitter<EditorEvent>();
  @Output() canceled = new EventEmitter<EditorEvent>();
  @Input() editorForm: EditorForm;
  @Input() dato: Dato;
  @Input() repo: Repositorio;
  @Input() opc: ExpoConfigMenuOpcion;
  @Input() isNested: boolean;
  @Input() parent: EditorComponent;
  @Input() nestedPropName: string;
  @Input() isNew = false;
  @Input() saveText = 'EDITOR.GUARDAR';
  @Input() deleteText = 'EDITOR.ELIMINAR';
  @Input() cancelText = 'EDITOR.CANCELAR';
  @Input() userData;
  childEditors: EditorComponent[] = [];
  childEditorsByName: { [name: string]: EditorComponent; } = {};
  @Input() isModal = false;
  datosParaSelects = {};
  validationMessages = {};
  dirtyFields: {} = {};
  formGroup: FormGroup;
  built: boolean;
  target: Dato;
  files: { [name: string]: File; } = {};
  images: {} = {};
  limit: number;
  vars: {
    edicion: number;
    expositor: number;
    usuario: number;
    noEsNuevo: boolean;
    esNuevo: boolean;
    esExpositor: boolean;
    noEsExpositor: boolean;
    esStaff: boolean;
    noEsStaff: boolean;
    esStaffExpositor: boolean;
    esStaffVisitante: boolean;
  } = {} as any;
  private fileInputCambiar: EditorInput;
  private shouldSaveAsJson = false;

  constructor(
    public uiServ: UiService,
    public configServ: ConfigService,
    private formBuilder: FormBuilder,
    private ldgCtrl: LoadingController,
    private events: EventsService,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private injector: Injector,
    public fileServ: FileService,
    private translateServ: TranslateService,
    private alertCtrl: AlertController) { }

  async ngOnInit() {
    // # Modo de modal
    if (this.isModal) {
      this.editorForm = this.repo.editor;
    }

    // # Modo incrustado
    if (this.isNested) {
      // console.log('My parent is ', this.parent);
      // console.log('Componente editor iniciado en modo nested');
      const exp = new ExpoConfigMenuOpcion();
      exp.disableDelete = true;
      exp.disableAdd = true;
      exp.disableEdit = this.parent.opc.disableEdit;
      if (this.isNew) {
        exp.disableEdit = false;
        // console.log('PaReNt OPTIOn ', this.parent.opc);
      }
      // exp.datoUnico
      exp.repositorio = null;
      exp.ctor = null;
      this.opc = exp;
      // console.log('CHILD exp', exp);
    }

    // # Crear dato
    if (!this.dato) {
      this.dato = new this.repo.ctr();
    }

    // # Llenar variables de entorno (para showWhen, disableWhen, activeWhen)
    const usr = await this.configServ.usuarioAsync.getValue();
    this.vars.esNuevo = this.dato.id !== undefined;
    this.vars.noEsNuevo = !this.vars.esNuevo;
    this.vars.esExpositor = this.configServ.esExpositor;
    this.vars.noEsExpositor = !this.vars.esExpositor;
    this.vars.esStaff = this.configServ.isStaff;
    this.vars.noEsStaff = !this.vars.esStaff;
    this.vars.esStaffExpositor = this.configServ.grupo === 'staff_expositor';
    this.vars.esStaffVisitante = this.configServ.grupo === 'staff_visitante';
    this.vars.edicion = this.configServ.edicionId;
    this.vars.usuario = usr.id;
    if (this.configServ.staffExpositorEditando && this.configServ.isStaff) {
      this.vars.expositor = this.configServ.staffExpositorEditando.id;
    } else {
      this.vars.expositor = ConfigService.ExpositorID;
    }
    if (this.opc.editVars) {
      for (const key in this.opc.editVars) {
        if (this.opc.editVars.hasOwnProperty(key)) {
          this.vars[key] = this.opc.editVars[key];
        }
      }
    }

    // # Crear form group
    const frmGrp = {};
    for (const input of this.editorForm.inputs) {
      if (!input.property) { continue; }

      // Ignore auto vars
      if (input.type.startsWith('auto')) { continue; }

      // Ignore inactive inputs
      if (!this.isInputActive(input)) { continue; }

      const validatorsForInput = [];
      const validationsMessagesForInput = [];

      // Required
      if (input.required && input.type !== 'nested') {
        validatorsForInput.push(Validators.required);
        validationsMessagesForInput.push({
          type: 'required',
          message: this.translateServ.instant('EDITOR.VALIDAR_REQUERIDO')
        });
      }
      // Tel
      if (input.type === 'tel') {
        validatorsForInput.push(Validators.minLength(10));
        validatorsForInput.push(Validators.maxLength(10));
        validatorsForInput.push(Validators.pattern(/^\d*$/));
        validationsMessagesForInput.push({
          type: 'maxlength',
          message: this.translateServ.instant('EDITOR.VALIDAR_TELEFONO')
        });
        validationsMessagesForInput.push({
          type: 'minlength',
          message: this.translateServ.instant('EDITOR.VALIDAR_TELEFONO')
        });
        validationsMessagesForInput.push({
          type: 'pattern',
          message: this.translateServ.instant('EDITOR.VALIDAR_TELEFONO_DIGITOS')
        });
      }
      // Email
      if (input.type === 'email') {
        validatorsForInput.push(Validators.pattern(EMAILPATTERN));
        validationsMessagesForInput.push({
          type: 'pattern',
          message: this.translateServ.instant('EDITOR.VALIDAR_EMAIL')
        });
      }
      // Custom pattern
      if (input.pattern && input.patternText) {
        validatorsForInput.push(Validators.pattern(input.pattern));
        validationsMessagesForInput.push({ type: 'pattern', message: input.patternText });
      }
      // Texteditor
      if (input.type === 'texteditor') {
        if (input.max) {
          validationsMessagesForInput.push({
            type: 'maxlength',
            message: this.translateServ.instant('EDITOR.VALIDAR_TAMAÑO_MAX', { MAX: input.max })
          });
        }
        if (input.min) {
          validationsMessagesForInput.push({
            type: 'minlength',
            message: 'Se require de más contenido (Min: ' + input.max + ').'
          });
        }
      }
      // Nested editor
      if (input.type === 'nested') {
        // validatorsForInput.push(NestedEditorValidator(this, input.property));
        // validationsMessagesForInput.push({ type: 'nested', message: 'Faltan datos' });
        this.shouldSaveAsJson = true;
        continue;
      }
      // URL
      if (input.type === 'url') {
        validatorsForInput.push(Validators.pattern(URLPATTERN));
        validationsMessagesForInput.push({
          type: 'pattern',
          message: this.translateServ.instant('EDITOR.VALIDAR_URL')
        });
      }

      // # Crear form control
      this.validationMessages[input.property] = validationsMessagesForInput;
      frmGrp[input.property] = new FormControl({
        value: this.dato[input.property],
        disabled: this.isInputDisabled(input)
      }, validatorsForInput);
      // validatorsForInput.length > 0 ? ['', validatorsForInput] : [''];
    }

    // Crear formgroup
    this.formGroup = this.formBuilder.group(frmGrp);
    // console.log('Form group:', this.formGroup);

    // # Activar validaciones si el dato ya estaba creado
    if (this.dato && this.dato.id) {
      for (const prop of this.editorForm.inputs) {
        this.dirtyFields[prop.property] = true;
      }
    }


    if (this.isNested) {
      this.target = this.dato;
      this.parent.childEditors.push(this);
      // this.parent.formGroup.addControl('',)
      this.parent.formGroup.addControl(this.nestedPropName, this.formGroup);
      // this.formGroup.setParent(this.parent.formGroup);
      this.parent.childEditorsByName[this.nestedPropName] = this;

    } else {
      this.target = new this.repo.ctr();
      if (this.dato) {
        Object.assign(this.target, this.dato);
      }
      // console.log("Is new: ", this.isNew);
    }

    // # Cargar datos para selectores
    for (const input of this.editorForm.inputs) {
      if (!input.property) { continue; }
      if (input.type === 'dato') {
        if (!input.repo) {
          console.error('Input de propiedad select "' + input.property + '" no tiene asignado un repositorio!',
            this.editorForm, input);
          throw new Error('ERROR');
        }
        this.datosParaSelects[input.property] = await input.repo.get();
      }
    }
    setTimeout(() => {
      for (const abs in this.formGroup.controls) {
        if (!this.formGroup.controls.hasOwnProperty(abs)) { continue; }
        const ctrl = this.formGroup.controls[abs];
        ctrl.updateValueAndValidity();
      }
      this.built = true;
    }, 400);
  }

  reset() {
    this.dirtyFields = {};
  }

  async save(sub: any) {
    //this.fillAutoVars(this.target);

    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      await this.uiServ.alert(this.translateServ.instant('EDITOR.ERRORES'));
      return;
    }

    // Build form data and transform data
    let jsonData: any = {};
    const res = this.collectValues();
    jsonData = res.json;
    const data = res.formData;

    const gif = await this.ldgCtrl.create({ message: this.translateServ.instant('EDITOR.GUARDANDO') });
    await gif.present();
    try {
      const returnedDato = await this.repo.save(
        this.shouldSaveAsJson ? jsonData : this.target,
        this.shouldSaveAsJson ? null : data,
        this.opc.saveWithPatch);
      gif.dismiss();

      if (!this.opc.noDialogs) {
        if (this.opc.customDialogues && this.opc.customDialogues.savedText) {
          await this.uiServ.ok(this.opc.customDialogues.savedText);
        } else {
          await this.uiServ.ok(this.translateServ.instant('EDITOR.GUARDADO'));
        }
      }

      if (this.isModal) {
        if (this.opc.onCancelOverride) {
          this.opc.onCancelOverride();
        } else {
          this.modalCtrl.dismiss({ status: true, dato: returnedDato });
        }
      } else {
        this.datoSaved.emit({ value: returnedDato, component: this, event: 'saved' });
      }
    } catch (err) {
      await gif.dismiss();
      await this.handleErrors(err);
    }
  }

  async delete(sub: any) {
    const dlg = await this.alertCtrl.create({
      message: this.translateServ.instant('EDITOR.ALERTA_BORRAR'),
      buttons: [
        {
          text: 'Aceptar', handler: async () => {
            await dlg.dismiss();
            const deleteGif = await this.ldgCtrl.create({ message: this.translateServ.instant('EDITOR.BORRANDO') });
            await deleteGif.present();
            try {
              await this.repo.delete(this.target);
              deleteGif.dismiss();

              const okDlg = await this.alertCtrl.create({
                message: this.translateServ.instant('EDITOR.BORRADO'),
                header: '✔',
                buttons: [{ text: 'Aceptar', role: 'cancel' }]
              });
              await okDlg.present();

              // this.datoSaved.emit();
              if (this.isModal) {
                if (this.opc.onCancelOverride) {
                  this.opc.onCancelOverride();
                } else {
                  this.modalCtrl.dismiss({ status: true, dato: sub });
                }
              } else {
                this.datoSaved.emit({ value: sub, component: this, event: 'deleted' });
              }

            } catch (err) {
              await deleteGif.dismiss();
              console.error(err);
              await this.uiServ.error(err);
            }
          }
        },
        {
          text: this.translateServ.instant('EDITOR.CANCELAR'), role: 'cancel'
        }]
    });
    await dlg.present();
  }

  collectValues(): { json: {}, formData: FormData } {
    // console.log(this.editorForm.inputs);
    const result = { json: {}, formData: new FormData() };
    for (const prop of this.editorForm.inputs) {
      if (!prop.property) { continue; }

      if (!this.isInputActive(prop)) {
        continue;
      }

      if (this.dato.id && this.opc.saveWithPatch && this.isInputDisabled(prop)) {
        continue;
      }

      let filename;

      const formControl = this.formGroup.get(prop.property);
      let val: any = formControl ? formControl.value : null;

      if (prop.type === 'auto') {
        const varvalue = this.evaluateVar(prop.autoValue, undefined);
        if (varvalue !== undefined) {
          val = varvalue;
          // console.log('Auto lleanado de propiedad ' + prop.property + ' con variable ' + prop.autoValue + ' con valor ', varvalue);
        } else {
          console.error('No se encontró variable ' + prop.autoValue);
        }
      }

      if (prop.type === 'date') {
        const raw = new Date(val);
        const m = (raw.getMonth() < 9 ? '0' : '') + (raw.getMonth() + 1).toString();
        const d = (raw.getDate() < 10 ? '0' : '') + raw.getDate().toString();
        val = raw.getFullYear() + '-' + m + '-' + d;
      }

      if (prop.type === 'file' || prop.type === 'image') {
        // console.log(this.files[prop.property]);
        if (this.files[prop.property]) {
          const fil = this.files[prop.property];
          val = fil;
          // filename = fil.name.replace(/[^\x00-\x7F]/g, '');
          const ext = fil.name.substr(fil.name.lastIndexOf('.') + 1);
          filename = ulid() + '.' + ext;
        } else {
          val = undefined;
        }
      }

      if (prop.type === 'nested') {
        const childObj = {};
        const childFrmGrp = this.childEditorsByName[prop.property].formGroup;
        const childValues = this.childEditorsByName[prop.property].collectValues();
        result.json[prop.property] = childValues.json;
        /*result.formData.append(prop.property, result.formData);
        for (const pr of prop.nestedEditor.inputs) {
          childObj[pr.property] = childFrmGrp.get(pr.property).value;
        }
        val = childObj;*/
      }

      if (prop.type === 'dato' && prop.multiSelect) {
        for (const id in val) {
          if (!val.hasOwnProperty(id)) { continue; }
          result.formData.append(prop.property, val[id]);
        }
        continue;
      }

      if (val !== undefined && val !== null) {
        result.json[prop.property] = val;
        // console.log(prop.property, val, filename);
        if (filename) {
          result.formData.append(prop.property, val, filename);
        } else {
          result.formData.append(prop.property, val);
        }

      }
    }
    return result;
  }

  editObject(obj: any) {
    // console.log('Cargando objeto a editar ', obj);
    const fieldValues = {};
    for (const prop in obj) {
      if (!obj.hasOwnProperty(prop)) { continue; }
      const inputsForPorperty = this.editorForm.inputs.filter(inpt =>
        inpt.property === prop &&
        inpt.type.length &&
        inpt.type !== 'auto' &&
        inpt.type !== '**' &&
        inpt.type !== 'hidden');
      if (!inputsForPorperty.length) { continue; }
      fieldValues[prop] = obj[prop];
    }
    this.formGroup.setValue(fieldValues);
  }

  isFieldDirty(fieldName: string) {
    return this.dirtyFields[fieldName];
  }

  dirtyField(fieldName: string) {
    this.dirtyFields[fieldName] = true;
    if (this.isNested) {
      console.log('Changed nested prop');
      this.parent.dirtyField(this.nestedPropName);
      this.formGroup.updateValueAndValidity();
      this.parent.formGroup.updateValueAndValidity();
      for (const abs in this.parent.formGroup.controls) {
        if (!this.parent.formGroup.controls.hasOwnProperty(abs)) { continue; }
        const ctrl = this.parent.formGroup.controls[abs];
        ctrl.updateValueAndValidity();
      }
      this.parent.formGroup.markAsDirty();
      // this.parent.formGroup.controls[this.nestedPropName].markAsTouched();
      // console.log('ESTADO NESTED ', this.formGroup.valid, this.formGroup, this.formGroup.errors);
    } else { // if (this.formGroup.invalid)
      // console.log('NO VALIDO', this.formGroup, this.formGroup.errors);
      // console.log('ESTADO PARENT ', this.formGroup.valid, this.formGroup, this.formGroup.errors);
    }
  }

  onFileChange(event, formInput?: EditorInput) {
    const frmInpt = formInput ? formInput : this.fileInputCambiar;
    const vm = this;
    this.dirtyFields[frmInpt.property] = true;
    // console.log(event);
    if (event.target.files && event.target.files.length) {
      const file = event.target.files.item(0) as File;
      if (frmInpt.max) {
        if (file.size > frmInpt.max) {
          const txt = 'El archivo seleccionada de ' + Tools.bytesToSize(file.size, 2)
            + ' supera el máximo permitido de <b>' + Tools.bytesToSize(frmInpt.max, 2)
            + '</b> para este campo.';
          this.uiServ.alert(txt);
          return;
        }
      }

      this.files[frmInpt.property] = file;
      if (frmInpt.type === 'image') {
        const reader = new FileReader();
        reader.onload = (e) => {
          // vm.images[frmInpt.property] = e.target.result;
          vm.formGroup.get(frmInpt.property).setValue(reader.result);
        };
        // reader.onload = (function (imgs) { return function (e) { imgs[frmInpt.property] = e.target.result; }; })(this.images);
        reader.readAsDataURL(this.files[frmInpt.property]);
        // this.images[frmInpt.property] = undefined;
      }
    } else {
      delete this.files[frmInpt.property];
      if (frmInpt.type === 'image') {
        this.images[frmInpt.property] = undefined;
      }
    }
  }

  action(act: EditorOption) {
    act.action(this.dato, this, this.events, this.navCtrl);
  }

  getImage(prop: EditorInput) {
    if (this.formGroup.get(prop.property).value) {
      return this.formGroup.get(prop.property).value;
    } else {
      return '';
    }
  }

  cancel() {
    if (this.opc.onCancelOverride) {
      this.opc.onCancelOverride();
    } else {
      this.modalCtrl.dismiss({ status: false });
    }
  }

  async addDato(frmInpt: EditorInput) {
    let exp: ExpoConfigMenuOpcion;
    if (frmInpt.editorOption) {
      exp = this.configServ.buscarOpcion(frmInpt.editorOption);
      //  console.log('Usando de menu para modal de input ' + frmInpt.property);
    } else {
      exp = new ExpoConfigMenuOpcion();
      exp.disableDelete = true;
      exp.disableAdd = true;
      exp.repositorio = frmInpt.repo;
      exp.ctor = frmInpt.repo.ctr;
      // console.log('Creando opcion de menú para modal de input ' + frmInpt.property);
    }
    const modal = await this.modalCtrl.create({
      component: EditorComponent,
      componentProps: {
        isModal: true,
        repo: frmInpt.repo,
        opc: exp
      }
    });
    modal.onDidDismiss()
      .then((modalData) => {
        // this.producto = data.data.sel as Producto;
        // console.log(data);
        if (modalData.data && modalData.data.status) {
          // this.target[frmInpt.property] = data.data.dato.id;
          if (frmInpt.multiSelect) {
            let val = this.formGroup.get(frmInpt.property).value as any[];
            if (!Array.isArray(val)) {
              val = [];
            }
            val.push(modalData.data.dato.id);
          } else {
            this.formGroup.get(frmInpt.property).setValue(modalData.data.dato.id);
          }
        }
      });
    // console.log(modal);
    await modal.present();
  }

  /** Si el input estará visible o invisible, (el input estará activo) */
  isInputVisible(frmInpt: EditorInput): boolean {
    if (!this.built) { return false; }
    if (!frmInpt.showWhen) {
      return this.isInputActive(frmInpt);
    } else {
      return this.evaluateVars(frmInpt.showWhen, true);
    }
  }

  /** Si el input aparecerá, pero no se podrá editar */
  isInputDisabled(frmInpt: EditorInput): boolean {
    if (this.opc.disableEdit) {
      return true;
    }
    if (frmInpt.disabled !== undefined) {
      return frmInpt.disabled;
    }
    if (frmInpt.disableWhen) {
      return this.evaluateVars(frmInpt.disableWhen, false);
    }
    return false;
  }

  /** Si el input no se utilizará en el formulario */
  isInputActive(frmInpt: EditorInput): boolean {
    if (!frmInpt.activeWhen) {
      return true;
    } else {
      return this.evaluateVars(frmInpt.activeWhen, true);
    }
  }

  private evaluateVars(content: string | string[], defaultValue: boolean): boolean {
    if (!content) {
      throw new Error('No content in var evaluation');
    } else {
      if (Array.isArray(content)) {
        for (const varentry of content) {
          const state = this.evaluateVar(varentry, defaultValue);
          if (state !== defaultValue) {
            // console.log('STATE OF ', varentry, ' is ', state);
            return state;
          }
        }
        // console.log('STATE OF ', content, ' is default ', defaultValue);
        return defaultValue;
      } else {
        const state = this.evaluateVar(content, defaultValue);
        // console.log('STATE OF ', content, ' is ', state);
        return state;
      }
    }
  }

  private evaluateVar(varName: string, defaultValue: any): any {
    if (varName.startsWith('obj:')) {
      varName = varName.replace('obj:', '');
      return this.dato[varName];
    }
    if (this.formGroup && this.formGroup.contains(varName)) {
      return this.formGroup.get(varName).value;
    }
    // Enviroment
    if (this.vars[varName] === undefined) {
      // console.log('Property ' + frmInpt.showWhen + ' not found in vars or props');
      return defaultValue;
    } else {
      return this.vars[varName];
    }
  }

  async clickSeleccionarImagen(frmInpt: EditorInput) {
    // console.log(frmInpt);
    this.fileInputCambiar = frmInpt;
    this.botonOcultoImagen.nativeElement.value = '';
    this.botonOcultoImagen.nativeElement.click();
  }

  hasFile(frmInpt: EditorInput): boolean {
    const value = this.formGroup.get(frmInpt.property).value as string;
    if (typeof value !== 'string') { return false; }
    return (value.toLocaleLowerCase().startsWith('http'));
  }

  downloadFile(frmInpt: EditorInput) {
    const value = this.formGroup.get(frmInpt.property).value as string;
    // this.fileServ.downloadURL(value);
    if (value) {
      this.uiServ.abreLink(value);
    }
  }

  getValue(frmInpt: EditorInput) {
    return this.formGroup.get(frmInpt.property).value;
  }

  limpiaInput(frmInpt: EditorInput) {
    if (frmInpt.type === 'email') {
      let value = this.formGroup.get(frmInpt.property).value as string;
      if (value && value.trim) {
        value = Tools.cleanEmail(value);
        this.formGroup.get(frmInpt.property).setValue(value);
      }
      // console.log(value);
    }
  }

  async editGeo(frmInpt: EditorInput) {
    if (this.isInputDisabled(frmInpt)) {
      return;
    }
    const cVal = this.getValue(frmInpt);
    const opc = {
      geoJson: cVal
    };
    const modal = await this.modalCtrl.create({
      component: EditorGeoComponent,
      componentProps: {
        isModal: true,
        geoJson: cVal,
        opc
      }
    });
    await modal.present();
    const res = await modal.onDidDismiss();
    if (res.data) {
      this.formGroup.get(frmInpt.property).patchValue(res.data);
    }
  }

  private async handleErrors(err) {
    console.error(err);
    if (err instanceof HttpErrorResponse && err.status === 400 && err.statusText === 'Bad Request') {
      try {
        const errorTxt = this.appendErrors(err.error);
        await this.uiServ.error(errorTxt);
      } catch (err) {
        await this.uiServ.error(err);
      }
    } else {
      await this.uiServ.error(err);
    }
  }

  private appendErrors(errObj: any): string {
    let ret = '';
    for (const key in errObj) {
      if (!errObj.hasOwnProperty(key)) { continue; }
      const val = errObj[key];
      if (isArray(val)) {
        for (const errorText of val as string[]) {
          ret += '- ' + errorText + '<br>';
        }
      } else if (typeof val === 'object') {
        ret += this.appendErrors(val);
      } else {
        console.error('Error no detectado');
      }
    }
    return ret;
  }
}
