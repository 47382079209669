// tslint:disable: max-line-length
import { Component, OnInit } from '@angular/core';
import { Producto, CompraARealizarConcepto } from 'src/app/models/comunes';
import { ModalController, NavController } from '@ionic/angular';
import { WebapiService } from 'src/app/servicios/webapi.service';
import { UiService } from 'src/app/servicios/ui.service';
import { InAppBrowser, InAppBrowserObject, InAppBrowserEvent } from '@ionic-native/in-app-browser/ngx';
import { ConfigService } from 'src/app/servicios/config.service';
import { TiposDePagos, CompraJSONPeticion, Carrito, CompraJSONPeticionConcepto, CarritoConcepto, TiposDeCarrito } from 'src/app/models/compras';
import { FileService } from 'src/app/servicios/file.service';

@Component({
  selector: 'app-realizar-compra',
  templateUrl: './realizar-compra.component.html',
  styleUrls: ['./realizar-compra.component.scss'],
})
export class RealizarCompraComponent implements OnInit {
  cargando = true;
  facturar: boolean;
  rfc: string;
  razonSocial: string;
  metodoDePago: any;
  total: any;
  esPedido = false;
  activeBrowser: InAppBrowserObject;
  mostrarAdvertencia5min = true;
  tiposDePago: TiposDePagos[] = [];
  tipoDePagoSeleccionado: TiposDePagos;
  carrito: Carrito;

  constructor(
    public modalCtrl: ModalController,
    public uiServ: UiService,
    public inAppBrwsrCtrl: InAppBrowser,
    public configServ: ConfigService,
    public fileServ: FileService,
    public navCtrl: NavController,
    public webapiServ: WebapiService) { }

  async ngOnInit() {
    this.cargando = true;
    try {
      this.tiposDePago = await this.webapiServ.getObjects('tipos_de_pago/', TiposDePagos);
    } catch (err) {
      this.uiServ.error(err);
    }
    this.cargando = false;
  }

  async cancel() {
    await this.modalCtrl.dismiss(null, 'cancel');
  }

  cambiarTipoDePago(tipo: TiposDePagos) {
    this.tipoDePagoSeleccionado = tipo;
  }

  async verProducto(prod: CarritoConcepto) {
    await this.modalCtrl.dismiss(null, 'cancel');
    await this.navCtrl.navigateForward('tienda/producto/' + prod.producto.id + '/');
  }

  async comprar() {
    if (!this.tipoDePagoSeleccionado) {
      await this.uiServ.alert('Por favor seleccione el tipo de pago');
      return;
    }

    if (this.tipoDePagoSeleccionado.clave === 'TAROP') {
      if (!(await this.uiServ.pregunta('Recuerde que tiene un lapso de cinco minutos para completar su transacción.'))) {
        // this.modalCtrl.dismiss(null, 'cancel');
        return;
      }
    }

    const jif = await this.uiServ.cargando();
    try {

      // # Create purchase petition
      const objToSend: CompraJSONPeticion = {
        conceptos: this.carrito.conceptosJSON(),
        tipo_pago: this.tipoDePagoSeleccionado.clave,
        pedido: this.carrito.tipo === TiposDeCarrito.Pedidos,
        carrito: this.carrito.id
      };

      // # Create purchase
      const params = { articulos: objToSend };
      const result = await this.webapiServ.post('compras/crear_compra/', params);
      console.log(result);

      // # Pedidos redirije
      if (this.tipoDePagoSeleccionado.clave === 'PED') {
        await jif.dismiss();
        await this.uiServ.ok('Pedido registrado con exito!');
        this.modalCtrl.dismiss(null, 'ok');
        return;
      }

      // # Download payment pdf
      if (this.tipoDePagoSeleccionado.clave === 'PAYOP') {
        await jif.dismiss();
        await this.uiServ.abreLink(result.url);
        // await this.uiServ.ok('Pedido registrado con exito!');
        this.modalCtrl.dismiss(null, 'ok');
        return;
      }

      // # Open payment service window
      const target = '_blank';
      this.activeBrowser = this.inAppBrwsrCtrl.create(result.url, target, { location: 'yes', zoom: 'no', toolbarcolor: '#007d38', toolbarposition: 'top', closebuttoncaption: 'Cerrar', closebuttoncolor: '#ffffff' });
      console.log(this.activeBrowser);

      await this.uiServ.wait(1000);

      // # If its a native platform, wait for response of external window
      if (this.configServ.esCordova) {

        const browserResult = await this.WaitForBrowserResponse();
        await jif.dismiss();
        if (browserResult) {
          // await this.uiServ.ok('Puede dar seguimiento al estado de su compra desde el apartado de compras del menú.', 'Gracias por su compra');
          this.modalCtrl.dismiss(null, 'ok');
        } else {
          this.modalCtrl.dismiss(null, 'cancel');
        }
      } else {
        await jif.dismiss();
        await this.uiServ.ok('Puede ver el estado e información de su compra en el apartado de Mis Compras');
        await this.modalCtrl.dismiss(null, 'ok');
        await this.navCtrl.navigateForward('tienda/mis-compras/');
      }
    } catch (err) {
      await jif.dismiss();
      this.uiServ.error(err);
      this.modalCtrl.dismiss(null, 'error');
    }
  }

  private async WaitForBrowserResponse(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.activeBrowser.on('loadstart').subscribe((e: InAppBrowserEvent) => {
        if (e && e.url) {
          console.log(this.activeBrowser, ' loaded ', e.url);
          if (e.url.includes('expoceres.com.mx')) {
            resolve(true);
            this.activeBrowser.close(); // not reached?
          }
        }
      });
      this.activeBrowser.on('exit').subscribe((e: InAppBrowserEvent) => {
        console.log('exit: ' + e); // this is reached
        resolve(false);
      });
    });
  }

}
/*
Sends:

{
  "conceptos": [
    {
      "producto": 1,
      "cantidad": 1,
      "boleto": 2994
    },
    {
      "producto": 1,
      "cantidad": 1,
      "boleto": 3029
    },
    {
      "producto": 2,
      "cantidad": 1,
      "boleto": 3336
    }
  ]
}
*/

/*
Response:

{
  "description": "Expoceres Conferencia Magistral",
  "transaction_type": "charge",
  "currency": "MXN",
  "order_id": "17",
  "payment_method": {
    "type": "redirect",
    "url": "https://sandbox-api.openpay.mx/v1/m4pzimqbnxxy7smse3ic/charges/trj8wmb4rqgxhngm5slj/card_capture"
  },
  "amount": 1500.0,
  "id": "trj8wmb4rqgxhngm5slj",
  "status": "charge_pending",
  "error_message": null,
  "operation_date": "2020-01-28T10:53:39-06:00",
  "creation_date": "2020-01-28T10:53:39-06:00",
  "customer_id": "abanevzmbultlqwabitg",
  "conciliated": false,
  "authorization": null,
  "operation_type": "in",
  "object": "charge",
  "method": "card"
}

*/