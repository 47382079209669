import { Injectable } from '@angular/core';
import { config } from 'rxjs';
import { EncuentroDeNegocios } from '../models/expositores';
import { Usuario } from '../models/usuario';
import { ConfigService } from './config.service';
import { EventosService } from './eventos.service';
import { UiService } from './ui.service';
import { WebapiService } from './webapi.service';

@Injectable({
  providedIn: 'root'
})
export class BadgeService {
  refreshContactRequestsInterval: number;

  constructor(
    private uiServ: UiService,
    private configServ: ConfigService,
    private eventosServ: EventosService,
    private webapiServ: WebapiService) {

    this.eventosServ.onUserLoaded.subscribe((usr: Usuario) => {
      // console.log('LOGEDIN', usr);
      if (configServ.esExpositor || configServ.isStaff) {
        this.refreshContactRequestsInterval = window.setInterval(async () => {
          await this.refreshContactRequests();
        }, 1000 * 60 * 2);
        this.refreshContactRequests().then().catch();
      }
    });

    this.eventosServ.onUserLogout.subscribe((usr) => {
      // console.log('LOGEDOUT');
      clearInterval(this.refreshContactRequestsInterval);
    });

  }

  async refreshContactRequests() {
    try {
      let pending = (await EncuentroDeNegocios.Repo.get()) as EncuentroDeNegocios[];
      pending = pending.filter(iter => !iter.atendido);
      if (this.configServ.isStaff) {
        this.setBadgeValue('staff-expositores', 'ContactarExpositor', pending.length);
      } else {
        this.setBadgeValue('expositor_datos_generales', 'ContactarExpositor', pending.length);
      }
    } catch (err) {
      console.error(err);
    }
  }

  private setBadgeValue(menu: string, opc: string, val: any) {
    // console.log(menu, opc, val);
    const menuOpc = this.uiServ.getMenuOption(menu, opc);
    if (!menuOpc) {
      console.error(menuOpc);
      return;
    }
    if (val) {
      menuOpc.badge = true;
      menuOpc.badgeContent = val;
    } else {
      menuOpc.badge = false;
    }
  }
}
