import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as L from 'leaflet';
import { ConfigService } from 'src/app/servicios/config.service';
import { UiService } from 'src/app/servicios/ui.service';
import * as GJSON from 'geojson';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

@Component({
  selector: 'app-editor-geo',
  templateUrl: './editor-geo.component.html',
  styleUrls: ['./editor-geo.component.scss'],
})
export class EditorGeoComponent implements OnInit {
  @ViewChild('leafletmap', { static: false }) leafletmap: any;
  @Input() geoJson: string;
  @Input() geoJsons: string[];
  @Input() readonly: boolean;
  map: L.Map;
  geoLayer: L.GeoJSON;


  constructor(
    public uiServ: UiService,
    private configServ: ConfigService,
    private modalCtrl: ModalController) {
    L.Icon.Default.imagePath = 'assets/maps/icons/';
  }

  async ngOnInit() {
    await this.uiServ.wait(100);
    await this.render();
  }

  async render() {

    const edicion = await this.configServ.edicionAsync.getValue();
    console.log(edicion);

    const mapOpts: any = {
      crs: L.CRS.Simple,
      minZoom: -5,
      attributionControl: false,
      editable: true
    };
    // this.map = L.map(this.leafletmap.nativeElement, mapOpts); // .setView([this.center.lat, this.center.lng], 9);
    this.map = L.map(this.leafletmap.nativeElement, mapOpts);
    var bounds: any = [[0, 0], [1000, 1000]];
    var image = L.imageOverlay(edicion.imagen_mapas1, bounds).addTo(this.map);
    this.map.fitBounds(bounds);
    this.map.setView([400, 400], 2);

    if (this.geoLayer) {
      this.geoLayer.removeFrom(this.map);
    }

    // Load values
    try {
      if (this.geoJson) {
        this.geoLayer = L.geoJSON(JSON.parse(this.geoJson)).addTo(this.map);
        //this.map.invalidateSize();
        // await this.uiServ.wait(1000);
        // this.map.fitBounds(this.geoLayer.getBounds());
        // this.map.zoomOut();
      }
      if (this.geoJsons) {
        for (const json of this.geoJsons) {
          if (!json || json == '') {
            continue;
          }
          L.geoJSON(JSON.parse(json)).addTo(this.map);
        }
      }
    } catch (err) {
      console.error(err);
    }

    if (!this.readonly) {
      this.map.pm.addControls({
        position: 'topleft',
        drawCircle: false,
        drawPolyline: false,
      });
    }
    this.map.pm.setLang('es');

    await this.uiServ.wait(1000);
    //this.createMapLayers(this.map);
    await this.uiServ.wait(1000);
    // this.map.invalidateSize();
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async save() {
    const json = this.getGeoJson();
    await this.modalCtrl.dismiss(JSON.stringify(json), 'OK');
  }

  getGeoJson(): GJSON.GeoJSON {
    const fg = L.featureGroup();
    this.map.eachLayer((layer) => {
      console.log(layer);
      if ((layer instanceof L.Path || layer instanceof L.Marker) && layer.pm) {
        fg.addLayer(layer);
      }
    });
    const gj = fg.toGeoJSON();
    return gj;
  }
}
