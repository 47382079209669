// tslint:disable: member-ordering
import { EditorForm } from './editor';
import { Dato, Repositorio } from './dato';

// tslint:disable: variable-name
// tslint:disable: no-inferrable-types

/** En este archivo esta los modelos que se utilizan cuando el app esta en modo de visitante */

// ========================================================================== //
export class Visitante {
  // No editables:
  id: number;
  usuario: any;
  email: string;

  first_name: string;
  last_name: string;
  username: string;
  codigo_qr: string;
  direccion: string;

  // Solo se utilizan durante el registro:
  password: string;
  registro_en_app: boolean = true;
  fecha_registro: Date = new Date();

  // Productor
  cultivo: string = null;
  // hectareas: number = null;
  hectareas_1: number = null;
  cultivo_2: string = null;
  hectareas_2: number = null;
  cultivo_3: string = null;
  hectareas_3: number = null;

  // Ganadero
  ganado = null;
  ganado_list: number = null;

  // Empresa o Profesionista
  giro: string = null;
  empresa: string = null;
  carrera: string = null;
  genero: number = null;
  telefono: string = null;
  //edad: number = null;
  nacimiento: number = null;
  pais = 'México';
  estado: string = null;
  ciudad: string = null;
  perfil_visitante: number = null;
  facebook: boolean = false;
  instagram: boolean = false;
  whatsapp: boolean = false;
  twitter: boolean = false;
  youtube: boolean = false;
}

// ========================================================================== //
export class DatosDeQRdeEntrada {
  id: number;
  edicion: number;
  usuarioID: number;
  email: string;
}

// ========================================================================== //
export class PerfilVisitante {
  id: number;
  nombre: string;
  mostrar: boolean;
}

// ========================================================================== //
export class Visita {
  id: number;
  fecha: string;
  folio_visita: number;
  acompañantes: number;
  observaciones = 'Registrado con la APP';
  usuario: number;
  edicion: number;
}

// ========================================================================== //
export class Ocupacion {
  id: number;
  nombre: string;
  mostrar: boolean;
}

// ========================================================================== //
export class Tema {
  id: number;
  nombre: string;
  mostrar: boolean;
}

// ========================================================================== //
export class Cultivo extends Dato {
  id: number;
  nombre: string;
  mostrar: boolean;

  public static Editor: EditorForm = {
    newObjectTitle: 'Nuevo cultivo',
    limitText: '',
    inputs: [
      { titulo: 'Nombre', property: 'nombre', type: 'text', max: 50, required: true },
      { titulo: 'Mostrar', property: 'mostrar', type: 'boolean', required: true },
    ]
  };

  public static Repo: Repositorio = new Repositorio('Cultivo', 'cultivos/', Cultivo.Editor, Cultivo);
}

// ========================================================================== //
export class Conferencia extends Dato { // Evento
  nombre: string;
  resumen: string;
  conferencista: number;
  fecha_y_hora: string;
  capacidad: number;
  ubicacion: string;
  imagen: string;
  presentacion: string;
  tema: any;
  conferencia: boolean;
  magistral: boolean;
  inscritos: number;
  edicion: number;
  link_externo: string;
  tipo_evento: number;
  id_youtube: string;

  _reservado = false;
  _confirmado = false;
  _inscripcion: any;

  toString(): string { return this.nombre; }

  public static Editor: EditorForm = {
    newObjectTitle: 'Nueva conferencia',
    limitText: '',
    inputs: [
      { titulo: 'Nombre', property: 'nombre', type: 'text', max: 100, required: true },
      { titulo: 'Resumen', property: 'resumen', type: 'texteditor', max: 800, required: false },
      { titulo: 'Conferencista', property: 'conferencista', type: 'text', max: 50, required: true, },
      { titulo: 'Fecha y hora', property: 'fecha_y_hora', type: 'datetime', },
      { titulo: 'Ubicación', property: 'ubicacion', type: 'text', max: 30, required: true, },
      { titulo: 'Capacidad', property: 'capacidad', type: 'number', required: true, },
      { titulo: 'Imagen', property: 'imagen', type: 'image', max: 500000, required: false, },
      { titulo: 'Presentación', property: 'presentacion', type: 'file', max: 40000000, required: false, },
      { titulo: 'Es conferencia', property: 'conferencia', type: 'bool', required: false, },
      { titulo: 'Es magistral', property: 'magistral', type: 'bool', required: false, },
      { titulo: 'Enlace externo', property: 'link_externo', type: 'url' },
      {
        titulo: 'Tipo de evento', property: 'tipo_evento', type: 'select',
        options: [
          { id: 1, nombre: 'General' },
          { id: 2, nombre: 'Conferencia' },
          { id: 3, nombre: 'Visita de campo' },
          { id: 4, nombre: 'Demostrativo' }
        ]
      },
      { titulo: 'ID video youtube (texto despues de "v=" en URL de video)', property: 'id_youtube', type: 'text', max: 200, required: false },
      { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
    ]
  };
  public static Repo: Repositorio = new Repositorio('Conferencia', 'conferencias/', Conferencia.Editor, Conferencia);
}

// ========================================================================== //
export class InscripcionConferencia {
  id: number;
  usuario: number;
  asistio: boolean;
  conferencia: number;
  confirmado: boolean;
  codigo: number;

  public static Repo: Repositorio = new Repositorio('InscripcionConferencia', 'inscripciones/', null, InscripcionConferencia);
}

// ========================================================================== //
export class AsistenciaConferencia {
  id: number;
  usuario: number;
  nombre: string;
  conferencia: number;
  reservada: boolean;
  _pendiente = false;
  codigo_pulsera: number;

  public static Repo: Repositorio = new Repositorio('AsistenciaConferencia', '/', null, AsistenciaConferencia);
}

// ========================================================================== //
export class Grupo {
  id: number;
  nombre: string;
  giro: number;
  institucion: string;
  responsable: number;
}

// ========================================================================== //
export class Cupon {
  id: number;
  titulo: string;
  imagen: string;

  public static Editor: EditorForm = {
    newObjectTitle: 'Nuevo cupon',
    limitText: '',
    inputs: [
      // TODO
    ]
  };

  public static Repo: Repositorio = new Repositorio('AsistenciaConferencia', '/', Cupon.Editor, Cupon);
}
