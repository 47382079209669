// tslint:disable: max-line-length
// tslint:disable: align

import { Injectable, EventEmitter } from '@angular/core';
import { Usuario } from '../models/usuario';
import { LoadingController, AlertController, MenuController, NavController } from '@ionic/angular';
import { Configs } from '../data/opcionesDeMenu';
import { WebapiService } from './webapi.service';
import { ExpoConfigMenu, ExpoConfig, ExpoConfigMenuOpcion } from '../models/menu';
import { HttpClient } from '@angular/common/http';
import { EncuentroDeNegocios, Expositor, ExpositorRepo, ExpositorUsuario, Participacion } from '../models/expositores';
import { Repositorio } from '../models/dato';
import { EdicionExpoceres } from '../models/comunes';
import { AsyncValue } from './tools';
import { version as appVersion } from '../../../package.json';
import { UiService } from './ui.service';
import { CachedWebapiService } from './cached-webapi.service';
import { EventosService } from './eventos.service';
import { environment } from 'src/environments/environment';
import { EventsService } from './events.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public static PantallaChicaRes = 750;
  public static ExpositorID = -2;
  config: ExpoConfig;
  menu: ExpoConfigMenu[];
  usuario: Usuario = null;
  // Tipo de perfil: visitante, expositor
  grupo: string;
  perfilTipo: string;
  perfilNombre: string;
  // Bandera que indica si el perfil ya se cargó
  perfilCargado = false;
  muestraPanel = true;
  pantallaChica = false;
  pantallaWidth: number;
  ultimoPerfil = '';
  edicionId = 1;
  edicionActual: EdicionExpoceres;
  isStaff = false;
  isStaffExpositor = false;
  isStaffVisitante = false;
  isStaffEntradas = false;
  noEsStaff = true;
  esCordova: boolean;
  cordova: any;
  esExpositor: boolean;
  esVisitante: boolean;
  staffExpositorEditando: Expositor;
  expositorBloqueado = false;
  participacionSinConfirmar = false;
  staffExpositorEditandoAsync: AsyncValue<Expositor> = new AsyncValue();
  usuarioAsync: AsyncValue<Usuario> = new AsyncValue();
  edicionAsync: AsyncValue<EdicionExpoceres> = new AsyncValue();
  edicionActualYear = '2020';
  version: string;
  onCodigoQrCambiado = new EventEmitter<string>();
  onScreenResized = new EventEmitter<any>();
  /** Funcion que se llamara cuando se actulice el usuario */
  // usuarioActualizadoCallback: () => void;

  constructor(
    public http: HttpClient,
    public events: EventsService,
    private eventsServ: EventosService,
    private loadingCtrl: LoadingController,
    private menuCtrl: MenuController,
    private navCtrl: NavController,
    private cachedWebapiServ: CachedWebapiService,
    private alertCtrl: AlertController,
    private translateServ: TranslateService,
    private webapi: WebapiService) {
    this.version = appVersion;
    if (!environment.production) {
      this.version += ' D';
    }
    console.log('Expoceres App ', this.version);

    window.onresize = () => {
      this.onScreenResized.emit();
      this.detectarPantalla();
    };
    this.detectarPantalla();

    this.config = Configs;
    this.ultimoPerfil = localStorage.getItem('ultimoPerfil');
    this.esCordova = (this.cordova = window['cordova']) !== undefined;
    this.perfilTipo = localStorage.getItem('tipo');
    console.log('Tipo de perfil:', this.perfilTipo);
    this.events.subscribe('ConfigService.editarExpositor', (data) => { this.editarExpositor(data as Expositor); });


    const savedExpo = JSON.parse(localStorage.getItem('staffExpositor'));
    if (savedExpo && savedExpo.id) {
      const expoInst = Object.assign(new Expositor(), savedExpo);
      this.editarExpositor(expoInst);
    }
  }

  borrarDatosDeUsuario() {
    this.usuario = undefined;
    this.isStaff = false;
    this.esExpositor = false;
    this.grupo = this.perfilTipo = undefined;
    localStorage.removeItem('staffExpositor');
    localStorage.removeItem('expo-conferencia-magistral-carrito');
  }

  async cargarDatosDeUsuario(acabaDeIniciarSesion?: boolean): Promise<any> {
    const alrt = await this.loadingCtrl.create({ message: await this.translateServ.get('INICIO.CARGANDO_PERFIL').toPromise() });
    await alrt.present();
    try {
      const usrs = await this.cachedWebapiServ.get('usuarios/', Usuario);
      if (!usrs.length) {
        throw new Error(await this.translateServ.get('INICIO.ERROR_PERFIL').toPromise());
      }
      this.usuario = usrs[0];
      // # Cargar datos principales del usuario
      this.isStaff = this.usuario.is_staff;
      this.noEsStaff = !this.isStaff;

      // # Cargar perfil
      if (!this.usuario.groups) {
        throw new Error('No tiene asignado un grupo!');
      }
      const grupo = this.usuario.groups[0].name; // .replace('_', '-');
      this.perfilNombre = this.usuario.username;
      this.esExpositor = grupo === 'expositor';
      this.esVisitante = grupo === 'visitante';
      this.isStaff = grupo.startsWith('staff');
      this.isStaffEntradas = grupo === 'staff_visitante_entradas';
      this.isStaffVisitante = grupo === 'staff_visitante';
      this.isStaffExpositor = grupo === 'staff_expositor';
      this.grupo = this.perfilTipo = grupo;
      this.ultimoPerfil = grupo;
      localStorage.setItem('ultimoPerfil', grupo);
      this.perfilCargado = true;

      // # Cargar datos de expositor si existen en el usuario
      if (this.usuario.perfil && this.usuario.perfil.expositor) {
        ConfigService.ExpositorID = this.usuario.perfil.expositor.id;
        this.perfilNombre = this.usuario.perfil.expositor.nombre;
        this.buscarGrupo('expoceres_2').titulo = this.usuario.perfil.expositor.nombre;
        this.deshabilitarOpcionesNoDisponiblesDeExpositor();
      }

      // # Actualizar edicion
      this.actualizarEdicion();

      // # Notificar codigo que espera al usuario
      // if (this.usuarioActualizadoCallback) { this.usuarioActualizadoCallback(); }
      this.usuarioAsync.setValue(this.usuario);
      console.log('Perfil establecido a', this.perfilTipo, 'con nombre de', this.perfilNombre);
      if (acabaDeIniciarSesion) {
        this.eventsServ.onUserLogin.emit(this.usuario);
      }
      this.eventsServ.onUserLoaded.emit(this.usuario);

      await alrt.dismiss();

    } catch (err) {
      console.log(err);
      await alrt.dismiss();
      this.usuario = null;
      throw err;
    }
  }

  buscarOpcion(id: any): ExpoConfigMenuOpcion {
    for (const opcMenu of this.config.menus) {
      for (const opc of opcMenu.opciones) {
        if (opc.id === id) { return opc; }
      }
    }
    return null;
  }

  buscarGrupo(id: any): ExpoConfigMenu {
    for (const opcMenu of this.config.menus) {
      if (opcMenu.id === id) { return opcMenu; }
    }
    return null;
  }


  /** Actualiza la propiedad edicion usando el la edición más reciente del backend */
  actualizarEdicion() {
    const edicionUrl = 'edicionexpoceres/actual/';
    this.webapi.get(edicionUrl, {})
      .then(async (edicion) => {
        this.edicionActual = edicion;
        this.edicionId = edicion.id;
        this.edicionAsync.setValue(this.edicionActual);
        console.log('Bienvenido a la expo', this.edicionActual.edicion, '(ID:' + this.edicionId + ')');
        this.edicionActualYear = this.edicionActual.edicion.toString();
        await this.verificaConfirmacionAExpoActual();
        if (this.isStaff) {
          const opc = this.buscarOpcion('expositores');
          opc.editorActions[0].title = this.edicionActual.edicion.toString();
        }
      })
      .catch((err) => {
        this.edicionId = 2;
        this.edicionActual = {
          id: 2,
          edicion: 2020,
          fecha_inicio: '2020-03-26',
          fecha_fin: '2020-03-28',
        } as EdicionExpoceres;
        console.error(err);
      });
  }

  editarExpositor(expstr: Expositor) {
    console.log(expstr);
    if (expstr) {
      localStorage.setItem('staffExpositor', JSON.stringify(expstr));
      const grp = this.buscarGrupo('editar-expositor');
      grp.titulo = expstr.toString();
      grp.disabled = false;
      // this.menuCtrl.toggle('menu_lateral');
      this.staffExpositorEditando = expstr;
      ConfigService.ExpositorID = expstr.id;
      Repositorio.overridingEndpoint = (original: string, repo: Repositorio) => this.overrideRepoEndpoints(expstr, original, repo);
      // this.buscarOpcion('staff-expositor-datosGenerales').repositorio.getEndpoint = 'expositores/' + expstr.id + '/';
      // Participacion.ParticipacionRepo.getEndpoint = 'participaciones/' + expstr.id;
      // Expositor.ExpositoresEditorRepo.getEndpoint = 'expositores/' + expstr.id;
      // this.buscarOpcion('staff-expositor-participacion').repositorio.getEndpoint = 'participaciones/' + expstr.id;
      this.staffExpositorEditandoAsync.setValue(expstr);
    } else {
      localStorage.removeItem('staffExpositor');
      const grp = this.buscarGrupo('editar-expositor');
      grp.disabled = true;
      this.staffExpositorEditando = expstr;
      Repositorio.overridingEndpoint = null;
      // Repositorio.overridingEndpoint = undefined;
      ConfigService.ExpositorID = -2;
      // Expositor.ExpositoresEditorRepo.getEndpoint = 'expositores/';
      // Participacion.ParticipacionRepo.getEndpoint = 'participaciones/';
      // this.buscarOpcion('staff-expositor-datosGenerales').repositorio.getEndpoint = 'expositores/';
      // this.buscarOpcion('staff-expositor-participacion').repositorio.getEndpoint = 'participaciones/';
    }
  }

  private overrideRepoEndpoints(expositor: Expositor, original: string, repo: Repositorio) {
    if (repo === ExpositorRepo && !original.endsWith(expositor.id + '/')) {
      return original + expositor.id + '/';
    }
    if (original.indexOf('?') >= 0) {
      return original + '&expositor=' + expositor.id;
    } else {
      return original + '?expositor=' + expositor.id;
    }
  }

  private detectarPantalla() {
    this.pantallaWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // console.log(this.pantallaWidth);
    const esPantallaChica = this.pantallaWidth < ConfigService.PantallaChicaRes;
    const oldValue = this.pantallaChica;
    this.pantallaChica = esPantallaChica;
    // this.events.publish("pantallaCambioWidth", this.pantallaWidth);
    if (esPantallaChica && !oldValue) {
      this.events.publish('pantallaChica', true);
    } else if (!esPantallaChica && oldValue) { this.events.publish('pantallaChica', false); }

  }

  private async verificaConfirmacionAExpoActual() {
    if (!this.edicionActual || !this.edicionActual.edicion || this.isStaff) { return; }
    if (!this.usuario.tieneParticipacion()) {
      const alert = await this.alertCtrl.create({
        header: await this.translateServ.get('INICIO.BIENVENIDO').toPromise(),
        message: await this.translateServ.get('INICIO.CONFIRMAR_ASISTENCIA').toPromise() + this.edicionActual.edicion + ',' + await this.translateServ.get('INICIO.CONFIRMAR_ASISTENCIA_PREGUNTA').toPromise(),
        buttons: [{ text: await this.translateServ.get('INICIO.SOLICITAR').toPromise(), role: 'ok' }, { text: await this.translateServ.get('INICIO.DESPUES').toPromise(), role: 'cancel' }]
      });
      await alert.present();
      const result = await alert.onDidDismiss();
      if (!result || result.role !== 'ok') {
        return;
      }
      const gif = await this.loadingCtrl.create({ message: await this.translateServ.get('INICIO.SOLICITUD_CONFIRMAR').toPromise() });
      await gif.present();
      try {
        const res = await this.webapi.get('usuarios/' + this.usuario.id + '/participacion_actual/');
        console.log(res);
        gif.dismiss();
        if (res.codigo_qr) {
          this.usuario.participacion_actual = { codigo_qr: res.codigo_qr };
          // this.tieneCodigo = true;
          const dlg = await this.alertCtrl.create({
            header: await this.translateServ.get('CORRECTO').toPromise(),
            message: '✔' + this.translateServ.instant('INICIO.ASISTENCIA_CONFIRMADA'),
          });
          await dlg.present();
          this.onCodigoQrCambiado.emit(res.codigo_qr);
        } else {
          const dlg = await this.alertCtrl.create({
            header: '❌ Error',
            message: await this.translateServ.get('INICIO.PETICION_NO_PROCESADA').toPromise(),
          });
          await dlg.present();
        }
        // await this.configServ.cargarDatosDeUsuario();
      } catch (err) {
        console.error(err);
        gif.dismiss();
        const dlg = await this.alertCtrl.create({
          header: '❌ Error',
          message: await this.translateServ.get('INICIO.PETICION_NO_PROCESADA').toPromise(),
        });
        await dlg.present();
      }
    }
  }

  obtenNombreDeUsuario() {
    if (this.usuario) {
      return this.usuario.obtenNombreDeUsuario();
    } else {
      return '';
    }
  }

  private deshabilitarOpcionesNoDisponiblesDeExpositor() {
    return; // No deshabiltar opciones basadas en contrato
    this.expositorBloqueado = !this.usuario.obtenerParticipacionDeExpositor();
    this.participacionSinConfirmar = !this.usuario.obtenerParticipacionNoConfirmada();
    // console.log(this.participacionSinConfirmar);
    const opciones = ['Participacion'];//, 'DatosFiscales', 'Invitacion', 'Gafete', 'Contacto', 'Direccion', 'proveedores', 'materialoficial']; // 'DirectorioOficial',
    const opcionesParticipacion = [];//'DirectorioOficial'];
    for (const opcName of opciones) {
      const menuOpc = this.buscarOpcion(opcName);
      if (!menuOpc) { continue; }
      menuOpc.disabled = this.expositorBloqueado;
    }
    for (const opcName of opcionesParticipacion) {
      const menuOpc = this.buscarOpcion(opcName);
      if (!menuOpc) { continue; }
      menuOpc.disabled = this.participacionSinConfirmar;
    }
    if (this.expositorBloqueado) {
      this.navCtrl.navigateRoot('contrato');
    }
  }

  async obtenerIDExpositorActivo() {
    const usr = await this.usuarioAsync.getValue();
    let id = 0;
    if (this.isStaff) {
      id = this.staffExpositorEditando.id;
    } else {
      id = usr.perfil.expositor.id; // ConfigService.ExpositorID;
    }
    return id;
  }
}
