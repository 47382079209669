import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { ExpositorUsuario, EncuentroDeNegocios } from '../models/expositores';
import { EventosService } from './eventos.service';
import { EventsService } from './events.service';
import { UiService } from './ui.service';
import { WebapiService } from './webapi.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalFuncsService {

  constructor(
    private events: EventsService,
    private uiServ: UiService,
    private webapi: WebapiService,
    private loadingCtrl: LoadingController,
    private ui: UiService,
    private alertCtrl: AlertController) {
    this.events.subscribe('GlobalFuncsService.activarUsuarioExpositor', (data) => {
      this.activarUsuarioExpositor(data as ExpositorUsuario)
        .then(() => { });
    });
    this.events.subscribe('GlobalFuncsService.desactivarUsuarioExpositor', (data) => {
      this.desactivarUsuarioExpositor(data as ExpositorUsuario)
        .then(() => { });
    });
    this.events.subscribe('GlobalFuncsService.marcarAtendidoEncuentro', (data) => {
      this.marcarEncuentroComoAtendido(data as EncuentroDeNegocios)
        .then(() => { });
    });
  }
  private async activarUsuarioExpositor(dato: ExpositorUsuario) {
    dato.activo = true;
    const gif = await this.ui.cargando('Espere por favor...');
    try {
      const url = ExpositorUsuario.Repo.endpoint + dato.id + '/habilitar/';
      await this.webapi.get(url, dato);
      await gif.dismiss();
      await this.ui.ok('Usuario activado correctamente');
    } catch (err) {
      await gif.dismiss();
      await this.ui.error(err);
      dato.activo = false;
    }
  }

  private async desactivarUsuarioExpositor(dato: ExpositorUsuario) {
    dato.activo = false;
    const gif = await this.ui.cargando('Espere por favor...');
    try {
      const url = ExpositorUsuario.Repo.endpoint + dato.id + '/deshabilitar/';
      await this.webapi.get(url, dato);
      await gif.dismiss();
      await this.ui.ok('Usuario desactivado correctamente');
    } catch (err) {
      await gif.dismiss();
      await this.ui.error(err);
      dato.activo = true;
    }
  }

  private async marcarEncuentroComoAtendido(dato: EncuentroDeNegocios) {
    if (await this.uiServ.pregunta('¿Marcar encuentro de negocios como atendido?', 'Sí', 'No')) {
      const gif = await this.ui.cargando('Espere por favor...');
      try {
        // https://pruebas.expoceres.com.mx/api/encuentros_de_negocio/11/confirmar_atendido/
        dato.atendido = true;
        const url = EncuentroDeNegocios.Repo.endpoint + dato.id + '/confirmar_atendido/';
        await this.webapi.get(url);
        await gif.dismiss();
        await this.ui.ok('Encuentro marcado como atendido');
      } catch (err) {
        await gif.dismiss();
        dato.atendido = false;
        await this.ui.error(err);
      }
    }
  }
}
