import { WebapiService, RequestType } from '../servicios/webapi.service';
import { EditorForm } from './editor';
import { environment } from 'src/environments/environment';

export class Dato {
  public id: number;
  public toString(): string { return '<ToString() sin implementar>'; }
  public compareTo?: (term: string, dato: Dato) => boolean;
}

export class Repositorio {
  static overridingEndpoint: (originalEndpoint: string, repo: Repositorio) => string;
  public objetos: Dato[] = [];
  public updated = false;
  public isUpdating = false;
  public getEndpoint: string;
  private onChangeHandlers: () => void[];
  private onFetchedHandlers: () => void[];
  customEndpoint: string;
  customHTTPMethod: RequestType;

  constructor(public name: string, public endpoint: string, public editor: EditorForm, public ctr: new () => Dato) {
  }

  create(): Dato { return new this.ctr(); }

  async save(dato: Dato, formData: FormData, preferPatch?: boolean): Promise<Dato> {
    let endpoint = this.endpoint;
    if (dato.id) {
      endpoint += dato.id + '/';
    }
    if (Repositorio.overridingEndpoint) {
      endpoint = Repositorio.overridingEndpoint(endpoint, this);
    }
    if (this.customEndpoint) {
      endpoint = this.customEndpoint;
    }
    let toSave: any = formData;
    if (!toSave) { toSave = dato; }
    let ret: any;
    if (this.customHTTPMethod) {
      if (dato.id && formData === toSave) {
        formData.set('id', dato.id.toString());
      }
      ret = await WebapiService.Singleton.doRequest(this.customHTTPMethod, endpoint, toSave, undefined);

    } else if (dato.id) {
      if (formData === toSave) {
        formData.set('id', dato.id.toString());
      }
      // # Modify
      if (preferPatch) {
        ret = await WebapiService.Singleton.patch(endpoint, toSave);
      } else {
        ret = await WebapiService.Singleton.put(endpoint, toSave);
      }
    } else {
      // # Create new
      ret = await WebapiService.Singleton.post(endpoint, toSave);
    }
    console.log('RETURNED:', ret);
    if (ret) {
      let detObj = this.findByField('id', dato.id);
      if (!detObj) {
        detObj = new this.ctr();
        this.objetos.push(detObj);
      }
      Object.assign(detObj, ret);
      return detObj;
    }
    return dato;
  }

  async get(): Promise<Dato[]> {
    let endpoint = this.getEndpoint ? this.getEndpoint : this.endpoint;
    if (Repositorio.overridingEndpoint) {
      if (!environment.production) {
        // console.log('overriding endpoint for ' + this.name);
      }
      endpoint = Repositorio.overridingEndpoint(endpoint, this);
    }
    try {
      const ret = await WebapiService.Singleton.get(endpoint);
      // this.objetos = [];
      this.objetos.length = 0;
      if (ret) {
        if (this.isArray(ret)) {
          for (const retObj of ret) {
            const instance = Object.assign(new this.ctr(), retObj);
            this.objetos.push(instance);
          }
        } else {
          const instance = Object.assign(new this.ctr(), ret);
          this.objetos.push(instance);
        }
      }
      return this.objetos;
    } catch (err) {
      console.error(err);
      return this.objetos;
    }
  }

  async delete(dato: Dato): Promise<Dato> {
    let endpoint = this.endpoint;
    if (!dato.id) {
      throw new Error('Dato sin ID!');
    }
    if (dato.id) {
      endpoint += dato.id;
    }
    /*if (Repositorio.overridingEndpoint) {
        endpoint = Repositorio.overridingEndpoint(endpoint, this);
    }*/
    endpoint += '/';
    let ret: any;
    ret = await WebapiService.Singleton.del(endpoint);
    if (ret) {
      const detObj = this.findByField('id', dato.id);
      if (!detObj) {
        const index = this.objetos.indexOf(detObj);
        if (index) {
          this.objetos.splice(index, 1);
        }
      }
      return dato;
    }
    return dato;
  }

  /** Searches for an object with a value on a field. Regresa null si no se encontró. */
  findByField(fieldName: string, value: any): Dato {
    for (const obj of this.objetos) {
      if (obj[fieldName] === value) { return obj; }
    }
    return null;
  }

  /** Searches for an object with a value on a field. Regresa null si no se encontró. */
  findByFields(fields: {}): Dato {
    for (const element of this.objetos) {
      let found = true;
      for (const key in fields) {
        if (fields.hasOwnProperty(key)) {
          if (!element[key]) {
            found = false;
            break;
          }
          if (element[key] !== fields[key]) {
            found = false;
            break;
          }
        }
      }
      if (found) { return element; }
    }
    return null;
  }

  /*onChange(callback: () => void) {
      this.onChangeHandlers.push(callback);
  }

  onFetched(callback: () => void) {
      if (this.didFetch && !this.isFetching) callback();
      this.onFetchedHandlers.push(callback);
  }

  refreshIfNedeed(forced: boolean) {
      if (!this.didFetch && !this.isFetching) {
          console.log('Fetching stale repository " + this.apiPath);
          this.fetch()
              .then(() => { })
              .catch((err) => {
                  console.log(err);
              });
      }
  }*/

  clear(): void {
    this.objetos.splice(0);
    this.updated = false;
  }

  clone(): Repositorio {
    return new Repositorio(this.name, this.endpoint, this.editor, this.ctr);
  }

  isArray(ret: any): boolean {
    if (!ret) { return false; }
    if (Array.isArray) { return Array.isArray(ret); }
    return Object.prototype.toString.call(ret) === '[object Array]';
  }
}