import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cargando',
  templateUrl: './cargando.component.html',
  styleUrls: ['./cargando.component.scss'],
})
export class CargandoComponent implements OnInit {
  //@Input() loadingText = 'Cargando...';
  @Input() loadingText = this.translateServ.instant('SISTEMA.CARGANDO');
  @Input() failedText = 'No se pudo cargar la información';
  @Input() failed = false;

  constructor(
    private translateServ: TranslateService,
  ) { }

  ngOnInit() { }

}
