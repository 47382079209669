import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

export class MensajeErrorValidador {
  type: string;
  message: string;
}

@Component({
  selector: 'app-mensaje-validacion',
  templateUrl: './mensaje-validacion.component.html',
  styleUrls: ['./mensaje-validacion.component.scss'],
})
export class MensajeValidacionComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() field: string;
  @Input() mensajesError: MensajeErrorValidador[];

  constructor() {
    this.mensajesError = [
      { type: 'required', message: 'Este campo es requerido.' },
      { type: 'missmatch', message: 'Contraseñas no coinciden.' },
      { type: 'minlength', message: 'Contraseña de al menos 8 caracteres.' },
    ];
  }

  ngOnInit() {
    if (!this.form.get(this.field)) {
      throw new Error('Campo ' + this.field + ' no se encontró');
    }
    if (!Array.isArray(this.mensajesError)) {
      console.error(this.mensajesError);
      throw new Error('Campo ' + this.field + ' no se asignaron mensajes de error válidos');
    }
  }

  tieneError(input: string, type: string): boolean {
    return this.form.get(input).hasError(type);
  }

}
