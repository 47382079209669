import { Injectable } from '@angular/core';
import { WebapiService } from './webapi.service';
import { File as FileNPX} from '@ionic-native/file/ngx';
import { ConfigService } from './config.service';
import { UiService } from './ui.service';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import * as mime from 'mime';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private webapiServ: WebapiService,
    private fileNpx: FileNPX,
    private fileOpener: FileOpener,
    private uiServ: UiService,
    private translateServ: TranslateService,
    private configServ: ConfigService) { }

  async downloadFileFromApi(apiPath: string, objectId: any, filePath: string) {
    apiPath = apiPath.replace(':id', objectId);
    const url = this.webapiServ.apiURLtoFullURL(apiPath);
    const fileName = this.getFilenameFromPath(filePath);
    await this.downloadURL(url, fileName);
  }

  async downloadURL(fullURL: string, filename?: string) {
    const lastIndex = fullURL.lastIndexOf('/');
    const index = (lastIndex < 0 ? 0 : lastIndex) + 1;
    const name = filename || fullURL.substr(index);

    const jif = await this.uiServ.cargando(await this.translateServ.get('NOTICIAS.DESCARGANDO').toPromise());
    try {
      const res = await this.webapiServ.get(fullURL, {}, { responseType: 'blob' });
      this.downloadBlob(res, name);
      jif.dismiss();
    } catch (err) {
      if (!this.configServ.esCordova) {
        try {
          const link = document.createElement('a');
          link.download = name;
          link.href = fullURL;
          link.target = '_blank';
          document.body.appendChild(link);
          const e = document.createEvent('MouseEvents');
          e.initEvent('click', true, true);
          link.dispatchEvent(e);
          // link.click();
          document.body.removeChild(link);
        } catch (err) {
          this.uiServ.error(err);
        }
        jif.dismiss();
      } else {
        jif.dismiss();
        this.uiServ.error(err);
      }
    }
  }

  async downloadCordova(fullURL: string, filename?: string){

  }

  /*async downloadFile(fullURL: string) {

  }*/

  async downloadBlob(blob: Blob, fileName: string) {
    console.log('Downloading blob:', blob, ' with filename: ', fileName);
    if (this.configServ.esCordova) {
      // Android, iOS Native
      try {
        const res = await this.fileNpx.writeFile(this.fileNpx.dataDirectory, fileName, blob, { replace: true });
        console.log(res);

        const mimeType = mime.getType(fileName);
        if (mimeType) {
          console.log('Opening blob natively with MIME of ', mimeType);
          const openRes = await this.fileOpener.showOpenWithDialog(res.nativeURL, mimeType);
          console.log(openRes);
        }
      } catch (err) {
        this.uiServ.error(err);
      }
    } else {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE and Edge
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        // Chrome and Firefox
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }
    }
  }

  getFilenameFromPath(path: string): string {
    let lastIndex = path.lastIndexOf('/');
    if (lastIndex !== 0) { lastIndex += 1; }
    // const index = (lastIndex < 0 ? 0 : lastIndex);
    return path.substr(lastIndex);
  }

}

/*

if (this.configServ.esCordova) {
  /*const fileTransfer: FileTransferObject = this.transfer.create();
  console.log(fullURL);
  fileTransfer.download(fullURL, this.file.dataDirectory + name).then((entry) => {
    console.log(entry);
    console.log('download complete: ' + entry.toURL());
  }, (error) => {
    this.uiServ.error(error);
  });*
  const jif = await this.uiServ.cargando();
  try {
    const res = await this.webapiServ.get(fullURL, {}, { responseType: 'blob' });
    this.downloadBlob(res, name);
    jif.dismiss();
  } catch (err) {
    jif.dismiss();
    this.uiServ.error(err);
  }

} else {
  /*
  // Download as blobs
  try {
    const res = await this.webapiServ.get(fullURL, {}, { responseType: 'blob' });
    this.downloadBlob(res, name);
  } catch (err) {
    this.error(err);
  } // *
  // const blob = this.webapiServ.get(url, )
  // this.webapiServ.downloadInBrowser(url);
  const link = document.createElement('a');
  link.download = name;
  link.href = fullURL;
  link.target = '_blank';
  document.body.appendChild(link);
  const e = document.createEvent('MouseEvents');
  e.initEvent('click', true, true);
  link.dispatchEvent(e);
  // link.click();
  document.body.removeChild(link);
  //
}*/