
export class Estado {
  val: string;
  t: string;
}

export const Estados: Estado[] = [
  { val: '1', t: 'Aguascalientes' },

  { val: '2', t: 'Baja California' },

  { val: '3', t: 'Baja California Sur' },

  { val: '4', t: 'Campeche' },

  { val: '5', t: 'Coahuila de Zaragoza' },

  { val: '6', t: 'Colima' },

  { val: '7', t: 'Chiapas' },

  { val: '8', t: 'Chihuahua' },

  { val: '9', t: 'Distrito Federal' },

  { val: '10', t: 'Durango' },

  { val: '11', t: 'Guanajuato' },

  { val: '12', t: 'Guerrero' },

  { val: '13', t: 'Hidalgo' },

  { val: '14', t: 'Jalisco' },

  { val: '15', t: 'México' },

  { val: '16', t: 'Michoacán de Ocampo' },

  { val: '17', t: 'Morelos' },

  { val: '18', t: 'Nayarit' },

  { val: '19', t: 'Nuevo León' },

  { val: '20', t: 'Oaxaca' },

  { val: '21', t: 'Puebla' },

  { val: '22', t: 'Querétaro' },

  { val: '23', t: 'Quintana Roo' },

  { val: '24', t: 'San Luis Potosí' },

  { val: '25', t: 'Sinaloa' },

  { val: '26', t: 'Sonora' },

  { val: '27', t: 'Tabasco' },

  { val: '28', t: 'Tamaulipas' },

  { val: '29', t: 'Tlaxcala' },

  { val: '30', t: 'Veracruz' },

  { val: '31', t: 'Yucatán' },

  { val: '32', t: 'Zacatecas' },
]