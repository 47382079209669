import { Pipe, PipeTransform } from '@angular/core';
import { months } from '../data/meses';

export function FechaDetalladaEnEspa(date: Date) {
  let minutes = date.getMinutes().toString();
  if (date.getMinutes() < 10) { minutes = '0' + date.getMinutes(); }
  return date.getDate() + ' de ' + months[date.getMonth()] + ' de ' + date.getFullYear() + ' a las ' + date.getHours() + ':' + minutes;
}

export function FechaCortaEnEspa(date: Date) {
  let minutes = date.getMinutes().toString();
  if (date.getMinutes() < 10) { minutes = '0' + date.getMinutes(); }
  // return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + ' ' + date.getHours() + ':' + minutes;
  return date.getDate() + ' de ' + months[date.getMonth()] + ' de ' + date.getFullYear();
}

function hora(fechayhora: string, tz?: string): string {
  if (fechayhora === undefined || fechayhora == null) { return 'No disponible'; }
  let time = new Date(fechayhora);
  if (tz) {
    return time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, });
  } else {
    return time.toLocaleString('es-MX', { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: tz });
  }
}

/** REGRESA DIRECTAMENTE LA HORA ORIGINAL DEL STRING SIN TRANSFORMAR A HORA LOCAL DEL CLIENTE */
function horaLocal(fechayhora: string): string {
  if (fechayhora === undefined || fechayhora == null) { return 'No disponible'; }
  const noTZ = fechayhora.substring(0, 19);
  console.log(noTZ);
  const time = new Date(noTZ);
  let hour = time.getHours();
  let ampm = hour < 13 ? 'AM' : 'PM';
  if (hour > 12) hour -= 12;
  let minutes: string | number = time.getMinutes();
  if (minutes < 10) minutes = '0' + minutes.toString();
  else minutes = minutes.toString();
  return hour + ':' + minutes + ' ' + ampm;
  // console.log(tz, '-', time, '-', fechayhora);
  // console.log(fechayhora, time);
  // return time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, });
  // return time.getHours() + ':' + time.getMinutes();
}

function fechaCorta(fechayhora: string): string {
  if (fechayhora == undefined || fechayhora == null) { return 'No disponible'; }
  let indx = fechayhora.indexOf('T');
  let fch = fechayhora.substr(0, indx).split('-');
  return fch[2] + '/' + fch[1] + '/' + fch[0];
}

/** Pipe for date processing */
@Pipe({
  name: 'fechaEspa'
})
export class FechaEspaPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (args === 'hora') {
      return hora(value);
    } else if (args === 'fechaCorta') {
      return fechaCorta(value);
    } else if (args === 'fechaCortaEsp') {
      const date = new Date(value);
      return FechaCortaEnEspa(date);
    } else {
      const date = new Date(value);
      return FechaDetalladaEnEspa(date);
    }
  }

}

/** Pipe for date processing */
@Pipe({
  name: 'fechaLocal'
})
export class FechaLocalPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (args === 'hora') {
      // return hora(value, 'America/Mazatlan');
      return horaLocal(value);
    }
    return null; /*else if (args === 'fechaCorta') {
      return fechaCorta(value);
    } else if (args === 'fechaCortaEsp') {
      const date = new Date(value);
      return FechaCortaEnEspa(date);
    } else {
      const date = new Date(value);
      return FechaDetalladaEnEspa(date);
    }*/
  }

}

