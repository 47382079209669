import { Repositorio, Dato } from './dato';

export class EditorInput {
    type: string = 'text';
    titulo: string = '';
    property: string;
    placeholder?: string = '';
    options?: any = null;
    objectStorage?: string;
    min?: number;
    max?: number;
    repo?: Repositorio;
    objectTitle ?= 'Seleccione';
    objectDesc ?= '';
    disabled ?= false;
    required?: boolean = true;
    validator?: string = 'text';
    writeable?: boolean = true;
    linkedRepos?: Repositorio[];
    editorOption?: string;
    showWhen?: string | string[];
    disableWhen?: string | string[];
    autoValue?: string;
    nestedEditor?: EditorForm;
    pattern?: RegExp;
    patternText?: string;
    helpText?: string;
    activeWhen?: string;
    disableAdd?: boolean;
    readonly?: boolean;
    multiSelect?: boolean;

    /** Flag that controls wether default values should be added to select/lists */
    hideDefaults?:boolean;
}

export class EditorForm {
    /** Texto que aparece al crear un nuevo objeto */
    newObjectTitle: string;

    title?: string;

    /** Listado de todos los campos que se manejaran en el editor */
    inputs: EditorInput[];

    /** Si sólo se deben mostrar los campos y no permitir editar */
    noEditable?: boolean;

    /** Si se debe bloquear poder agregar nuevos objetos */
    noPermitirAgregar?: boolean;

    limitText?: string;

    helpText?: string;

    onBeforeChange?: (dato: Dato, formData: FormData) => void;

    getLimit?: (editorComponent: any) => number;
}
