// import { Dato } from '../providers/datos/tipos';
// import { DatoEditorForm, FormInput } from '../pages/Expositores/editor-de-datos/tipos';
// tslint:disable: no-string-literal
// tslint:disable: no-inferrable-types
// tslint:disable: variable-name
// tslint:disable: max-line-length
// tslint:disable: member-ordering

import { Dato, Repositorio } from './dato';
import { EditorForm } from './editor';
import { Giro } from './comunes';
import { EditarPage } from '../paginas/editar/editar.page';
import { GrupoSponsor } from './sponsors';
import { Tools } from '../servicios/tools';
import { ModelEditorTemplate, ModelEditorTemplates } from './editorTemplate';




/** El perfil es asociado un expostor a un usuario en el api de expositores */
export class Perfil {
  expositor: Expositor;
}

/** Proveedor_Categoria */
export class CategoriaProvedor extends Dato {
  nombre: string = '';
  toString(): string { return this.nombre; }
  static Editor: EditorForm = {
    newObjectTitle: 'Nueva categoria',
    limitText: '',
    inputs: [
      { titulo: 'Nombre:', property: 'nombre', type: 'text', placeholder: 'Nombre', max: 50, required: true },
      { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
    ]
  };
  static Repo: Repositorio = new Repositorio('CategoriaProvedor', 'categoria_proveedor/', CategoriaProvedor.Editor, CategoriaProvedor);
}

/** Proveedor */
export class Proveedor extends Dato {
  nombre: string = '';
  contacto: string = '';
  telefono: string = '';
  direccion: string = '';
  email: string = '';
  pagina_web: string = '';
  logo: string;
  categoria: any;
  toString(): string { return this.nombre; }

  static Editor: EditorForm = {
    newObjectTitle: 'Nuevo provedor',
    limitText: '',
    inputs: [
      { titulo: 'Nombre:', property: 'nombre', type: 'text', max: 100, placeholder: 'Nombre', required: true },
      { titulo: 'Categoría:', property: 'categoria', type: 'dato', repo: CategoriaProvedor.Repo, required: true },
      { titulo: 'Contacto:', property: 'contacto', type: 'text', max: 100, placeholder: 'Contacto' },
      { titulo: 'Correo:', property: 'email', type: 'email', placeholder: 'Correo', validator: 'email' },
      { titulo: 'Teléfono:', property: 'telefono', type: 'tel', placeholder: 'Télefono', max: 10, validator: 'tel', required: true },
      { titulo: 'Página web:', property: 'pagina_web', type: 'url', placeholder: 'Sitio web' },
      { titulo: 'Logo:', property: 'logo', type: 'image', writeable: false, helpText: 'El formato de la imagen deberá ser .PNG, o .JPG con fondo blanco, dimensiones de 200 x 200 pixeles, y una resolución mínima de 150 ppp.', max: 500000 },
      { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
    ]
  };

  static Repo: Repositorio = new Repositorio('Proveedor', 'proveedores/', Proveedor.Editor, Proveedor);

}


export class Contacto extends Dato {
  expositor: number = -1;
  apellido_paterno: string = '';
  apellido_materno: string = '';
  nombre: string = '';
  // lada: string = '';
  telefono: string = '';
  correo: string = '';

  toString(): string {
    return this.nombre + ' ' + this.apellido_paterno + ' ' + this.apellido_materno;
  }
}

export const ContactoEditor: EditorForm = {
  newObjectTitle: 'Nuevo contacto',
  limitText: 'Contactos',
  inputs: [
    { titulo: 'Nombre:', property: 'nombre', type: 'text', placeholder: 'Nombre', max: 60, required: true },
    { titulo: 'Apellido paterno:', property: 'apellido_paterno', type: 'text', placeholder: 'Apellido paterno', max: 60, required: true },
    { titulo: 'Apellido materno:', property: 'apellido_materno', type: 'text', placeholder: 'Apellido materno', max: 60 },
    { titulo: 'Correo:', property: 'correo', type: 'email', placeholder: 'Correo', validator: 'email', required: true },
    { titulo: 'Teléfono:', property: 'telefono', type: 'tel', placeholder: 'Télefono', max: 10, validator: 'tel' },
    { titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
  ]
};

export const ContactoRepo: Repositorio = new Repositorio('Contacto', 'contactos/', ContactoEditor, Contacto);


// ========================================================================== //

export class GrupoExpositor extends Dato {
  nombre: string;
  mostrar_app = true;
  edicion: number;
  toString() { return this.nombre; }
}
export const GrupoExpositorEditor: EditorForm = {
  newObjectTitle: 'Nuevo grupo',
  limitText: 'Grupos',
  inputs: [
    { titulo: 'Nombre:', property: 'nombre', type: 'text', placeholder: 'Nombre de grupo', max: 50, required: true },
    { titulo: 'Mostrar en mapa:', property: 'mostrar_app', type: 'bool' },
    { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
  ]
};
export const GrupoExpositorRepo: Repositorio = new Repositorio('GrupoExpositor', 'grupos_expositores/', GrupoExpositorEditor, GrupoExpositor);


// ========================================================================== //

export class Direccion extends Dato {
  expositor: number = -1;
  tipo_direccion: string = '0';
  calle: string = '';
  num_exterior: string = '';
  num_interior: string = '';
  colonia: string = '';
  codigo_postal: string = '';
  ciudad: string = '';
  estado: string = '';

  toString(): string {
    return 'Calle ' + this.calle + ' #' + this.num_exterior + ' Col. ' + this.colonia + ', ' + this.ciudad;
  }
}
export const DireccionEditor: EditorForm = {
  newObjectTitle: 'Nueva dirección',
  limitText: 'Direcciones',
  inputs: [
    // { titulo: 'Tipo:', property: 'tipo_direccion', type: 'select', required: true, options: [{ val: 0, title: 'Dirección fiscal' }, { val: 1, title: 'Dirección para recibir impresos' }] },
    { titulo: 'Calle:', property: 'calle', type: 'text', placeholder: 'Calle', max: 60, required: true },
    { titulo: 'Numero exterior:', property: 'num_exterior', type: 'text', placeholder: 'Numero exterior', required: true, max: 12 },
    { titulo: 'Numero interior:', property: 'num_interior', type: 'text', placeholder: 'Numero interior', max: 10 },
    { titulo: 'Colonia:', property: 'colonia', type: 'text', placeholder: 'Colonia', max: 30, required: true },
    { titulo: 'Código postal:', property: 'codigo_postal', type: 'text', placeholder: 'Código postal', required: true, max: 5 },
    { titulo: 'Ciudad:', property: 'ciudad', type: 'text', placeholder: 'Ciudad', max: 30, required: true },
    { titulo: 'Estado:', property: 'estado', type: 'text', placeholder: 'Estado', max: 30, required: true },
    { titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
  ]
};
export const DireccionRepo: Repositorio = new Repositorio('Direccion', 'direcciones/', DireccionEditor, Direccion);

// ========================================================================== //

/** En este archivo esta los modelos que se utilizan cuando el app esta en modo de expositor */
export class DirectorioOficial extends Dato {
  edicion: number;
  expositor: number;
  nombreEmpresa: string = '';
  giro: number;
  descripcion: string = '';
  // lada: string = '';
  telefono: string = '';
  direccion: Direccion;
  correo: string = '';
  sitioweb: string = '';
  contacto: Contacto;
  contacto_adicional: Contacto;
  logo = '';
  toString() { return 'Directorio oficial'; }
}
export const DirectorioOficialEditor: EditorForm = {
  newObjectTitle: '',
  limitText: '',
  helpText: '<img src="assets/stands/editor_help/Diapositiva1.JPG">',
  inputs: [
    { titulo: 'Nombre empresa:', property: 'nombre', type: 'text', placeholder: 'Nombre empresa', required: true, max: 100 },
    { titulo: 'Giros:', property: 'giros', type: 'dato', repo: Giro.Repositorio, required: true, disableAdd: true, multiSelect: true },
    { titulo: 'Breve descripción:', property: 'descripcion', type: 'textarea', max: 300 },
    { titulo: 'Teléfono:', property: 'telefono', type: 'tel', max: 10, required: true, },
    { titulo: 'Dirección:', property: 'direccion', type: 'dato', repo: DireccionRepo, objectTitle: 'Seleccione dirección', objectDesc: 'menu/Direccion/desc' },
    { titulo: 'Correo:', property: 'correo', type: 'email', },
    { titulo: 'Sitio web:', property: 'sitio_web', type: 'url', placeholder: 'https://ejemplo.com.mx' },
    { titulo: 'Contacto:', property: 'contacto', type: 'dato', repo: ContactoRepo, objectTitle: 'Seleccione contacto' },
    { titulo: 'Contacto adicional:', property: 'contacto_adicional', type: 'dato', repo: ContactoRepo, objectTitle: 'Seleccione contacto' },
    { titulo: 'Logo:', property: 'logo', type: 'image', writeable: false, helpText: 'El formato de la imagen deberá ser .PNG, o .JPG con fondo blanco, dimensiones de 500 x 500 pixeles, y una resolución mínima de 150 ppp.', max: 500000 },
    { titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
    { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
  ]
};
export const DirectorioOficialRepo = new Repositorio('DirectorioOficial', 'datos_directorio/', DirectorioOficialEditor, DirectorioOficial);

// ========================================================================== //

export class DatosFiscales extends Dato {
  rfc: string = '';
  razon_social: string = '';
  direccion: Direccion;
  expositor: number = 0;
  toString() { return this.razon_social; }
}
export const DatosFiscalesEditor: EditorForm = {
  newObjectTitle: 'Nuevo dato fiscal',
  limitText: '',
  inputs: [
    { titulo: 'RFC:', property: 'rfc', type: 'text', placeholder: 'RFC', max: 13, required: true },
    { titulo: 'Razón social:', property: 'razon_social', type: 'text', placeholder: 'Razón social', max: 100, required: true },
    { titulo: 'Dirección:', property: 'direccion', type: 'dato', repo: DireccionRepo, objectTitle: 'Seleccione dirección', required: true },
    { titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
  ]
};
export const DatosFiscalesRepo: Repositorio = new Repositorio('DatosFiscales', 'datos_fiscales/', DatosFiscalesEditor, DatosFiscales);

// ========================================================================== //

export class Invitacion extends Dato {
  expositor: number = -1;
  nombre: string = '';
  correo: string = '';
  edicion: number = 1;
  toString() { return this.nombre; }
}
export const InvitacionEditor: EditorForm = {
  newObjectTitle: 'Nueva invitación',
  limitText: 'Invitaciones',
  inputs: [
    { titulo: 'Nombre:', property: 'nombre', type: 'text', max: 70, placeholder: 'Nombre en invitación', required: true },
    { titulo: 'Correo:', property: 'correo', type: 'email', placeholder: 'Email', required: true, validator: 'email' },
    { titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
    { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
  ]/*,
  getLimit: (editorPage: any) => {
    const page = editorPage as EditarPage;
    let participacion: Participacion;
    // console.log('Buscando LIMITE', page.config.staffExpositorEditando);
    if (page.config.staffExpositorEditando && page.config.staffExpositorEditando.participacion_actual) {
      participacion = page.config.staffExpositorEditando.participacion_actual as Participacion;
    } else if (page.config.usuario) {
      participacion = page.config.usuario.obtenerParticipacionDeExpositor();
    }

    if (participacion) {
      return participacion.numero_invitaciones;
    } else {
      return 20;
    }
  }*/
};
export const InvitacionRepo: Repositorio = new Repositorio('Invitacion', 'invitaciones/', InvitacionEditor, Invitacion);


// ========================================================================== //

export class Oferton extends Dato {
  expositor: number = -1;
  producto: string = '';
  promocion: string = '';
  inicioPromocion: string = '';
  finPromocion: string = '';
  contacto: string = '';
  imagenCupon: string = '';
  imagenEnFaltante: string = '';
  autorizado: boolean;
  expositorNombre: string;
  toString() { return this.producto; }

  static Editor: EditorForm = {
    newObjectTitle: 'Nuevo cupón',
    limitText: 'Cupones',
    inputs: [
      { titulo: 'Producto o servicio:*', property: 'producto', type: 'text', required: true },
      { titulo: 'Promoción (Descuento o beneficio al visitante)*:', property: 'promocion', type: 'textarea', required: true },
      { titulo: 'Inicio de promoción*:', property: 'vigencia_inicio', type: 'date', required: true },
      { titulo: 'Fin de promoción*:', property: 'vigencia_final', type: 'date', required: true },
      //{ titulo: 'Contacto Responsable de Ofertón*:', property: 'contacto', type: 'object', objectStorage: 'Contacto', objectTitle: 'Seleccione contacto', required: true },
      { titulo: 'Contacto Responsable de Ofertón*:', property: 'contacto', type: 'dato', repo: ContactoRepo, objectTitle: 'Seleccione contacto', required: true },
      { titulo: 'Diseño del cupón o logo (en alta calidad):', property: 'disenio_cupon', type: 'image', writeable: false },
      { titulo: 'Imagen del producto en caso de no tener diseño de cupón:', property: 'imagen', type: 'image', writeable: false },
      // { titulo: 'Autorizado', property: 'autorizado', type: 'bool', required: false },
      { titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
      { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
    ]
  };
  static Repo: Repositorio = new Repositorio('Oferton', 'oferton/', Oferton.Editor, Oferton);
}

// ========================================================================== //

export class Gafete extends Dato {
  expositor: number = -1;
  apellido_paterno: string = '';
  apellido_materno: string = '';
  nombre: string = '';
  edicion: number = 1;
  toString(): string { return this.nombre + ' ' + this.apellido_paterno + ' ' + this.apellido_materno; }
}
export const GafeteEditor: EditorForm = {
  newObjectTitle: 'Nuevo gafete',
  limitText: 'Gafetes',
  inputs: [
    { titulo: 'Apellido paterno:*', property: 'apellido_paterno', type: 'text', placeholder: 'Apellido paterno', max: 40 },
    { titulo: 'Apellido materno:', property: 'apellido_materno', type: 'text', placeholder: 'Apellido materno', max: 40 },
    { titulo: 'Nombre:*', property: 'nombre', type: 'text', placeholder: 'Nombre', max: 60, required: true },
    { titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
    { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
  ],
  getLimit: (editorPage: any) => {
    console.log('Buscando LIMITE');
    const page = editorPage as EditarPage;
    let participacion: Participacion;
    if (page.config.staffExpositorEditando && page.config.staffExpositorEditando.participacion_actual) {
      participacion = page.config.staffExpositorEditando.participacion_actual as Participacion;
      // participacion = this.perfil.expositor.participacion_actual as Participacion;
    } else if (page.config.usuario) {
      participacion = page.config.usuario.obtenerParticipacionDeExpositor();
    }
    if (participacion) {
      return participacion.numero_gafetes;
    } else {
      return 5;
    }
  }
};
export const GafeteRepo: Repositorio = new Repositorio('Gafete', 'gafetes/', GafeteEditor, Gafete);


// ========================================================================== //

export class Comentarios extends Dato {
  edicion: any = 1;
  titulo: string = '';
  contenido: string = '';

  autor: any;
  comentario_original: any;
  fecha_y_hora: string;
  respuestas: any[];
  leido: boolean = false;
  toString() { return this.titulo; }
}

// ========================================================================== //

export class MaterialOficial extends Dato {
  fecha_y_hora: string;
  titulo: string = '';
  descripcion: string = '';
  adjunto: string;
  edicion: number;
  imagen: string;
  toString() { return this.titulo; }
}
export const MaterialOficialEditor: EditorForm = {
  newObjectTitle: 'Nuevo gafete',
  limitText: 'Gafetes',
  inputs: [
    { titulo: 'Título:', property: 'titulo', type: 'text', max: 100, required: true },
    { titulo: 'Descripción:', property: 'descripcion', type: 'text', /* max:inf */ },
    { titulo: 'Adjunto:', property: 'adjunto', type: 'file', required: true },
    // { titulo: 'Imagen:', property: 'imagen', type: 'image', required: true },
    { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
  ]
};
export const MaterialOficialRepo: Repositorio = new Repositorio('MaterialOficial', 'material_oficial/', MaterialOficialEditor, MaterialOficial);

// ========================================================================== //

export class Notificacion extends Dato {
  fecha_y_hora: string;
  titulo: string = '';
  descripcion: string = '';
  imagen: string = '';
  adjunto: string = '';
  edicion: number;
  grupo: number;
  url_video: string;
  vigencia: string;
  visitantes = false;
  expositores = false;
  generar_mailing = true;
  toString() { return this.titulo; }
}
export const NotificacionEditor: EditorForm = {
  newObjectTitle: 'Nueva notificación',
  limitText: 'Notificaciones',
  inputs: [
    { titulo: 'Título:', property: 'titulo', type: 'text', max: 100, placeholder: 'Título', required: true },
    { titulo: 'Contenido:', property: 'descripcion', type: 'texteditor', placeholder: 'Descripción', required: true },
    { titulo: 'Imagen', property: 'imagen', type: 'image', max: 500000 },
    { titulo: 'Adjunto', property: 'adjunto', type: 'file' },
    { titulo: 'Grupo', property: 'grupo', type: 'dato', repo: GrupoExpositorRepo, showWhen: 'esExpositor' },
    { titulo: 'Dejar de mostrar en', property: 'vigencia', type: 'date', repo: GrupoExpositorRepo, required: true },
    { titulo: 'Mandar notificación', property: 'generar_mailing', type: 'bool' },
    // { titulo: 'Visitantes', property: 'visitantes', type: 'bool' },
    // { titulo: 'Expositores', property: 'expositores', type: 'bool' },
    { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
    { titulo: '**', property: 'visitantes', type: 'auto', autoValue: 'esStaffVisitante' },
    { titulo: '**', property: 'expositores', type: 'auto', autoValue: 'esStaffExpositor' },
  ]
};
export const NotificacionRepo: Repositorio = new Repositorio('Notificacion', 'notificaciones/', NotificacionEditor, Notificacion);

// ========================================================================== //

export class Prospecto extends Dato {
  folio: number = 0;
  nombre: string = '';
  correo: string = '';
  telefono: string = '';
  perfil: string = '1';
  observaciones: string = ' ';
  expositor: number;
  cupon: number;
  edicion: number;
  toString() { return this.nombre; }
}
export const ProspectoEditor: EditorForm = {
  newObjectTitle: 'Nuevo prospecto',
  limitText: '',
  inputs: [
    // { titulo: 'Folio:', property: 'folio', type: 'number', placeholder: 'Folio', required: false },
    { titulo: 'Nombre:', property: 'nombre', type: 'text', placeholder: 'Nombre', max: 100, required: true },
    { titulo: 'Correo:', property: 'correo', type: 'email', placeholder: 'Email', required: true, validator: 'email' },
    { titulo: 'Perfil:', property: 'perfil', type: 'select', placeholder: 'perfil', max: 80, required: true,
    options: [
      { id: 1, nombre: 'Empresa' },
      { id: 2, nombre: 'Estudiante' },
      { id: 3, nombre: 'Publico en general' },
      { id: 4, nombre: 'Productor agrícola' },
      { id: 5, nombre: 'Productor ganadero' },
      { id: 7, nombre: 'Productor acuícola' }
    ] },
    { titulo: 'Teléfono:', property: 'telefono', type: 'tel', placeholder: '', max: 10, required: true },
    { titulo: 'Observaciones:', property: 'observaciones', type: 'text', placeholder: 'Observaciones', max: 200, required: false, },
    { titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
    { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
  ]
};
export const ProspectoRepo: Repositorio = new Repositorio('Prospecto', 'prospectos/', ProspectoEditor, Prospecto);


// ========================================================================== //

export class Pedido extends Dato {
  edicion: number;
  expositor: number;
  concepto: string;
  subtotal: number = 0;
  iva: number = 0;
  total: number = 0;
  atendido: boolean = false;
  detalle: any[];
  requiere_factura: boolean = false;
  datos_fiscales: number;
  toString() { return this.concepto; }
}

// ========================================================================== //

export class ProductoStand extends Dato {
  stand_virtual: number;
  nombre: string;
  descripcion: string;
  folleto_producto: string;
  foto_producto: string;
  oferta: boolean;
  // precio: number;
  // mostrar: boolean;
  toString() { return this.nombre; }
  static Editor: EditorForm = {
    newObjectTitle: 'Nuevo producto de stand virtual',
    limitText: '',
    inputs: [
      { titulo: 'Nombre:', property: 'nombre', type: 'text', placeholder: '', max: 200, required: true },
      { titulo: 'Descripción:', property: 'descripcion', type: 'text', placeholder: '', max: 200, required: false },
      { titulo: 'Foto de producto:', property: 'foto_producto', type: 'image', helpText: 'El formato de la imagen deberá ser .PNG, o .JPG.', max: 500000 },
      { titulo: 'Folleto de producto:', property: 'folleto_producto', type: 'image', helpText: 'El formato de la imagen deberá ser .PNG, o .JPG.', max: 500000 },
      { titulo: 'Es oferta:', property: 'oferta', type: 'bool' },
      { titulo: '**', property: 'stand_virtual', type: 'hidden' },
    ]
  };
  static Repo: Repositorio = new Repositorio('ProductoStand', 'productos_stands/', ProductoStand.Editor, ProductoStand);
}

// ========================================================================== //

export class AsistenciaConferencias extends Dato {
  nombre: string = '';
  usuario: number;
  conferencia: number;
  toString(): string { return this.nombre; }
}

// ========================================================================== //

export class DetallesDePedido extends Dato {
  pedido: number;
  cantidad: number;
  producto: number;
  importe: number;
}

// ========================================================================== //

export const ExpositorEditor: EditorForm = {
  newObjectTitle: 'Nuevo expositor',
  title: 'Datos generales',
  inputs: [
    { titulo: 'Nombre:', property: 'nombre', type: 'text', placeholder: 'Nombre', max: 100, required: true },
    // { titulo: 'Lada:', property: 'lada', type: 'number', placeholder: 'Lada', max: 999, required: true },
    { titulo: 'Teléfono:', property: 'telefono', type: 'tel', placeholder: '', max: 10, required: true },
    //  { titulo: 'Datos fiscales:', property: 'datos_fiscales', type: 'dato', repo: DatosFiscalesRepo, placeholder: 'Seleccione...', editorOption: 'Contacto', required: true, disableWhen: 'noEsStaff' },
    { titulo: 'RFC:', property: 'rfc', type: 'text', placeholder: 'RFC', max: 13, required: true },
    { titulo: 'Razón social:', property: 'razon_social', type: 'text', placeholder: 'Razón social', max: 100, required: true },
    { titulo: 'Dirección:', property: 'direccion', type: 'dato', repo: DireccionRepo, objectTitle: 'Seleccione dirección', required: true, activeWhen: 'esNuevo' },
    { titulo: 'Representante legal:', property: 'representante_legal', type: 'dato', repo: ContactoRepo, placeholder: 'Seleccione...', editorOption: 'Contacto', required: true, activeWhen: 'esNuevo' }, //  disableWhen: 'noEsStaff',
  ]
};

export class Expositor extends Dato {
  nombre: string;
  // lada: string;
  telefono: string;
  participacion_actual: Participacion;
  representante_legal: number;
  // datos_fiscales: number;
  rfc: string = '';
  razon_social: string = '';
  direccion: Direccion;

  toString(): string {
    if (this.razon_social) {
      return this.nombre + ' (' + this.razon_social + ')';
    } else {
      return this.nombre;
    }
  }

  compareTo = Expositor.ExpositorSearchFunc;

  static ExpositorSearchFunc = (term: string, dato: Dato) => {
    const d = dato as Expositor;
    const compA = Tools.strCompare(Tools.strNormalize(d.nombre), term);
    const compB = Tools.strCompare(Tools.strNormalize(d.razon_social), term);
    return compA || compB;
  }

  static ExpositoresEditorRepo = new Repositorio('Expositores', 'expositores/', ExpositorEditor, Expositor);
}

export const ExpositorRepo = new Repositorio('Expositor', 'expositores/', ExpositorEditor, Expositor);

ModelEditorTemplates['Expositor'] = new ModelEditorTemplate({ ctor: Expositor, repo: ExpositorRepo, form: ExpositorEditor });

// ==============================PROVEEDOR STAND=============================== //
export class ProveedorStand extends Dato {
  empresa: string;
  nombre: string;
  correo: string;
  telefono: string;
  toString() { return this.nombre; }
}
export const ProveedorStandEditor: EditorForm = {
  newObjectTitle: 'Nuevo provedor de stand',
  title: 'Provedor de stand',
  limitText: 'proveedor_stand',
  inputs: [
    { titulo: 'Empresa:', property: 'empresa', type: 'text', placeholder: 'Empresa', max: 100, required: true },
    { titulo: 'Nombre:', property: 'nombre', type: 'text', placeholder: 'Nombre', max: 60, required: true },
    { titulo: 'Correo:', property: 'correo', type: 'email', placeholder: 'Correo', validator: 'email', required: true },
    { titulo: 'Teléfono:', property: 'telefono', type: 'tel', placeholder: 'Télefono', max: 10, validator: 'tel' },

    { titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
    { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
  ]
};
export const ProveedorStandRepo: Repositorio = new Repositorio('ProveedorStand', 'proveedor_stand/', ProveedorStandEditor, ProveedorStand);

// ==============================ASESOR COMERCIAL=============================== //
export class AsesorComercial extends Dato {
  empresa: string;
  nombre: string;
  correo: string;
  telefono: string;
  toString() { return this.nombre; }
}
export const AsesorComercialEditor: EditorForm = {
  newObjectTitle: 'Nuevo asesor comercial',
  title: 'Asesor comercial',
  limitText: 'proveedor_stand',
  inputs: [
    { titulo: 'Empresa:', property: 'empresa', type: 'text', placeholder: 'Empresa', max: 100, required: true },
    { titulo: 'Nombre:', property: 'nombre', type: 'text', placeholder: 'Nombre', max: 60, required: true },
    { titulo: 'Correo:', property: 'correo', type: 'email', placeholder: 'Correo', validator: 'email', required: true },
    { titulo: 'Teléfono:', property: 'telefono', type: 'tel', placeholder: 'Télefono', max: 10, validator: 'tel' },
    //{ titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
    //{ titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
  ]
};
export const AsesorComercialRepo: Repositorio = new Repositorio('AsesorComercial', 'asesor_comercial/', AsesorComercialEditor, AsesorComercial);

// ========================================================================== //
export class TipoDeContrato extends Dato {
  nombre: string;
  edicion = -1;
  plantillaPdf: string;
  firma_manual: boolean;
  
  toString() { return this.nombre; }
  static Editor: EditorForm = {
    newObjectTitle: 'Tipo de contrato',
    inputs: [
      { titulo: 'Nombre:', property: 'nombre', type: 'text', placeholder: 'Nombre', max: 200, required: true },
      // { titulo: 'Plantilla PDF:', property: 'plantillaPdf', type: 'file', required: true },
      { titulo: 'Imagen encabezado:', property: 'plantilla_imagen_encabezado', type: 'image', required: false },
      { titulo: 'Margen horizontal (mm):', property: 'margen_horizontal', type: 'number', required: true },
      { titulo: 'Margen superior (mm):', property: 'margen_superior', type: 'number', required: true },
      { titulo: 'Firma manual:', property: 'firma_manual', type: 'bool', required: true, helpText:'Indica si el contrato no utilizará firma digital' },
      { titulo: 'Contenido:', property: 'plantillaHTML', type: 'texteditor', required: true, helpText: 'No todos los estilos son aceptados en documento generado. Ver manual.' },
      // { titulo: 'Plantilla Word:', property: 'plantilla_docx', type: 'file', required: true },
      { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
    ]
  };
  static Repo: Repositorio = new Repositorio('TipoDeContrato', 'tipo_de_contrato/', TipoDeContrato.Editor, TipoDeContrato);
}

// ========================================================================== //

export class Participacion extends Dato {
  // nombre: string;
  // telefono: string;
  // datos_fiscales: number;
  nombre_reconocimiento: string;

  contacto_admon: number;
  contacto_stand: number;

  contacto_autorizado_para_compra_1: number;
  contacto_autorizado_para_compra_2: number;
  contacto_autorizado_para_compra_3: number;
  // representante_legal: number;

  // numero_stand: string;
  especificaciones_del_espacio: string;
  // grupo: number;

  numero_gafetes = 5;
  // numero_invitaciones = 30;

  expositor = -1;
  edicion = -1;

  archivo: string;
  contrato_confirmado: boolean;
  // contrato_firmado: string;

  enviado_a_firmar: boolean;
  // firmado: boolean;

  es_sponsor: boolean;
  tipo_sponsor: number;
  proveedorStand: number;
  asesor_comercial: number;
  /* nombre: string;
   nombre_reconocimiento: string;
   lada: string;
   telefono: string;*/
  tipo_contrato: number;

  firma_externa_estado: number;

  // <2024>
  stand_requiere_paredes_melanina: boolean;
  stand_requiere_marquesina: boolean;
  stand_requiere_corriente_220: boolean;
  stand_autogestion: boolean;
  requiere_gafetes_promotores: boolean;
  // </2024>

  toString(): string { return ''; }

  static ParticipacionEditor: EditorForm = {
    newObjectTitle: 'Nuevo expositor',    
    inputs: [
      // Contactos
      { titulo: 'Contacto administrador:', property: 'contacto_admon', type: 'dato', repo: ContactoRepo, placeholder: 'Seleccione...', editorOption: 'Contacto', required: true, disableWhen: ['obj:contrato_confirmado', 'noEsStaff'] },
      { titulo: 'Contacto stand:', property: 'contacto_stand', type: 'dato', repo: ContactoRepo, placeholder: 'Seleccione...', editorOption: 'Contacto', required: true, disableWhen: ['obj:contrato_confirmado', 'noEsStaff'] },
      { titulo: 'Contacto autorizado para compra:', property: 'contacto_autorizado_para_compra_1', type: 'dato', repo: ContactoRepo, placeholder: 'Seleccione...', editorOption: 'Contacto', required: true, disableWhen: ['obj:contrato_confirmado', 'noEsStaff'] },
      { titulo: 'Contacto autorizado para compra No. 2:', property: 'contacto_autorizado_para_compra_2', type: 'dato', repo: ContactoRepo, placeholder: 'Seleccione...', editorOption: 'Contacto', required: false, disableWhen: ['obj:contrato_confirmado', 'noEsStaff'], showWhen:'obj:contacto_autorizado_para_compra_1' },
      { titulo: 'Contacto autorizado para compra No. 3:', property: 'contacto_autorizado_para_compra_3', type: 'dato', repo: ContactoRepo, placeholder: 'Seleccione...', editorOption: 'Contacto', required: false, disableWhen: ['obj:contrato_confirmado', 'noEsStaff'], showWhen:'obj:contacto_autorizado_para_compra_2' },

      // Espacio
      //  { titulo: 'Número stand:', property: 'numero_stand', type: 'text', placeholder: 'Número de stand', required: false, disableWhen: ['obj:contrato_confirmado', 'noEsStaff'], max: 50 },
      //  { titulo: 'Grupo (Pabellón):', property: 'grupo', type: 'dato', repo: GrupoExpositorRepo, editorOption: 'staff-expositor-grupos', required: true, disableWhen: ['obj:contrato_confirmado', 'noEsStaff'] },
      { titulo: 'Especificaciones del espacio:', property: 'especificaciones_del_espacio', type: 'texteditor', required: true, disableWhen: ['obj:contrato_confirmado', 'noEsStaff'] },

      { titulo: 'Es sponsor:', property: 'es_sponsor', type: 'bool', disableWhen: ['obj:contrato_confirmado', 'noEsStaff'] },
      { titulo: 'Tipo de sponsor:', property: 'tipo_sponsor', type: 'dato', repo: GrupoSponsor.Repositorio, editorOption: 'staff-grupos-sponsor', disableWhen: ['obj:contrato_confirmado', 'noEsStaff'], showWhen: 'es_sponsor' },
      { titulo: 'Número de gafetes:', property: 'numero_gafetes', type: 'number', placeholder: 'Número de gafetes', required: false, disableWhen: ['obj:contrato_confirmado', 'noEsStaff'] },
      { titulo: 'Requiero gafetes para promotores/as:', property: 'requiere_gafetes_promotores', type: 'bool' },
      { titulo: 'Reconocimiento:', property: 'nombre_reconocimiento', type: 'text', placeholder: 'Ej. Mi Empresa S.A. de C.V.', max: 100 },
      { titulo: 'Asesor comercial:', property: 'asesor_comercial', type: 'dato', repo: AsesorComercialRepo, required: true, },
      { titulo: 'Tipo de contrato:', property: 'tipo_contrato', type: 'dato', repo: TipoDeContrato.Repo, required: true, disableWhen: ['obj:contrato_confirmado', 'noEsStaff']  },
      
      { titulo: 'Requiere paredes melamina:', property: 'stand_requiere_paredes_melanina', type: 'bool' },
      { titulo: 'Requiere marquesina:', property: 'stand_requiere_marquesina', type: 'bool' },
      { titulo: 'Requiere corriente 220:', property: 'stand_requiere_corriente_220', type: 'bool' },
      { titulo: 'Autogestión:', property: 'stand_autogestion', type: 'bool', helpText: 'En caso de no contar con proveedor de stand y llevar su propio material de montaje, favor de escribir su nombre como persona responsable, teléfono y correo.' },
      { titulo: 'Provedor de stand:', property: 'proveedorStand', type: 'dato', repo: ProveedorStandRepo},
      
      // { titulo: 'Número de invitaciones:', property: 'numero_invitaciones', type: 'number', placeholder: 'Número de invitaciones', required: false, disableWhen: 'obj:contrato_confirmado' },
      { titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
      { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
    ]
  };
  static ParticipacionRepo = new Repositorio('Participacion', 'participaciones/', Participacion.ParticipacionEditor, Participacion);
}

// ========================================================================== //
class Usuario {
  username: string;
  first_name: string;
  last_name: string;
  password: string;
}

export class ExpositorUsuario extends Dato {
  usuario: Usuario = new Usuario();
  activo: boolean;
  expositor: number;

  toString() {
    if (this.usuario) {
      return this.usuario.first_name + ' ' + this.usuario.last_name + ' (' + this.usuario.username + ')';
    } else {
      return 'Usuario incorrecto';
    }
  }

  public static Editor: EditorForm = {
    newObjectTitle: 'Nuevo usuario',
    inputs: [
      {
        titulo: 'Usuario:', property: 'usuario', type: 'nested', required: true, nestedEditor: {
          newObjectTitle: '*',
          inputs: [
            // https://codecraft.tv/courses/angular/advanced-topics/basic-custom-validators/
            { titulo: 'Usuario:', property: 'username', type: 'email', required: true, max: 150, }, // pattern: /^[\w.@+-_]+$/, patternText: 'Letras, dígitos y @/./+/-/_ únicamente.'
            { titulo: 'Nombre:', property: 'first_name', type: 'text', max: 80, required: true },
            { titulo: 'Apellidos:', property: 'last_name', type: 'text', max: 80, required: true },
            { titulo: 'Contraseña:', property: 'password', type: 'password', required: true, min: 6 },
          ]
        }
      },
      { titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
    ]
  };

  public static Repo = new Repositorio('ExpositorUsuario', 'perfiles_usuarios/', ExpositorUsuario.Editor, ExpositorUsuario);
}

// ========================================================================== //

export class Contrato extends Dato {
  expositor: number;
  contacto: number;
  direccion: number;
  datos_fiscales: number;
  espacio: string;
  especificaciones_del_espacio: string;
  observaciones: string;
  fecha: string;
  archivo: string;
  usuario_firma: any;
  firma: string;
  validado: boolean;

  static Editor: EditorForm = {
    newObjectTitle: '',
    inputs: [
      { titulo: 'Contacto:', property: 'contacto', type: 'dato', repo: ContactoRepo, placeholder: 'Seleccione...', editorOption: 'Contacto', required: true },
      { titulo: 'Dirección:', property: 'direccion', type: 'dato', repo: DireccionRepo, placeholder: 'Seleccione...', editorOption: 'Contacto', required: true },
      { titulo: 'Datos fiscales:', property: 'datos_fiscales', type: 'dato', repo: DatosFiscalesRepo, placeholder: 'Seleccione...', editorOption: 'Contacto', required: true },
      { titulo: 'Espacio:', property: 'espacio', type: 'text', max: 25, required: true },
      { titulo: 'Especificaciones del espacio:', property: 'especificaciones_del_espacio', type: 'textarea', required: true },
      { titulo: 'Fecha:', property: 'fecha', type: 'date', required: true },
      { titulo: 'Validado:', property: 'validado', type: 'hidden' },
      { titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
      { titulo: '**', property: 'usuario_firma', type: 'auto', autoValue: 'usuario' },
    ]
  };
  static Repo = new Repositorio('Contrato', 'contratos/', Contrato.Editor, Contrato);
}

export class ContratoFirma extends Dato {
  firma: string;
  usuario_firma: any;

  static Editor: EditorForm = {
    newObjectTitle: '',
    inputs: [
      { titulo: 'Firma:', property: 'firma', type: 'image', required: true },
      { titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
      { titulo: '**', property: 'usuario_firma', type: 'auto', autoValue: 'usuario' },
    ]
  };
  static Repo = new Repositorio('ContratoFirma', 'contratos/', ContratoFirma.Editor, ContratoFirma);
}

// ========================================================================== //

export class PagosParticipacion extends Dato {
  fecha: string;
  importe_total: any;
  importe: any;
  iva: any;
  orden_de_compra: string;
  factura_solicitada: boolean;
  comprobante: string;
  validado: boolean;
  recargo_aplicado: boolean;
  importe_recargo: any;
  orden: number;
  participacion: number;
  metodo_de_pago: number;
  uso_del_cfdi: number;
  forma_de_pago: number;
  pago_anticipo: boolean;

  obtenerTotal(): any {
    if (this.importe_total) { return this.importe_total; }
    const imp = this.importe ? parseFloat(this.importe) : 0;
    const iva = this.iva ? parseFloat(this.iva) : 0;
    return imp + iva;
  }

  static Editor: EditorForm = {
    newObjectTitle: '',
    inputs: [
      { titulo: 'Fecha:', property: 'fecha', type: 'date', required: true },
      { titulo: 'Importe:', property: 'importe', type: 'number', required: true },
      { titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
    ]
  };

  static Repo = new Repositorio('PagosParticipacion', 'pagos_participacion/', PagosParticipacion.Editor, PagosParticipacion);
}

// ==============================FACTURAS=============================== //
export class Facturas extends Dato {
  edicion: number;
  expositor: number;
  folio: string;
  rfc: string;
  uuid: string;
  concepto: string;
  xml: string = '';
  pdf: string = '';
  toString() { return this.folio; }
}
export const FacturasEditor: EditorForm = {
  newObjectTitle: 'Nueva factura',
  limitText: 'Facturas',
  inputs: [
    { titulo: 'Id:', property: 'id', type: 'number', placeholder: 'Id', max: 999, required: true },
    { titulo: 'Expositor:', property: 'expositor', type: 'number', placeholder: 'Expositor', max: 999, required: true },
    { titulo: 'Folio:', property: 'folio', type: 'text', placeholder: 'Folio', max: 10, required: true },
    { titulo: 'RFC:', property: 'rfc', type: 'text', placeholder: 'RFC', max: 13, required: true },
    { titulo: 'UUID:', property: 'uuid', type: 'text', placeholder: 'UUID', max: 100, required: true },
    { titulo: 'Concepto:', property: 'concepto', type: 'text', placeholder: 'concepto', max: 150, required: true },
    { titulo: 'XML', property: 'xml', type: 'file' },
    { titulo: 'PDF', property: 'pdf', type: 'file' },
    { titulo: '**', property: 'edicion', type: 'auto', autoValue: 'edicion' },
  ]
};
export const FacturasRepo: Repositorio = new Repositorio('Facturas', 'facturas/', FacturasEditor, Facturas);

/** Objeto que representa a forma_de_pago, uso_del_cfdi y metodo_de_pago */
export class CatalogoSAT {
  id: number;
  clave: string;
  nombre: string;
}

// ==============================UBICACION=============================== //
export class UbicacionParticipacion extends Dato {
  participacion: number;
  pabellon: number;
  numero_stand: string;
  mapa_geo_json: string;

  toString() { return this.numero_stand; }

  static Editor: EditorForm = {
    newObjectTitle: '',
    inputs: [
      { titulo: 'Grupo (Pabellón):', property: 'pabellon', type: 'dato', repo: GrupoExpositorRepo, editorOption: 'staff-expositor-grupos', required: true, disableWhen: ['obj:contrato_confirmado', 'noEsStaff'] },
      { titulo: 'Número stand:', property: 'numero_stand', type: 'text', placeholder: 'Número de stand', required: true, disableWhen: ['obj:contrato_confirmado', 'noEsStaff'], max: 50 },
      { titulo: 'Ubicación en mapa:', property: 'mapa_geo_json', type: 'geo', required: false, disableWhen: ['obj:contrato_confirmado', 'noEsStaff'] },      { titulo: 'participacion:', property: 'participacion', type: 'hidden' },
    ]
  };

  static Repo = new Repositorio('UbicacionParticipacion', 'ubicaciones/', UbicacionParticipacion.Editor, UbicacionParticipacion);
}

// ============================== Encuentro de negeocios =============================== //
export class EncuentroDeNegocios extends Dato {
  usuario: number;
  fecha_creacion: string;
  telefono: string;
  email: string;
  celular: string;
  tipo: string = '';
  solicitud: string = '';
  destinatario: number;
  comentarios_atencion: string;
  expositor: number = 0;
  atendido: boolean;
  nombre: string;
  apellido: string;
  empresa_destino: string;

  get fullName() { return this.nombre + ' ' + this.apellido; }

  toString() {
    if (this.empresa_destino) {
      return this.empresa_destino + ': ' + this.solicitud;
    } else {
      return this.solicitud;
    }
  }

  static Editor: EditorForm = {
    newObjectTitle: 'Contactar expositor',
    inputs: [
      { titulo: 'Nombre:', property: 'fullName', type: 'text', readonly: true },
      { titulo: 'Tipo:', property: 'tipo', type: 'text', required: true, max: 20, readonly: true },
      { titulo: 'MODELOS.ENCUENTRODENEGOCIOS.TELEFONO', property: 'telefono', required: true, type: 'tel', max: 10, validator: 'tel', readonly: true },
      { titulo: 'MODELOS.ENCUENTRODENEGOCIOS.CELULAR', property: 'celular', required: true, type: 'tel', max: 10, validator: 'tel', readonly: true },
      { titulo: 'MODELOS.ENCUENTRODENEGOCIOS.CORREO', property: 'email', type: 'email', required: true, max: 150, readonly: true },
      { titulo: 'MODELOS.ENCUENTRODENEGOCIOS.NECESIDAD', property: 'solicitud', type: 'textarea', required: true, readonly: true },
      // { titulo: 'Atendido:', property: 'atendido', type: 'bool' },
      // { titulo: '**', property: 'destinatario', type: 'hidden' },
      // { titulo: '**', property: 'expositor', type: 'hidden' },
      // { titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
    ]
  };

  static Repo = new Repositorio('EncuentroDeNegocios', 'encuentros_de_negocio/', EncuentroDeNegocios.Editor, EncuentroDeNegocios);

  static EditorInicial: EditorForm = {
    newObjectTitle: 'Contactar expositor',
    inputs: [
      // { titulo: 'Nombre', property: 'fullName', type: 'text', readonly: true },
      // { titulo: 'Tipo:', property: 'tipo', type: 'text', required: true, max: 20 },
      { titulo: 'MODELOS.ENCUENTRODENEGOCIOS.TELEFONO', property: 'telefono', required: true, type: 'tel', max: 10, validator: 'tel' },
      { titulo: 'MODELOS.ENCUENTRODENEGOCIOS.CELULAR', property: 'celular', readonly: true, required: true, type: 'tel', max: 10, validator: 'tel' },
      { titulo: 'MODELOS.ENCUENTRODENEGOCIOS.CORREO', property: 'email', type: 'email', readonly: true, required: true, max: 150, },
      { titulo: 'MODELOS.ENCUENTRODENEGOCIOS.NECESIDAD', property: 'solicitud', type: 'textarea', required: true },
      // { titulo: '**', property: 'destinatario', type: 'hidden' },
      { titulo: '**', property: 'expositor', type: 'hidden' },
      // { titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
    ]
  };

  static RepoInicial = new Repositorio('EncuentroDeNegocios', 'encuentros_de_negocio/', EncuentroDeNegocios.EditorInicial, EncuentroDeNegocios);
}

// ==============================Datos legales =============================== //


// ==============================ActaConstitucional=============================== //
export class ActaConstitucional extends Dato {
  expositor: number;
  nombre_notario: string;
  no_de_escritura: string;
  fecha_de_publicacion: string;
  municipio: string;

  toString() { return this.no_de_escritura + ' ' + this.nombre_notario; }

  static Form: EditorForm = {
    newObjectTitle: '',
    title: 'Acta constitutiva',
    inputs: [
      //{ titulo: 'Nombre de notario:', property: 'nombre_notario', type: 'text', max: 250, required: true },
      //{ titulo: 'Número de notario:', property: 'no_de_notario', type: 'text', max: 100, required: true },
      //{ titulo: 'Número de escritura:', property: 'no_de_escritura', type: 'text', max: 100, required: true },
      //{ titulo: 'Fecha de publicación:', property: 'fecha_de_publicacion', type: 'date', required: true },
      //{ titulo: 'Estado de publicación:', property: 'estado_publicacion', type: 'text', max: 250, required: true },
      //{ titulo: 'Municipio de publicación:', property: 'municipio_publicacion', type: 'text', max: 250, required: true },
      { titulo: 'Documento', property: 'documento', type: 'file', max: 20000000, required: false, helpText:'Tamaño maximo 20 megabytes' },
      { titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
    ]
  };

  static Repo = new Repositorio('ActaConstitucional', 'actas-constitucionales/', ActaConstitucional.Form, ActaConstitucional);
}
ModelEditorTemplates['ActaConstitucional'] = new ModelEditorTemplate({ ctor: ActaConstitucional, repo: ActaConstitucional.Repo, form: ActaConstitucional.Form });


// ==============================PoderLegal=============================== //
export class PoderLegal extends Dato {
  expositor: number;
  nombre_notario: string;
  no_de_notario: string;
  no_de_escritura: string;
  fecha_de_publicacion: string;
  estado_publicacion: string;
  municipio_publicacion: string;
  rfc: string;

  toString() { return this.no_de_escritura + ' ' + this.nombre_notario; }

  static Form: EditorForm = {
    newObjectTitle: '',
    title: 'Poder legal',
    inputs: [
      //{ titulo: 'Nombre de notario:', property: 'nombre_notario', type: 'text', max: 250, required: true },
      //{ titulo: 'Número de notario:', property: 'no_de_notario', type: 'text', max: 100, required: true },
      //{ titulo: 'Número de escritura:', property: 'no_de_escritura', type: 'text', max: 100, required: true },
      //{ titulo: 'Fecha de publicación:', property: 'fecha_de_publicacion', type: 'date', required: true },
      //{ titulo: 'Estado de publicación:', property: 'estado_publicacion', type: 'text', max: 250, required: true },
      //{ titulo: 'Municipio de publicación:', property: 'municipio_publicacion', type: 'text', max: 250, required: true},
      //{ titulo: 'RFC:', property: 'rfc', type: 'text', max: 13, required: true },
      { titulo: 'Documento', property: 'documento', type: 'file', max: 20000000, required: false, helpText:'Tamaño maximo 20 megabytes' },
      { titulo: '**', property: 'expositor', type: 'auto', autoValue: 'expositor' },
    ]
  };

  static Repo = new Repositorio('PoderLegal', 'poderes-legales/', PoderLegal.Form, PoderLegal);
}

ModelEditorTemplates['PoderLegal'] = new ModelEditorTemplate({ ctor: PoderLegal, repo: PoderLegal.Repo, form: PoderLegal.Form });
