import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  listeners: { [name: string]: ((params) => void)[]; } = {};

  constructor() {

  }

  publish(eventName: string, params: any) {
    if (this.listeners[eventName]) {
      for (const listen of this.listeners[eventName]) {
        listen(params);
      }
    }
  }

  subscribe(eventName: string, listener: (params) => void) {
    if (!this.listeners[eventName]) {
      this.listeners[eventName] = [];
    }
    this.listeners[eventName].push(listener);
  }

  unsubscribe(eventName: string, listener: (params) => void): boolean {
    if (!this.listeners[eventName]) { return false; }
    const indx = this.listeners[eventName].indexOf(listener);
    if (indx > -1) {
      this.listeners[eventName].splice(indx, 1);
      return true;
    } else {
      return false;
    }
  }
}
