import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { FechaEspaPipe, FechaLocalPipe } from './fecha-espa.pipe';
import { CargandoComponent } from './cargando/cargando.component';
import { EditorComponent } from './editor/editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MensajeValidacionComponent } from './mensaje-validacion/mensaje-validacion.component';
import { QuillModule } from 'ngx-quill';

import { AutosizeModule } from 'ngx-autosize';
import { FacturarComponent } from './facturar/facturar.component';
import { RealizarCompraComponent } from './realizar-compra/realizar-compra.component';
import { CrearVisitanteComponent } from './crear-visitante/crear-visitante.component';
import { MensajeCentradoComponent } from './mensaje-centrado/mensaje-centrado.component';
import { TranslateModule, TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { CrudComponent } from './crud/crud.component';
import { EditorGeoComponent } from './editor-geo/editor-geo.component';
import { ModalUbicacionesComponent } from '../paginas/mapas/modal-ubicaciones/modal-ubicaciones.component';

@NgModule({
  entryComponents: [
    EditorComponent,
    FacturarComponent,
    CrudComponent,
    RealizarCompraComponent,
    CrearVisitanteComponent,
    EditorGeoComponent,
    ModalUbicacionesComponent,
  ],
  declarations: [
    HeaderComponent,
    CargandoComponent,
    EditorComponent,
    EditorGeoComponent,
    ModalUbicacionesComponent,
    CrudComponent,
    MensajeValidacionComponent,
    FechaEspaPipe,
    FechaLocalPipe,
    FacturarComponent,
    RealizarCompraComponent,
    CrearVisitanteComponent,
    MensajeCentradoComponent,
  ],
  imports: [
    HttpClientModule,
    IonicModule,
    QuillModule,
    // IonicSelectableModule,
    CommonModule,
    AutosizeModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule
  ],
  exports: [
    HeaderComponent,
    EditorComponent,
    EditorGeoComponent,
    ModalUbicacionesComponent,
    CargandoComponent,
    MensajeValidacionComponent,
    FechaEspaPipe,
    FechaLocalPipe,
    FacturarComponent,
    RealizarCompraComponent,
    CrearVisitanteComponent,
    MensajeCentradoComponent,
    CrudComponent,
    TranslateDirective,
    TranslatePipe,
  ]
})
export class ExpoceresModule { }
