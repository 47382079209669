import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mensaje-centrado',
  templateUrl: './mensaje-centrado.component.html',
  styleUrls: ['./mensaje-centrado.component.scss'],
})
export class MensajeCentradoComponent implements OnInit {
  @Input() texto = '';

  constructor() { }

  ngOnInit() { }

}
