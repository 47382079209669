// tslint:disable: max-line-length
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './servicios/auth.guard';

const routes: Routes = [
  // <PAGINAS SIN USUARIO ACTIVO>
  { path: '', redirectTo: 'inicio', pathMatch: 'full' },
  { path: 'inicio', loadChildren: () => import('./paginas/inicio/inicio.module').then(m => m.InicioPageModule) },
  { path: 'registro', loadChildren: () => import('./paginas/visitantes/registro-visitante/registro-visitante.module').then(m => m.RegistroVisitantePageModule) },
  { path: 'error404', loadChildren: () => import('./paginas/miscelaneo/error404/error404.module').then(m => m.Error404PageModule) },
  { path: 'recuperar-pass', loadChildren: () => import('./paginas/recuperar-pass/recuperar-pass.module').then(m => m.RecuperarPassPageModule) },
  // </PAGINAS SIN USUARIO ACTIVO>

  // <PAGINAS CON USUARIO ACTIVO>
  { path: 'noticias', canActivate: [AuthGuard], loadChildren: () => import('./paginas/noticias/noticias.module').then(m => m.NoticiasPageModule) },
  { path: 'conferencias', canActivate: [AuthGuard], loadChildren: () => import('./paginas/conferencias/conferencias.module').then(m => m.ConferenciasPageModule) },
  { path: 'conferencias/:id/', canActivate: [AuthGuard], loadChildren: () => import('./paginas/conferencias/conferencia-detalles/conferencia-detalles.module').then(m => m.ConferenciaDetallesPageModule) },
  { path: 'conferencias/:id', canActivate: [AuthGuard], loadChildren: () => import('./paginas/conferencias/conferencia-detalles/conferencia-detalles.module').then(m => m.ConferenciaDetallesPageModule) },
  { path: 'editar-datos/:opcion', canActivate: [AuthGuard], loadChildren: () => import('./paginas/editar/editar.module').then(m => m.EditarPageModule) },
  { path: 'editar/:templates', canActivate: [AuthGuard], loadChildren: () => import('./paginas/multieditar/multieditar.module').then(m => m.MultieditarPageModule) },
  { path: 'lista/:opcion', canActivate: [AuthGuard], loadChildren: () => import('./paginas/editar/editar.module').then(m => m.EditarPageModule) },
  { path: 'material-oficial', canActivate: [AuthGuard], loadChildren: () => import('./paginas/expositores/material-oficial/material-oficial.module').then(m => m.MaterialOficialPageModule) },
  { path: 'conferencia-magistral', canActivate: [AuthGuard], loadChildren: () => import('./paginas/conferencias/conferencia-magistral/conferencia-magistral.module').then(m => m.ConferenciaMagistralPageModule) },
  { path: 'proveedores', canActivate: [AuthGuard], loadChildren: () => import('./paginas/expositores/proveedores/proveedores.module').then(m => m.ProveedoresPageModule) },
  { path: 'mapas', canActivate: [AuthGuard], loadChildren: () => import('./paginas/mapas/mapas.module').then(m => m.MapasPageModule) },
  { path: 'recomendaciones', canActivate: [AuthGuard], loadChildren: () => import('./paginas/recomendaciones/recomendaciones.module').then(m => m.RecomendacionesPageModule) },
  { path: 'restaurantes', canActivate: [AuthGuard], loadChildren: () => import('./paginas/restaurantes/restaurantes.module').then(m => m.RestaurantesPageModule) },  
  { path: 'oferton',canActivate: [AuthGuard], loadChildren: () => import('./paginas/oferton/oferton.module').then(m => m.OfertonPageModule) },
  { path: 'galeria', canActivate: [AuthGuard], loadChildren: () => import('./paginas/galeria/galeria.module').then(m => m.GaleriaPageModule) },
  { path: 'galeria/:id/', canActivate: [AuthGuard], loadChildren: () => import('./paginas/galeria/galeria-fotos/galeria-fotos.module').then(m => m.GaleriaFotosPageModule) },
  { path: 'mi-pase', canActivate: [AuthGuard], loadChildren: () => import('./paginas/codigo-qr/codigo-qr.module').then(m => m.CodigoQrPageModule) },
  { path: 'programa-general', canActivate: [AuthGuard], loadChildren: () => import('./paginas/conferencias/conferencias.module').then(m => m.ConferenciasPageModule) },
  { path: 'contrato', canActivate: [AuthGuard], loadChildren: () => import('./paginas/expositores/contrato/contrato.module').then(m => m.ContratoPageModule) },
  { path: 'participacion', canActivate: [AuthGuard], loadChildren: () => import('./paginas/expositores/participacion/participacion.module').then(m => m.ParticipacionPageModule) },
  { path: 'facturas', canActivate: [AuthGuard], loadChildren: () => import('./paginas/expositores/facturas/facturas.module').then(m => m.FacturasPageModule) },
  { path: 'prospectos', canActivate: [AuthGuard], loadChildren: () => import('./paginas/expositores/prospectos/prospectos.module').then(m => m.ProspectosPageModule) },
  /*{ path: 'tienda', canActivate: [AuthGuard], loadChildren: () => import('./paginas/tienda/tienda.module').then(m => m.TiendaPageModule) },
  { path: 'tienda/categoria/:id/', canActivate: [AuthGuard], loadChildren: () => import('./paginas/tienda/tienda-categoria/tienda-categoria.module').then(m => m.TiendaCategoriaPageModule) },
  { path: 'tienda/producto/:id/', canActivate: [AuthGuard], loadChildren: () => import('./paginas/tienda/tienda-producto/tienda-producto.module').then(m => m.TiendaProductoPageModule) },
  { path: 'tienda/categoria/:id', canActivate: [AuthGuard], loadChildren: () => import('./paginas/tienda/tienda-categoria/tienda-categoria.module').then(m => m.TiendaCategoriaPageModule) },
  { path: 'tienda/producto/:id', canActivate: [AuthGuard], loadChildren: () => import('./paginas/tienda/tienda-producto/tienda-producto.module').then(m => m.TiendaProductoPageModule) },
  { path: 'tienda/carrito', canActivate: [AuthGuard], loadChildren: () => import('./paginas/tienda/tienda-carrito/tienda-carrito.module').then(m => m.TiendaCarritoPageModule) },
  { path: 'tienda/mis-compras', loadChildren: () => import('./paginas/tienda/tienda-mis-compras/tienda-mis-compras.module').then(m => m.TiendaMisComprasPageModule) },
  { path: 'tienda/mis-compras/', loadChildren: () => import('./paginas/tienda/tienda-mis-compras/tienda-mis-compras.module').then(m => m.TiendaMisComprasPageModule) },
  { path: 'tienda/pago-realizado', canActivate: [AuthGuard], loadChildren: () => import('./paginas/tienda/tienda-pago-realizado/tienda-pago-realizado.module').then(m => m.TiendaPagoRealizadoPageModule) },*/
  { path: 'conferencia-registro', canActivate: [AuthGuard], loadChildren: () => import('./paginas/conferencias/conferencia-registro/conferencia-registro.module').then(m => m.ConferenciaRegistroPageModule) },
  { path: 'registrar-entrada', canActivate: [AuthGuard], loadChildren: () => import('./paginas/staff/registrar-entrada/registrar-entrada.module').then(m => m.RegistrarEntradaPageModule) },
  { path: 'registrar-en-conferencia', canActivate: [AuthGuard], loadChildren: () => import('./paginas/staff/registrar-en-conferencia/registrar-en-conferencia.module').then(m => m.RegistrarEnConferenciaPageModule) },
  { path: 'reportes', canActivate: [AuthGuard], loadChildren: () => import('./paginas/staff/reportes/reportes.module').then(m => m.ReportesPageModule) },
  { path: 'consumos', canActivate: [AuthGuard], loadChildren: () => import('./paginas/staff/consumos/consumos.module').then(m => m.ConsumosPageModule) },
  { path: 'ver-expositor/:id/', canActivate: [AuthGuard], loadChildren: () => import('./paginas/staff/ver-expositor/ver-expositor.module').then(m => m.VerExpositorPageModule) },
  { path: 'ver-expositor/:id', canActivate: [AuthGuard], loadChildren: () => import('./paginas/staff/ver-expositor/ver-expositor.module').then(m => m.VerExpositorPageModule) },
  { path: 'contactar-expositor', canActivate: [AuthGuard], loadChildren: () => import('./paginas/contactar-expositor/contactar-expositor.module').then(m => m.ContactarExpositorPageModule) },
  { path: 'contactar-expositor/lista', canActivate: [AuthGuard], loadChildren: () => import('./paginas/contactar-expositor/contactar-expositor.module').then(m => m.ContactarExpositorPageModule) },
  { path: 'directorio-de-expositores', canActivate: [AuthGuard], loadChildren: () => import('./paginas/contactar-expositor/contactar-expositor.module').then(m => m.ContactarExpositorPageModule) },
  { path: 'stand-lobby', canActivate: [AuthGuard], loadChildren: () => import('./paginas/stands/stand-lobby/stand-lobby.module').then(m => m.StandLobbyPageModule) },
  // { path: 'stand-virtual/:id/', canActivate: [AuthGuard], loadChildren: () => import('./paginas/stands/stand-virtual/stand-virtual.module').then(m => m.StandVirtualPageModule) },
  //{ path: 'stand-virtual/:id', canActivate: [AuthGuard], loadChildren: () => import('./paginas/stands/stand-virtual/stand-virtual.module').then(m => m.StandVirtualPageModule) },
  { path: 'micrositio/:id/', canActivate: [AuthGuard], loadChildren: () => import('./paginas/stands/stand-virtual-v2/stand-virtual-v2.module').then(m => m.StandVirtualV2PageModule) },
  { path: 'micrositio/:id', canActivate: [AuthGuard], loadChildren: () => import('./paginas/stands/stand-virtual-v2/stand-virtual-v2.module').then(m => m.StandVirtualV2PageModule) },
  { path: 'stand-editor', canActivate: [AuthGuard], loadChildren: () => import('./paginas/stands/stand-editor/stand-editor.module').then(m => m.StandEditorPageModule) },
  { path: 'expo-digital-enlace', canActivate: [AuthGuard], loadChildren: () => import('./paginas/expo-digital-enlace/expo-digital-enlace.module').then(m => m.ExpoDigitalEnlacePageModule) },
  { path: 'editar-stand', canActivate: [AuthGuard], loadChildren: () => import('./paginas/stands/stand-editor/stand-editor.module').then(m => m.StandEditorPageModule) },
  { path: 'staff-landing', canActivate: [AuthGuard], loadChildren: () => import('./paginas/staff/landing/landing.module').then( m => m.LandingPageModule) },
  // </PAGINAS CON USUARIO ACTIVO>

  // PAGINAS SIN USUARIO ACTIVO
  {
    path: 'aviso-privacidad',
    loadChildren: () => import('./paginas/misc/aviso-privacidad/aviso-privacidad.module').then( m => m.AvisoPrivacidadPageModule)
  },
  { path: '**', loadChildren: () => import('./paginas/miscelaneo/error404/error404.module').then(m => m.Error404PageModule) },
  {
    path: 'stand-virtual-v2',
    loadChildren: () => import('./paginas/stands/stand-virtual-v2/stand-virtual-v2.module').then( m => m.StandVirtualV2PageModule)
  },



  // { path: 'multieditar', canActivate: [AuthGuard], loadChildren: () => import('./paginas/multieditar/multieditar.module').then( m => m.MultieditarPageModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
