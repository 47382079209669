// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backendURL: 'https://pruebas.expoceres.com.mx',
  backendApiURL: '/api',
  allowPushNotifications: true,
  analyticsEnabled: true,
  crashlyticsEnabled: true,
  firebaseWebConfig: {
    apiKey: 'AIzaSyD6wMn5hfYnBcSOBV9QCMm1X8BZQkld7ao',
    authDomain: 'expoceres-aec26.firebaseapp.com',
    databaseURL: 'https://expoceres-aec26.firebaseio.com',
    projectId: 'expoceres-aec26',
    storageBucket: 'expoceres-aec26.appspot.com',
    messagingSenderId: '798050878575',
    appId: '1:798050878575:web:ba9f1a8556535aef7a399a',
    measurementId: 'G-11J1M3NS9E'
  }
};

environment.backendApiURL = environment.backendURL + environment.backendApiURL;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
