import { QuillConfig } from 'ngx-quill';

export const ngquillConfig: QuillConfig = {
  modules: {
    // syntax: true,
    clipboard: true,
    keyboard: true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }],               // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],      // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }],          // outdent/indent

      [{ size: ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }],          // dropdown with defaults from theme
      // [{ font: [] }],
      [{ align: [] }],

      ['clean'],                                         // remove formatting button

      ['link', 'image', 'video']                         // link and image, video
    ]
  },
  placeholder: '',
};

/*
export const firebaseConfig = {
  apiKey: 'AIzaSyD6wMn5hfYnBcSOBV9QCMm1X8BZQkld7ao',
  authDomain: 'expoceres-aec26.firebaseapp.com',
  databaseURL: 'https://expoceres-aec26.firebaseio.com',
  projectId: 'expoceres-aec26',
  storageBucket: 'expoceres-aec26.appspot.com',
  messagingSenderId: '798050878575',
  appId: '1:798050878575:web:ba9f1a8556535aef7a399a',
  measurementId: 'G-11J1M3NS9E'
};
*/



