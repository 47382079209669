import { NavController } from '@ionic/angular';
import { EventsService } from '../servicios/events.service';
import { Repositorio, Dato } from './dato';
import { EditorForm } from './editor';
import { EditorOption, ExpoConfigMenuOpcion } from './menu';

export class ModelEditorTemplate extends ExpoConfigMenuOpcion {
  isArray?: boolean;

  reloadEvent?: string;
  repo?: Repositorio;
  datoUnico = true;
  endpoint?: string;
  form:  EditorForm;
  ctor?: new () => Dato;
  editorActions?: EditorOption[];
  disableDelete?: boolean;
  disableAdd?: boolean;
  disableEdit?: boolean;
  noDialogs?: boolean;
  saveWithPatch?: boolean;
  editVars?: {};
  backUrl?: string;
  onDatoSaved?: (dato: Dato, events: EventsService, navCtrl: NavController) => boolean;
  onDatoClick?: (dato: Dato, events: EventsService, navCtrl: NavController) => boolean;
  onLoad?: (paginaEditora: any) => Promise<void>;
  onCancelOverride?: () => void;
  customDialogues?: { savedText: string, failedText: string };

  constructor(other?: Partial<ModelEditorTemplate>) {
    super();
    if (other) {
      Object.assign(this, other);
    }
  }
}

export const ModelEditorTemplates: { [name: string]: ModelEditorTemplate } = {};

export class EditorPageTemplate {
  title: string;
  backUrl: string;
  models: ModelEditorTemplate[] = [];

  constructor(other?: Partial<EditorPageTemplate>) {
    if (other) {
      Object.assign(this, other);
    }
  }
}

export const EditorPageTemplates: { [name: string]: EditorPageTemplate } = {};
