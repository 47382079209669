import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavController, AlertController, LoadingController } from '@ionic/angular';
import { Countries, Country } from '../../data/paises';
import { Estados, Estado } from '../../data/estados';
import { HttpErrorResponse } from '@angular/common/http';
import * as VisitanteModelos from '../../models/visitantes';
import * as ModelosEstaticos from '../../data/estaticos';
import { Usuario } from 'src/app/models/usuario';
import { WebapiService } from 'src/app/servicios/webapi.service';
import { ConfigService } from 'src/app/servicios/config.service';
import { Giro } from 'src/app/models/comunes';
import { UiService } from 'src/app/servicios/ui.service';
import { EventosService } from 'src/app/servicios/eventos.service';
import { CachedWebapiService } from 'src/app/servicios/cached-webapi.service';
import * as PassGen from 'generate-password-browser';
import { Tools } from 'src/app/servicios/tools';
import { TranslateService } from '@ngx-translate/core';
import { async } from '@angular/core/testing';

// tslint:disable-next-line: max-line-length
const EMAILPATTERN = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

function validaConfirmaPassword(control: FormControl) {
  // tslint:disable-next-line: no-use-before-declare
  const otherPass = control.root.get('pass');
  if (!otherPass) { return null; }
  const isMismatch = control.value !== otherPass.value; //  RegistroVisitantePage.singleton.pageForm.get('pass').value;
  return isMismatch ? { missmatch: { value: control.value } } : null;
}

function passwordNoSoloNumeros(control: FormControl) {
  // tslint:disable-next-line: prefer-const
  let isnum = /^\d+$/.test(control.value);
  return isnum ? { isOnlyNumbers: { value: control.value } } : null;
}

function validaCampoRequeridoDePerfil(page: any, perfil: string | string[], minStrLength?: number) {
  return function validador(control: FormControl) {
    if (!page.pageForm) { return null; }
    const perfilID = page.pageForm.get('perfil_visitante').value;
    // console.log('Perfil ', perfilID);
    let esPerfilSeleccionado = false;
    if (Array.isArray(perfil)) {
      const indx = perfil.indexOf(perfilID);
      esPerfilSeleccionado = indx >= 0;
    } else {
      esPerfilSeleccionado = perfilID === perfil;
    }

    if (esPerfilSeleccionado) {
      if (minStrLength) {
        if (!control.value || !control.value.length) {
          return { required: { value: control.value } };
        }
      } else {
        console.log('Validando campo de perfil ', perfilID, ' control ', control, 'value', control.value);
        if (typeof control.value !== 'number') {
          return { required: { value: control.value } };
        }
      }
    }
    return null;
  };
}

function validatorNacimiento(control: FormControl) {
  const
    isValid = /^(1|2)\d{3}$/.test(control.value)
      && Number(control.value) <= new Date().getFullYear()
      && Number(control.value) > (new Date().getFullYear() - 100);

  return (!isValid) ? { yearIsValid: { value: control.value } } : null;
}

@Component({
  selector: 'app-crear-visitante',
  templateUrl: './crear-visitante.component.html',
  styleUrls: ['./crear-visitante.component.scss'],
})
export class CrearVisitanteComponent implements OnInit {
  @ViewChild('ciudad', { static: false }) ciudad: any;
  @Output() creado = new EventEmitter<Usuario>();
  @Input() hacerLoginAlCrear = true;
  @Input() modoRegistro = false;
  @Input() shortMode = true;
  pageForm: FormGroup;
  passwForm: FormGroup;
  mensajesError: any = {} as any;
  dirtyFields: {} = {};
  cantidades = ModelosEstaticos.Cantidades;

  createSuccess = false;
  preguntaPorTipo = false;
  tipo = 0;
  countryList: any;
  estadosList: any;
  registerData = new VisitanteModelos.Visitante();
  passConfirm: string;
  giros: Giro[] = [];
  ocupacion: VisitanteModelos.Ocupacion[];
  cultivos: VisitanteModelos.Cultivo[] = [];
  ganados: any[] = ModelosEstaticos.Ganado;
  paises: Country[] = Countries;
  estados: Estado[] = Estados;

  formularioDesplegado = false;


  constructor(
    private nav: NavController,
    private alertCtrl: AlertController,
    private ldngCtrl: LoadingController,
    public webapi: WebapiService,
    public eventsServ: EventosService,
    public config: ConfigService,
    public uiServ: UiService,
    public navCtrl: NavController,
    private translateServ: TranslateService,
    public cacheWebapiServ: CachedWebapiService,
    // public datos: DatosProvider
  ) {
    this.countryList = Countries;
    this.estadosList = Estados;
    //  this.ganados = VisitanteModelos.Ganado;
    //  let EMAILPATTERN = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    // tslint:disable-next-line: max-line-length
    const tipo = {
      username: [
        { type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') },
        { type: 'pattern', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.VALIDAR_CORREO') }
      ],
      first_name: [
        { type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') },
        { type: 'pattern', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.VALIDAR_LETRAS') },
        { type: 'maxlength', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.VALIDAR_NOMBRE') }
      ],
      last_name: [
        { type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') },
        { type: 'pattern', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.VALIDAR_LETRAS') },
        { type: 'maxlength', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.VALIDAR_APELLIDO') }
      ],
      /*edad: [
        { type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') },
      ],*/
      nacimiento: [
        { type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') },
        { type: 'yearIsValid', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.VALIDAR_NACIMIENTO') },
      ],
      pais: [
        { type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') },
      ],
      estado: [
        { type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') },
      ],
      /*direccion: [
        { type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') },
      ],*/
      genero: [
        { type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') },
      ],
      telefono: [
        //{ type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') },
        { type: 'maxlength', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.VALIDAR_TELEFONO') },
        { type: 'minlength', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.VALIDAR_TELEFONO') },
        { type: 'pattern', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.VALIDAR_TELEFONO_1') }
      ],
      ciudad: [
        { type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') },
      ],
      perfil_visitante: [
        { type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') },
      ],
      cultivo: [{ type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') }],
      hectareas_1: [{ type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') }],
      ganado: [{ type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') }],
      ganado_list: [{ type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') }],
      pass: [
        { type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') },
        { type: 'minlength', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.VALIDAR_CONTRASEÑA') },
        { type: 'isOnlyNumbers', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.VALIDAR_CONTRASEÑA_1') },
      ],
      passConfirm: [
        { type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') },
        { type: 'missmatch', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.VALIDAR_CONTRASEÑA_2') },
      ],
      old_password: [
        { type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') },
        { type: 'minlength', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.VALIDAR_CONTRASEÑA') },
        { type: 'isOnlyNumbers', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.VALIDAR_CONTRASEÑA_1') },
      ],
      new_password1: [
        { type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') },
        { type: 'minlength', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.VALIDAR_CONTRASEÑA') },
        { type: 'isOnlyNumbers', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.VALIDAR_CONTRASEÑA_1') },
      ],
      new_password2: [
        { type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') },
        { type: 'missmatch', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.VALIDAR_CONTRASEÑA_2') },
      ],
      // carrera: [{ type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') }],
      empresa: [{ type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') }],
      giro: [{ type: 'required', message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.MENSAJE_CAMPO') }],
    };

    this.mensajesError = tipo; // (tipo as any) as MensajeError;
  }

  private construirFormGroup() {
    // TODO:
    this.shortMode = !this.webapi.logeado;

    let fieldControls: { [key: string]: AbstractControl; } = {
      username: new FormControl('', [Validators.required, Validators.pattern(EMAILPATTERN)]),
      first_name: new FormControl('', [Validators.required, Validators.maxLength(80)]),
      last_name: new FormControl('', [Validators.required, Validators.maxLength(80)]),
      registro_en_app: new FormControl(this.config.esCordova),
      fecha_registro: new FormControl(new Date()),
      perfil_visitante: new FormControl('', Validators.required),
      telefono: new FormControl('', [Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^\d*$/), Validators.required]),
    };
    if (!this.shortMode) {
      const longModeFields = {
        genero: new FormControl('', []),
        // edad: new FormControl('', []),
        nacimiento: new FormControl('', validatorNacimiento),
        ciudad: new FormControl('', Validators.required),
        pais: new FormControl('México', Validators.required),
        estado: new FormControl('', (ctrl) => {
          if (!ctrl.parent) { return null; }
          if (ctrl.parent.get('pais').value === 'México') {
            if (!ctrl.value) {
              return { required: { value: ctrl.value } };
            }
          }
          return null;
        }),
        // direccion: new FormControl('', [Validators.required]),
        // perfil_visitante: new FormControl('', Validators.required),
        // Requeridos por pefil
        cultivo: new FormControl(undefined, validaCampoRequeridoDePerfil(this, '4')),
        hectareas_1: new FormControl(undefined, validaCampoRequeridoDePerfil(this, ['4', '7'])),
        ganado: new FormControl(undefined, validaCampoRequeridoDePerfil(this, '5')),
        ganado_list: new FormControl(undefined, validaCampoRequeridoDePerfil(this, '5')),
        giro: new FormControl(undefined, validaCampoRequeridoDePerfil(this, ['1', '6'])),
        // empresa: new FormControl(undefined, [validaCampoRequeridoDePerfil(this, '1', 3)]),
        // carrera: new FormControl(undefined, [validaCampoRequeridoDePerfil(this, '2', 3)]),
        // Sin validar
        cultivo_2: new FormControl(undefined), hectareas_2: new FormControl(undefined), // , hectareas: new FormControl(undefined),
        cultivo_3: new FormControl(undefined), hectareas_3: new FormControl(undefined),
        facebook: new FormControl(false),
        instagram: new FormControl(false),
        whatsapp: new FormControl(false),
        twitter: new FormControl(false),
        youtube: new FormControl(false),
      };
      fieldControls = Object.assign(fieldControls, longModeFields);

      // this.pageForm.addControl('estado', );
    }

    this.pageForm = new FormGroup(fieldControls);



    if (!this.modoRegistro) {
      // Agregar campos para ingresar password
      if (!this.webapi.logeado) {
        this.pageForm.addControl('pass', new FormControl('', [Validators.required, Validators.minLength(8), passwordNoSoloNumeros]));
        this.pageForm.addControl('passConfirm', new FormControl('', [Validators.required, validaConfirmaPassword]));
      }
      // Crear formulario para cambiar password
      this.passwForm = new FormGroup({});
      this.passwForm.addControl('old_password', new FormControl('', [Validators.required]));
      // tslint:disable-next-line: max-line-length
      this.passwForm.addControl('new_password1', new FormControl('', [Validators.required, Validators.minLength(8), passwordNoSoloNumeros]));
      this.passwForm.addControl('new_password2', new FormControl('', [Validators.required, (ctrl) => {
        if (this.passwForm.get('new_password1').value !== ctrl.value) {
          return { missmatch: { value: ctrl.value } };
        } else {
          return null;
        }
      }]));
    }
    this.setupGoogleAutocomplete();
  }

  async ngOnInit() {
    // console.log(this.modoRegistro);
    // console.log(this.cultivos.length, this.giros.length);
    try {
      if (!this.cultivos.length) {
        this.cultivos = await this.webapi.getObjects('cultivos/', VisitanteModelos.Cultivo);
      }
      if (!this.giros.length) {
        this.giros = await this.webapi.getObjects('giros/', Giro);
      }
    } catch (err) {
      this.uiServ.error(err);
    }
    // this.events.subscribe('logout', () => { this.construirFormGroup(); });
    /*this.eventsServ.onLogout.subscribe(() => {
      this.construirFormGroup();
    });*/
    this.construirFormGroup();
  }

  /** Guarda un duplicado de los datos del visitanate para editarlos */
  async editarDatos(visitante: VisitanteModelos.Visitante) {
    if (!visitante) {
      this.uiServ.error('No se puede editar el perfil de visitante de este usuario porque no tiene asignado uno.');
      return;
    }
    // await this.ngOnInit();
    this.registerData = new VisitanteModelos.Visitante();
    Object.assign(this.registerData, visitante);
    if (this.registerData.perfil_visitante !== undefined) {
      this.registerData.perfil_visitante = this.registerData.perfil_visitante.toString() as any;
    }
    if (this.registerData.perfil_visitante !== undefined) {
      this.registerData.genero = this.registerData.genero.toString() as any;
    }
    this.registerData.username = this.registerData.email;
    const clon = Object.assign({}, this.registerData);
    delete clon.email;
    delete clon.id;
    delete clon['edad'];
    delete clon.codigo_qr;
    delete clon['apellidos'];
    delete clon.usuario;
    delete clon['nombre'];
    delete clon.password;
    delete clon.carrera;
    delete clon.empresa;
    if (clon['hectareas'] !== undefined) {
      delete clon['hectareas']; // <- dato borrado
    }
    this.pageForm.setValue(clon);
    console.log('GIRO ', visitante.giro, this.giros);
  }

  selectTipo(tipo: number) {
    this.preguntaPorTipo = false;
    this.tipo = tipo;
  }

  async save() {
    console.log(this.pageForm);
    // Validar formulario
    let badFields = 0;
    for (const controlName in this.pageForm.controls) {
      if (!this.pageForm.controls.hasOwnProperty(controlName)) { continue; }
      const ctrl = this.pageForm.get(controlName);
      ctrl.updateValueAndValidity();
      if (ctrl.invalid) {
        badFields++;
        ctrl.markAsTouched();
        console.log('INVALIDO:' + controlName, ctrl);
      }
      // this.registerData[controlName] = this.pageForm.get(controlName).value;
    }
    if (badFields) {
      this.mensaje(this.translateServ.instant('PERFIL.CREAR_VISITANTE.VALIDAR_FORMULARIO'));
      return;
    }

    // Pasar datos de formgrp a registerData
    const visitanteData: any = {};
    for (const controlName in this.pageForm.controls) {
      if (!this.pageForm.controls.hasOwnProperty(controlName)) { continue; }
      const value = this.pageForm.get(controlName).value;
      if (value !== undefined && value != null) {
        visitanteData[controlName] = value;
        this.registerData[controlName] = value;
      }
      /*if (!this.registerData[controlName]) {
        delete this.registerData[controlName];
      }*/
    }

    console.log(visitanteData);

    // Sanitizar
    visitanteData.username = this.pageForm.get('username').value.toLowerCase();
    visitanteData.email = visitanteData.username;
    this.registerData.username = visitanteData.username;

    // Iniciar dialogo de espera
    const loadingGif = await this.ldngCtrl.create({ message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.REGISTRANDO') });
    await loadingGif.present();

    // Crear usuario
    const nu = new Usuario();
    console.log({ USER_POST_DATA: nu });

    nu.username = visitanteData.username;
    nu.first_name = visitanteData.first_name;
    nu.last_name = visitanteData.last_name;
    if (this.pageForm.get('pass')) {
      nu.password = this.pageForm.get('pass').value;
    }
    nu.visitante = visitanteData;
    if (this.modoRegistro) {
      nu.password = PassGen.generate({ length: 8, numbers: true });
    }

    try {
      let res;
      if (this.webapi.logeado && !this.modoRegistro) {
        // Guardar perfil
        res = await this.webapi.put('visitantes/' + this.registerData.id + '/', this.registerData);
      } else {
        // Registrarse
        res = await this.webapi.post('usuarios/', nu);
      }
      console.log('Registro o edicion de visitante existoso');

      await loadingGif.dismiss();

      if (this.hacerLoginAlCrear) {
        if (!this.webapi.logeado) {
          this.login(res);
        } else {
          const alert = await this.alertCtrl.create({
            message: this.translateServ.instant('PERFIL.CREAR_VISITANTE.GUARDADO_OK'),
            header: this.translateServ.instant('PERFIL.CREAR_VISITANTE.GUARDADO_OK_TITULO')
          });
          await alert.present();
          // Se transfiere la edicion al objeto en memoria
          Object.assign(this.config.usuario.visitante, this.registerData);
        }
      } else {
        const ru = Object.assign(new Usuario(), res) as Usuario;
        // const ru = res as Usuario;
        if (ru.visitante) {
          ru.visitante.first_name = ru.first_name;
          ru.visitante.last_name = ru.last_name;
          ru.visitante.email = ru.username;
          ru.visitante.usuario = ru;
          this.creado.emit(ru);
        } else {
          this.uiServ.error('No se pudo registrar usuario');
        }
      }
      console.log(res);
    } catch (err) {
      loadingGif.dismiss();
      console.log(err);
      this.despliegaMensajeDeError(err);
    }
  }

  async mensaje(texto: string) {
    const msg = await this.alertCtrl.create({
      message: texto,
      buttons: [{
        text: 'Aceptar',
        role: 'cancel'
      }]
    });
    await msg.present();
  }

  llenarDatosDePrueba() {
    this.registerData = new VisitanteModelos.Visitante();
    this.registerData.username = 'pruebas@prueba.com';
    this.registerData.first_name = 'Fulano';
    // this.registerData.password = 'qwer1234';
    this.registerData.last_name = 'aprueba mprueba';
    this.registerData.telefono = '6681245792';
    this.registerData.perfil_visitante = 1;
    // this.registerData.cultivo = '4';
    // this.registerData.hectareas = 17;
    this.registerData.facebook = true;
    this.registerData.twitter = true;
    this.registerData.ciudad = 'Los mochis';
    this.registerData.estado = 'Durango';
    this.registerData.pais = 'México';
    this.registerData.perfil_visitante = 3;
    this.registerData.genero = 3;
    //this.registerData.edad = 25;
    this.registerData.nacimiento = 1991;
    // this.registerData.password = '';
    this.passConfirm = 'qwer1234';
    console.log('REGISTER DATA:', this.registerData);
    this.pageForm.setValue(this.registerData);
  }

  dirtyField(field: string) {
    /*if(fieldName == 'username') {
      this.registerData.username = this.registerData.username.toLowerCase();
    }*/
    // this.dirtyField[fieldName] = true;
  }

  private async login(usr: Usuario) {
    const loginGif = await this.ldngCtrl.create({ message: await this.translateServ.get('PERFIL.REGISTRO_CORRECTO').toPromise() });
    await loginGif.present();
    try {
      const result = await this.webapi.login('visitante', this.registerData.username, this.pageForm.get('pass').value);
      await this.uiServ.continuaSesion(true);
      // await this.config.cargarDatosDeUsuario();
      await this.navCtrl.navigateRoot('noticias', { animated: true });
      loginGif.dismiss();
    } catch (err) {
      console.error(err);
      await loginGif.dismiss();
      const alert = await this.alertCtrl.create({
        message: await this.translateServ.get('PERFIL.REGISTRO_MENSAJE').toPromise(),
        buttons: [
          {
            text: await this.translateServ.get('ACEPTAR').toPromise(),
            role: 'cancel',
            handler: () => {
              this.nav.navigateRoot('inicio', { animated: true });
            }
          }]
      });
      await alert.present();
    }
  }

  async despliegaMensajeDeError(err: HttpErrorResponse) {
    let texto = await this.translateServ.get('PERFIL.REGISTRO_MENSAJE_ERROR').toPromise();
    if (err) {
      if (err.status) {
        texto += ' (' + err.status + ')';
      }
      if (err.status === 404) {
        texto = await this.translateServ.get('PERFIL.SIN_SERVICIO').toPromise();
      } else if (err.status === 400 && err.error) {
        if (err.error.username && err.error.username[0] === 'Ya existe un usuario con ese nombre.') {
          texto = await this.translateServ.get('PERFIL.USUARIO_DUPLICADO').toPromise();
        } else if (err.error.username && err.error.username[0]) {
          texto = err.error.username[0];
        }
      }
    }
    const msg = await this.alertCtrl.create({
      header: 'Error',
      message: texto,
      buttons: [
        { text: await this.translateServ.get('ACEPTAR').toPromise(), role: 'cancel' }
      ]
    });
    await msg.present();
  }

  async cambiarPass() {
    const dlg = await this.ldngCtrl.create({ message: await this.translateServ.get('PERFIL.CAMBIO_CONTRAEÑA').toPromise() });
    await dlg.present();
    try {
      const postData = {
        new_password1: this.passwForm.get('new_password1').value,
        new_password2: this.passwForm.get('new_password2').value,
        old_password: this.passwForm.get('old_password').value,
      };
      const res = await this.webapi.post('rest-auth/password/change/', postData);
      console.log(res);
      await dlg.dismiss();
      this.passwForm.reset();
      await this.uiServ.ok(await this.translateServ.get('PERFIL.CAMBIO_CONTRAEÑA_OK').toPromise());
    } catch (err) {
      console.error(err);
      await dlg.dismiss();
      let message = null;
      if (err && err.error && err.error.status_code) {
        if (err.error.old_password && err.error.old_password.length) {
          if (err.error.old_password[0] === 'Invalid password') {
            message = await this.translateServ.get('PERFIL.ERROR_CONTRAEÑA').toPromise();
          }
        }
        if (err.error.new_password && err.error.new_password.length) {
          message = err.error.new_password;
        }
        if (err.error.new_password2 && err.error.new_password2.length) {
          message = err.error.new_password2[0];
        }
      }
      if (message) {
        this.uiServ.error(message);
      } else {
        this.uiServ.error(err);
      }
    }
  }

  trimFields() {
    let email = this.pageForm.get('username').value as string;
    if (email && email.trim) {
      email = Tools.cleanEmail(email);
      this.pageForm.get('username').setValue(email);
    }
  }

  handleAddressChange(evt) {
    console.log(evt);
  }

  // tslint:disable-next-line: member-ordering
  didSetup = false;
  setupGoogleAutocomplete() {
    if (this.didSetup) { return; }
    this.didSetup = true;
    setTimeout(() => {
      const ionInput = document.getElementById('editar_visitante_direccion');
      if (!ionInput) {
        this.didSetup = false;
        this.setupGoogleAutocomplete();
        return;
      }
      const nativeElement = ionInput.firstChild as any;
      if (!nativeElement) {
        this.didSetup = false;
        this.setupGoogleAutocomplete();
        return;
      }

      console.log(nativeElement);
      const google = (window as any).google;
      const options = {
        // bounds: defaultBounds,
        // componentRestrictions: { country: "us" },
        fields: ['address_components'],
        // origin: center,
        // strictBounds: false,
        types: ['(cities)'],
      };
      const autocomplete1 = new google.maps.places.Autocomplete(nativeElement, options);
      google.maps.event.addListener(autocomplete1, 'place_changed', (arg1, arg2) => {
        // console.log(arg1, arg2, nativeElement.value, autocomplete1.getPlace());
        this.pageForm.get('ciudad').patchValue(nativeElement.value);
        const place = autocomplete1.getPlace();
        // console.log(place);
        if (!place || !place.address_components) {
          return;
        }
        place.address_components.forEach(addc => {
          const types: string[] = addc.types;
          const value = addc.long_name;
          if (types.indexOf('political') === -1) {
            return;
          }

          if (types.indexOf('country') >= 0) {
            this.pageForm.get('pais').patchValue(value);
          }
          if (types.indexOf('administrative_area_level_1') >= 0) {
            this.pageForm.get('estado').patchValue(value);
          }
          /*if (types.indexOf('locality') >= 0) {
            this.pageForm.get('ciudad').patchValue(value);
          }*/
        });
      });
    }, 1000);
  }

  async darDeBaja() {
    const strT = this.translateServ.instant('PERFIL.DAR_DE_BAJA_ALERTA_TITULO');
    const strM = this.translateServ.instant('PERFIL.DAR_DE_BAJA_ALERTA_MSG');
    const okTxt = this.translateServ.instant('ACEPTAR');
    const cancelTxt = this.translateServ.instant('CANCELAR');
    if (await this.uiServ.pregunta(strM, okTxt, cancelTxt, strT)) {
      try {
        const res = await this.webapi.post('usuarios/' + this.config.usuario.id + '/dar_de_baja/');
        console.log(res);
        await this.webapi.logout();
      } catch (e) {
        await this.uiServ.error(e);
      }
    }
  }
}
