// tslint:disable: align
// tslint:disable: max-line-length
import { Injectable, ErrorHandler, EventEmitter } from '@angular/core';
import { ConfigService } from './config.service';
import { AlertController, MenuController, NavController, LoadingController, ToastController } from '@ionic/angular';
import { ExpoConfigMenu, ExpoConfigMenuOpcion } from '../models/menu';
import { Configs } from '../data/opcionesDeMenu';
import { WebapiService } from './webapi.service';
import { CachedWebapiService } from './cached-webapi.service';
import { Router, NavigationStart } from '@angular/router';
import { FullScreenViewer } from 'iv-viewer';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { HttpErrorResponse } from '@angular/common/http';
import { EventosService } from './eventos.service';
import { BitacoraEvento } from '../models/comunes';
import { TranslateService } from '@ngx-translate/core';
import { CurtainService } from './curtain.service';
// import * as jsPDF from 'jspdf';


@Injectable({
  providedIn: 'root'
})
export class UiService {
  menuHabilitado = false;
  menus: ExpoConfigMenu[];
  opcionActiva: ExpoConfigMenuOpcion;
  private lastImgViewerCreated: any;
  supportedLangs = {
    en: 'English',
    es: 'Español'
  };
  get lang(): string {
    return this.translateServ.currentLang;
  }
  set lang(value: string) {
    localStorage.setItem('lang', value);
    this.translateServ.use(value);
  }

  constructor(
    private configServ: ConfigService,
    private menuCtrl: MenuController,
    private navCtrl: NavController,
    private webapiServ: WebapiService,
    private cachedWebapiServ: CachedWebapiService,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private router: Router,
    private eventosServ: EventosService,
    private inAppBrwserCtrl: InAppBrowser,
    private translateServ: TranslateService,
    private curtainServ: CurtainService,
    private loadingCtrl: LoadingController) {
    this.menus = Configs.menus;

    const cLang = localStorage.getItem('lang');
    if (!cLang) {
      const defaultLang = this.translateServ.getBrowserLang();
      if (this.supportedLangs[defaultLang]) {
        this.lang = defaultLang;
      } else {
        this.lang = 'en';
        console.log('Not supoorted language yet ', defaultLang);
      }
    } else {
      this.translateServ.use(cLang);
    }
    this.translateServ.setDefaultLang('es');
    // console.log('Current language:', this.translateServ.currentLang);

    router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        // console.log('NAVIGATION ', e);

        // Hide last viewer created
        try {
          if (this.lastImgViewerCreated && this.lastImgViewerCreated.hide) {
            this.lastImgViewerCreated.hide();
          }
        } catch (e) {
          console.error(e);
        }

        // Update selected option
        const route = e.url.replace('/', '');
        for (const grp of this.menus) {
          for (const opc of grp.opciones) {
            opc.activada = (opc.ruta === route);
            /*if (opc.activada) {
              console.log('OPCION ACTIVA:', opc);
            }*/
          }
        }
      }
    });
  }

  habilitaMenu() {
    this.menuCtrl.enable(true, 'menu_lateral');
    this.menuHabilitado = true;
  }

  deshabilitaMenu() {
    this.menuCtrl.enable(false, 'menu_lateral');
    this.menuHabilitado = false;
  }

  async verificaSesionIniciada() {
    if (this.webapiServ.hasToken()) {
      this.continuaSesion();
    }
  }

  async continuaSesion(acabaDeIniciarSesion?: boolean) {
    this.habilitaMenu();
    try {
      await this.configServ.cargarDatosDeUsuario(acabaDeIniciarSesion);
      this.refreshMenuData();
    } catch (err) {
      this.error(err);
    }
    console.log('red');
    if (this.router.url === '' || this.router.url === '/' || this.router.url === '/inicio' || this.router.url.startsWith('/login')) {
      if (this.configServ.isStaffEntradas) {
        await this.navCtrl.navigateRoot('staff-landing', { animated: false }); console.log('XXXXX');
      } else {
        await this.navCtrl.navigateRoot('noticias', { animated: false });
      }
    }
  }

  /* Esta funcion es el unico codigo en el app que termina la sesion en el app */
  async terminaSesion() {
    const alert = await this.alertCtrl.create({
      header: this.translateServ.instant('SISTEMA.TITULO_CERRAR_SESION'),
      message: this.translateServ.instant('SISTEMA.TEXTO_CERRAR_SESION'),
      buttons: [
        {
          text: this.translateServ.instant('ACEPTAR'), handler: async () => {
            const closeAlert = await this.loadingCtrl.create({
              message: this.translateServ.instant('SISTEMA.CERRANDO')
            });
            try {
              await this.webapiServ.post('rest-auth/logout/');
            } catch (err) {
              console.error(err);
            }
            await closeAlert.present();
            this.curtainServ.show();
            await this.wait(500);
            const usr = this.configServ.usuario;
            this.configServ.borrarDatosDeUsuario();
            this.webapiServ.logout();
            this.deshabilitaMenu();
            await this.cachedWebapiServ.clearCache();
            // await this.navCtrl.navigateRoot('', { animated: false });
            console.log('Sesión cerrada');
            closeAlert.dismiss();
            this.eventosServ.onUserLogout.emit(usr);
            /* setTimeout(() => {
              window.location.reload();
            }, 1000);*/
          }
        },
        {
          text: await this.translateServ.get('SISTEMA.CANCELAR').toPromise(), role: 'cancel'
        }
      ]
    });
    await alert.present();
  }

  private refreshMenuData() {
    this.menus.forEach(menuGrp => {
      menuGrp.opciones.forEach(menuOpc => {
        if (menuOpc.grupos) {
          let hidden = false;
          menuOpc.grupos.forEach(gpoName => {
            if (this.configServ.usuario.hasGroup(gpoName)) {
              hidden = true;
            }
          });
          menuOpc.ocultar = !hidden;
        }
      });
    });
  }

  getMenuOption(menu: string, option: string) {
    return this.menus.find(iter => iter.id === menu).opciones.find(iter => iter.id === option);
  }

  /** Funcion que verifica que exista cordova en el entorno de la app, si no existe despliega un mensaje de error.
   * Regresa si el entorno no tiene cordova.
   */
  async noCordovaError() {
    if (!this.configServ.esCordova) {
      const alert = await this.alertCtrl.create({
        header: await this.translateServ.get('SISTEMA.ERROR').toPromise(),
        message: await this.translateServ.get('SISTEMA.ERROR_MENSAJE').toPromise(),
        buttons: [{ text: await this.translateServ.get('SISTEMA.ACEPTAR').toPromise(), role: 'cancel' }]
      });
      await alert.present();
    }
    return !this.configServ.esCordova;
  }

  /** Despliega mensaje de error al usuario, detecta el contenido automaticamente */
  async error(content: any) {
    console.error('Mensaje de error desplegado, contenido de error :', content);
    let mess = this.translateServ.instant('SISTEMA.PROBLEMA_INESPERADO');
    if (content) {
      if (content instanceof HttpErrorResponse) {
        const httpError = content as HttpErrorResponse;
        if (httpError.error && httpError.error.mensaje) {
          mess = httpError.error.mensaje;
        } else if (httpError.error && httpError.error.detail) {
          mess = httpError.error.detail;
        } else if (httpError.status !== undefined) {
          switch (httpError.status) {
            case 0: mess = await this.translateServ.get('SISTEMA.ERROR_MENSAJE_0').toPromise(); break;
            case 400: mess += await this.translateServ.get('SISTEMA.ERROR_MENSAJE_400').toPromise(); break;
            case 401: mess += await this.translateServ.get('SISTEMA.ERROR_MENSAJE_401').toPromise(); break;
            case 403: mess += await this.translateServ.get('SISTEMA.ERROR_MENSAJE_403').toPromise(); break;
            case 404: mess += await this.translateServ.get('SISTEMA.ERROR_MENSAJE_404').toPromise(); break;
            case 406:
              mess = await this.translateServ.get('SISTEMA.ERROR_MENSAJE_406').toPromise();
              if (httpError.error && httpError.error.message) {
                mess += '. ' + httpError.error.message;
              }
              mess += ' (#406).';
              break;
            case 500:
              if(httpError.error && httpError.error.indexOf('ProtectedError')>=0) {
                mess = 'No es posible eliminar este dato ya que esta relacionado con otro.';
              } else {
                mess += ' (#500).'; 
              }
              break;
            case 502: case 503:
              mess += await this.translateServ.get('SISTEMA.ERROR_MENSAJE_502').toPromise(); break;
            default: mess += ' (#' + content.status + ')'; break;
          }
          if (this.configServ.isStaff && (httpError.status !== 0 && httpError.status !== 502 && httpError.status !== 503)) {
            mess += await this.translateServ.get('SISTEMA.ERROR_MENSAJE_503').toPromise();
          }
        }
      } else {
        if (typeof content === 'string') {
          mess = content;
        }
      }
    }
    this.enviarEventoError(mess, content);
    const alert = await this.alertCtrl.create({
      header: await this.translateServ.get('LO_SENTIMOS').toPromise(),
      message: mess,
      buttons: [{ text: await this.translateServ.get('ACEPTAR').toPromise(), role: 'cancel' }]
    });
    await alert.present();
    await alert.onDidDismiss();
  }

  async alert(msg: string, header?: string) {
    if (!header) { header = await this.translateServ.get('ADVERTENCIA').toPromise(); }
    const alert = await this.alertCtrl.create({
      header,
      message: msg,
      buttons: [{ text: await this.translateServ.get('ACEPTAR').toPromise(), role: 'cancel' }]
    });
    await alert.present();
    await alert.onDidDismiss();
  }

  zoomImagen(url: string, urlBig?: string) {
    if (!url) { return; }
    const viewer = new FullScreenViewer({});
    if (url && urlBig) {
      viewer.show(url, urlBig);
      this.lastImgViewerCreated = viewer;
    } else {
      viewer.show(url);
      this.lastImgViewerCreated = viewer;
    }
  }

  abreLink(url: string) {
    this.inAppBrwserCtrl.create(url, '_system'); /*return;
    if (this.configServ.esCordova) {
      this.inAppBrwserCtrl.create(url, '_system');
    } else {
      window.open(url);
    }

    const cordova: any = window['cordova'];
    cordova.InAppBrowser.open(url, '_system');
    // window.open(url, '_system');*/
  }

  /** Crea y despliega un mensaje con animación de cargando, se es responsable de cerrarlo llamando dismiss en el objeto regresado! */
  async cargando(texto?: string): Promise<HTMLIonLoadingElement> {
    if (!texto) {
      texto = await this.translateServ.get('SISTEMA.CARGANDO').toPromise();
    }
    const dlg = await this.loadingCtrl.create({ message: texto });
    await dlg.present();
    return dlg;
  }

  /** Mensaje de texto que no detiene el flujo de la app ni la interfaz */
  popup(texto: string, duracion?: number) {
    if (duracion === undefined) { duracion = 3000; }
    this.toastCtrl.create({ message: texto, duration: duracion })
      .then((val) => { val.present(); })
      .catch((err) => { this.error(err); });
    // prms.dis
    // await tst.present();
  }

  async pregunta(question: string, okBtnTxt?: string, cancelBtnTxt?: string, headerTxt?: string): Promise<boolean> {
    //if (!okBtnTxt) { okBtnTxt = 'Aceptar'; }
    if (!okBtnTxt) { okBtnTxt = await this.translateServ.get('ACEPTAR').toPromise(); }
    //if (!cancelBtnTxt) { cancelBtnTxt = 'Cancelar'; }
    if (!cancelBtnTxt) { cancelBtnTxt = await this.translateServ.get('CANCELAR').toPromise(); }
    const dlg = await this.alertCtrl.create({
      message: question,
      header: headerTxt,
      buttons: [
        { text: cancelBtnTxt, role: 'cancel' },
        { text: okBtnTxt, role: 'ok' }]
    });
    await dlg.present();
    const result = await dlg.onDidDismiss();
    if (result.role === 'ok') {
      return true;
    } else {
      return false;
    }
  }

  async ok(texto?: string, title?: string) {
    const msg = texto || await this.translateServ.get('INICIO.PETICION_CORRECTA').toPromise();
    //const head = title || 'Correcto';
    const head = title || await this.translateServ.get('CORRECTO').toPromise();
    //const gif = await this.alertCtrl.create({ message: msg, header: head, buttons: [{ text: 'Aceptar', role: 'cancel' }] });
    const gif = await this.alertCtrl.create({ message: msg, header: head, buttons: [{ text: await this.translateServ.get('ACEPTAR').toPromise(), role: 'cancel' }] });
    await gif.present();
    await gif.onDidDismiss();
  }

  wait(ms: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  }

  enviarEventoError(message: string, content: any) {
    const evtBit = new BitacoraEvento();
    const evtBitMessage: any = {};
    evtBitMessage.fecha = (new Date()).toISOString();
    evtBitMessage.href = window.location.href;
    try {
      evtBit.stackTrace = (Error().stack);
      if (content) {
        if (content instanceof HttpErrorResponse) {
          if (content.url && content.url.endsWith('api-token-auth/')) { return; }

          evtBit.codigo = content.status;
          evtBitMessage.message = content.message;
          evtBitMessage.error = content.error;
          evtBitMessage.url = content.url;
          evtBitMessage.status = content.status;

        } else if (content instanceof Error) {
          evtBitMessage.message = content.message;
          evtBit.stackTrace = content.stack;
          evtBit.codigo = -1;
        } else {
          if (content === 'No puede iniciar sesión con las credenciales proporcionadas.') { return; }
          evtBit.codigo = -2;
          try {
            evtBit.mensaje = JSON.stringify(content);
          } catch (err) {
            try { evtBit.mensaje = 'Falló serialización de HttpErrorResponse, razon:' + JSON.stringify(err); } catch (err) { }
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
    try {
      evtBitMessage.mess = message;
      evtBit.mensaje = JSON.stringify(evtBitMessage);
    } catch (err) {
      try { evtBit.mensaje = 'Falló serialización de HttpErrorResponse, razon:' + JSON.stringify(err); } catch (err) { }
    }
    try {
      this.webapiServ.post(BitacoraEvento.Repo.endpoint, evtBit)
        .then((res) => {
          console.log('Evento enviado a backend!', res);
        })
        .catch((err) => {
          console.error('Falló envío de evento al backend!');
        });
    } catch (err) {
      console.error(err);
    }
  }

  getCurrentLanguage() {
    return this.translateServ.currentLang;
  }

  refreshWithoutCurtain() {
    this.curtainServ.skipCurtain();
    location.reload();
  }

  async printElement(id: string, title?: string) {
    // await this.wait(100);
    const printBlock = document.getElementById(id);
    const ogParent = printBlock.parentElement;
    document.body.appendChild(printBlock);


    const ogTitle = window.document.title;
    if (title) {
      window.document.title = title;
    }

    const ogPositon = document.body.style.position;
    document.body.style.position = 'unset';
    const ogOverflow = document.body.style.overflow;
    document.body.style.overflow = 'visible';
    const appRoot = document.body.getElementsByTagName('app-root')[0] as HTMLElement;
    appRoot.style.display = 'none';
    await this.wait(10);
    /*alert(printBlock.clientHeight);
    const bodyOGHeight = document.body.clientHeight;
    alert(document.body.clientHeight);
    document.body.style.height = printBlock.clientHeight + 'px';
    await this.wait(100);
    alert(document.body.clientHeight);*/

    window.print();

    await this.wait(10);
    if (title) {
      window.document.title = ogTitle;
      // document.body.style.height = bodyOGHeight + 'px';
    }
    document.body.style.position = ogPositon;
    document.body.style.overflow = ogOverflow;
    appRoot.style.display = 'unset';
    ogParent.appendChild(printBlock);
  }

  /*
  async toPDF(id: string, title?: string) {
    const printBlock = document.getElementById(id);
    if (!title) {
      title = window.document.title;
    }

    await this.wait(10);


    const doc = new jsPDF.jsPDF('p', 'mm', 'a4');

    await new Promise((res: any, rej: any) => {
      doc.html(printBlock, {
        html2canvas: {
          async: true,
          // width: 600,
          // width: 200,
          // windowWidth: 600
        },
        margin: 0,
        windowWidth: 600,
        width: 500,
        callback: () => {
          doc.save(title + '.pdf');
          res();
        }
      });
    });
  }*/

}
