import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { WebapiService } from './webapi.service';
import { UiService } from './ui.service';
import { ConfigService } from './config.service';
import { CachedWebapiService } from './cached-webapi.service';
import { Usuario } from '../models/usuario';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public uiServ: UiService,
    public navCtrl: NavController,
    public configServ: ConfigService,
    public webapiServ: WebapiService,
    private cachedWebapiServ: CachedWebapiService,
  ) { }

  /**
   * @description Valida la sesion de usuario activo mediante la verficacion del token
   * @returns '{Promise<boolean>}
   */
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {

    const IsValidToken = await this.webapiServ.verifyToken();

    if (!IsValidToken) {
      this.uiServ.deshabilitaMenu();
      this.navCtrl.navigateRoot('');
      return false;
    }

    const
      usrs = await this.configServ.usuarioAsync.getValue(),
      allowedRuotes = [];

    this.uiServ.menus
      .forEach((menuGrp => {
        if (usrs.hasGroup(menuGrp.perfil) && !menuGrp.disabled) {
          allowedRuotes.push(
            ...menuGrp.opciones
              .map(menuOpc => (!menuOpc.ocultar || menuOpc.interno) ? `/${menuOpc.ruta}` : '')
              .filter(menuOpc => menuOpc !== '')
          );
        }
      }));

    if ((state.url.split('/').length - 1) > 1) {
      return true;
    } else {
      return allowedRuotes.includes(state.url);
    }
  }
}
