
// tslint:disable: member-ordering
// tslint:disable: variable-name
// tslint:disable: max-line-length
import { Dato } from './dato';
import { Producto, Boleto } from './comunes';
import { Expositor } from './expositores';


export class Compras extends Dato {
  id: number;
  fecha: string;
  Concepto: string;
  pedido: boolean;
  atendido: boolean;
  subtotal: number;
  iva: number;
  total: number;
  requiere_factura: boolean;
  facturado: boolean;
  pagado: boolean;
  rfc: string;
  razon_social: string;
  status: CompraEstado;
  link_pago: string;
  edicion: number;
  usuario: number;
  expositor: any;
  metodo_de_pago: string;
  uso_del_cfdi: string;
  forma_de_pago: string;
  tipo_de_pago: number | TiposDePagos;
}

export enum CompraEstado {
  APROBADA = 1, ESPERA = 2, CANCELADA = 3, BLOQUEADA = 4, VENCIDA = 5
}

export class ProductosComprados extends Dato {
  precio: number;
  cantidad: number;
  compra: number;
  producto: number;
  boleto: number;
}

export class TiposDePagos extends Dato {
  nombre: string;
  clave: string;
  imagen: string;
  tipo_proceso: number;
  nombre_html: string;
}

export class CompraJSONPeticionConcepto {
  producto: number;
  cantidad: number;
  boleto?: number;
}
export class CompraJSONPeticion {
  conceptos: CompraJSONPeticionConcepto[];
  tipo_pago: string;
  pedido: boolean;
  carrito: number;
}

export class CarritoConcepto {
  producto: Producto;
  cantidad: number;
  boleto?: Boleto;
  subtotal: number;
}

export enum TiposDeCarrito {
  BoletosMagistral = 1,
  Pedidos = 2,
  Normal = 3,
}

export class Carrito extends Dato {
  tipo: TiposDeCarrito; // (boleto magistral, pedido, normal)
  texto: string; // texto(para json de productos)

  _conceptos: CarritoConcepto[] = [];
  _total = 0;

  deserialize() {
    if (this.texto) {
      this._conceptos = JSON.parse(this.texto);
    } else {
      this._conceptos = [];
    }
  }

  serialize() {
    this.texto = JSON.stringify(this._conceptos);
  }

  recalc() {
    this._total = 0;
    for (const cnc of this._conceptos) {
      const price = parseFloat(cnc.producto.precio);
      this._total += price * cnc.cantidad;
    }
  }

  addProduct(prod: Producto, count: number, boleto?: Boleto) {
    if (count === 0) {
      throw new Error('No se puede agregar 0 productos');
    }
    const cnc = new CarritoConcepto();
    cnc.producto = prod;
    cnc.cantidad = count;
    cnc.subtotal = parseFloat(prod.precio) * count;
    if (boleto) {
      cnc.boleto = boleto;
    }
    this._conceptos.push(cnc);
    this.recalc();
    this.serialize();
  }

  addConcept(cnc: CarritoConcepto) {
    this._conceptos.push(cnc);
    this.recalc();
    this.serialize();
  }

  removeConcept(concept: CarritoConcepto) {
    const indx = this._conceptos.indexOf(concept);
    if (indx >= 0) {
      this._conceptos.splice(indx, 1);
      this.recalc();
      this.serialize();
    } else {
      throw new Error('Concepto no encontrado en carrito');
    }
  }

  conceptosJSON(): CompraJSONPeticionConcepto[] {
    const res: CompraJSONPeticionConcepto[] = [];
    for (const cnc of this._conceptos) {
      const val: CompraJSONPeticionConcepto = { cantidad: cnc.cantidad, producto: cnc.producto.id };
      if (cnc.boleto) {
        val.boleto = cnc.boleto.id;
      }
      res.push(val);
    }
    return res;
  }

  removeBoleto(blt: Boleto) {
    const prod = this._conceptos.find(cnc => cnc.boleto && cnc.boleto.id === blt.id);
    if (prod) {
      this.removeConcept(prod);
    }
  }

  syncProducts(productList: Producto[]) {

  }
}
