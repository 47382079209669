export class Country {
  val: string;
  t: string;
}

export const Countries: Country[] = [
  { val: 'MX', t: 'México' },
  { val: 'US', t: 'Estados Unidos' },
  { val: 'CA', t: 'Canadá' },
  { val: 'CL', t: 'Chile' },
  { val: 'AR', t: 'Argentina' },
  { val: 'BR', t: 'Brasil' },
  { val: 'CO', t: 'Colombia' },
  { val: 'PE', t: 'Perú' },
  { val: 'OTRO', t: 'Otros' }
];
