import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { Usuario } from '../models/usuario';

@Injectable({
  providedIn: 'root'
})
export class EventosService {
  // onUserLogout: EventEmitter<any> = new EventEmitter<any>();
  onUserLoaded = new EventEmitter<Usuario>();
  onUserLogin = new EventEmitter<Usuario>();
  onUserLogout = new EventEmitter<Usuario>();

  constructor() { }
}
