import { Injectable, Injector } from '@angular/core';
// import { FirebaseX } from '@ionic-native/firebase-x';
import { Platform, ToastController, AlertController } from '@ionic/angular';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { ConfigService } from './config.service';
import { EventosService } from './eventos.service';
import { Usuario } from '../models/usuario';
import { environment } from 'src/environments/environment';
import { UiService } from './ui.service';
import { NavigationStart, Router } from '@angular/router';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';

@Injectable({
  providedIn: 'root'
})
export class CloudService {
  tokenAquired: boolean;
  deviceToken: string;
  logEvents = true;
  private fbWeb: AngularFireAnalytics;
  private fbNative: FirebaseX;

  constructor(
    private injector: Injector,
    private platform: Platform,
    private uiServ: UiService,
    private eventsServ: EventosService,
    private configServ: ConfigService,
    private alertCtrl: AlertController,
    private router: Router) {
  }

  init() {

    /* Desactivado Firebase nativo por conflictos de librería
    if (this.platform.is('cordova')) {
      this.fbNative = this.injector.get(FirebaseX);
      this.initNativeMode().then(() => {
        this.log('Firebase native initialized');
      }).catch((err) => {
        console.error(err);
      });
    } else {
      */
      this.fbWeb = this.injector.get(AngularFireAnalytics);
      this.initWebMode().then(() => {
        this.log('Firebase web initialized');
      }).catch((err) => {
        console.error(err);
      });
    // }
  }

  private async initWebMode() {
    // Enable
    const app = await this.fbWeb.app;
    // console.log(app);
    this.fbWeb.setAnalyticsCollectionEnabled(environment.analyticsEnabled);

    // User this.fbWeb.setUserId(this.configServ.usuario.id.toString());
    this.eventsServ.onUserLogin.subscribe((user: Usuario) => {
      this.fbWeb.setUserId(user.id.toString());
      this.logEvent('user_login');
    });
    this.eventsServ.onUserLogout.subscribe((user: Usuario) => {
      this.logEvent('user_logout');
      this.fbWeb.setUserId(undefined);
    });

    // Page change
    this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this.fbWeb.setCurrentScreen(e.url);
      }
    });
  }

  private async initNativeMode() {
    // await this.configServ.usuarioAsync.getValue();

    // Notifications
    if (environment.allowPushNotifications) {
      await this.initFCM();
    }

    // Enable
    this.fbNative.setAnalyticsCollectionEnabled(environment.analyticsEnabled);
    this.fbNative.setCrashlyticsCollectionEnabled(environment.crashlyticsEnabled);

    // User this.fbNative.setUserId(this.configServ.usuario.id.toString());
    this.eventsServ.onUserLogin.subscribe((user: Usuario) => {
      this.fbNative.setUserId(user.id.toString());
      this.logEvent('user_login', { id: user.id });
      if (environment.crashlyticsEnabled) {
        this.fbNative.setCrashlyticsUserId(user.id.toString());
      }
    });
    this.eventsServ.onUserLogout.subscribe((user: Usuario) => {
      this.logEvent('user_logout', { id: -1 });
      this.fbNative.setUserId(undefined);
    });

    // Page change
    this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this.fbNative.setScreenName(e.url);
      }
    });
  }

  private async initFCM() {
    try {
      const hasPermisson = await this.fbNative.hasPermission();
      if (!hasPermisson) {
        return;
      }

      // Configure device token
      let token: string;
      token = await this.fbNative.getToken();
      this.storeDeviceToken(token);
      this.deviceToken = token;
      this.fbNative.onTokenRefresh().subscribe((newToken) => {
        this.storeDeviceToken(newToken);
      });

      // Configure topic listening
      this.fbNative.subscribe('general').
        then((data) => { this.log('Escuchando topico: general'); }).
        catch((err) => { this.logErr(err); });
      this.fbNative.onMessageReceived().subscribe((data) => this.newNotificationArrived(data), (err) => { this.logErr(err); });
      this.eventsServ.onUserLoaded.subscribe((user: Usuario) => {
        this.fbNative.subscribe('sesion').
          then((data) => { this.log('Escuchando topico: sesion'); }).
          catch((err) => { this.logErr(err); });
        if (user.hasGroup('visitante')) {
          this.fbNative.subscribe('visitante').
            then((data) => { this.log('Escuchando topico: visitante'); }).
            catch((err) => { this.logErr(err); });
        }
        if (user.hasGroup('expositor')) {
          this.fbNative.subscribe('expositor').
            then((data) => { this.log('Escuchando topico: expositor'); }).
            catch((err) => { this.logErr(err); });
        }
      });
      this.eventsServ.onUserLogout.subscribe((user: Usuario) => {
        this.fbNative.unsubscribe('sesion').
          then((data) => { this.log('NO Escuchando topico: sesion'); }).
          catch((err) => { this.logErr(err); });
        if (user.hasGroup('visitante')) {
          this.fbNative.unsubscribe('visitante').
            then((data) => { this.log('NO Escuchando topico: visitante'); }).
            catch((err) => { this.logErr(err); });
        }
        if (user.hasGroup('expositor')) {
          this.fbNative.unsubscribe('expositor').
            then((data) => { this.log('NO Escuchando topico: expositor'); }).
            catch((err) => { this.logErr(err); });
        }
      });
      // this.log('firebaseX inicializado');
    } catch (err) {
      this.log(err);
    }
  }


  private log(msg: string, obj?: any) {
    if (!this.logEvents) { return; }
    if (obj) {
      console.log('[CloudServ]', msg, obj);
    } else {
      console.log('[CloudServ]', msg);
    }
  }

  private logErr(msg: string, obj?: any) {
    // if (!this.logEvents) { return; }
    if (obj) {
      console.error('[CloudServ]', msg, obj);
    } else {
      console.error('[CloudServ]', msg);
    }
  }

  logEvent(message: string, data?: any) {
    if (this.fbNative) {
      if (!data) {
        data = {};
      }
      this.fbNative.logEvent(message, data).then((res) => {
        if (environment.production) { this.log('Sent message ' + message, res); }
      }).catch((err) => {
        if (environment.production) { console.log('Failed sending anl message: ', message, data); }
        this.logErr(err);
      });
    } else {
      this.fbWeb.logEvent(message, data).then((res) => {
        if (environment.production) { this.log('Sent message ' + message, res); }
      }).catch((err) => {
        if (environment.production) { console.log('Failed sending anl message: ', message, data); }
        this.logErr(err);
      });
    }
  }


  private storeDeviceToken(token: string) {
    this.deviceToken = token;
    this.log('Device token set to ', token);
    // store in DB?
  }

  private newNotificationArrived(data: any) {
    this.log('New notification arrived: ', data);
    if (data.wasTapped) {
      this.log('Received in background');
      // this.router.navigate([data.landing_page, data.price]);
    } else {
      this.log('Received in foreground');
      // this.router.navigate([data.landing_page, data.price]);
    }
    // Display alert notificaciones
    if (data.tipo === 'alerta') {
      this.alertCtrl.create({ message: data.message, header: 'Alerta Expoceres', buttons: [{ text: 'Aceptar', role: 'close' }] })
        .then((stuff) => { stuff.present(); })
        .catch((err) => { this.logErr(err); });
    }
  }


}

/*

{
  "notification":{
    "title":"Expoceres",
    "body":"Notificacion importante sobre la Expoceres 2020",
    "sound":"default",
    "click_action":"firebaseX_PLUGIN_ACTIVITY",
    "icon":"firebaseX_push_icon"
  },
  "data":{
    "tipo":"alerta",
    "message":"Este es un mensaje enviado desde una notificación a todas las apps instaladas!"
  },
    "to":"/topics/general",
    "priority":"high",
    "restricted_package_name":""
}

*/

/*private async initWrap() {

// Initialize Android or iOS device for firebaseX
if (this.configServ.esCordova) {
this.initDevice();
if (this.tokenAquired) {
this.onNotifications().subscribe(
(msg) => {
if (this.platform.is('ios')) {
this.presentToast(msg.aps.alert);
} else {
this.presentToast(msg.body);
}
});
}
}

const result = await this.firebase.logEvent('Init', this.deviceToken);
this.log('Cloud service initialized', result);
}

private async initDevice() {
this.log('Initializing device for firebase...');
let token;
if (this.platform.is('android')) {
token = await this.firebase.getToken();
}

if (this.platform.is('ios') || this.platform.is('desktop')) {
token = await this.firebase.getToken();
await this.firebase.grantPermission();
}
if (token) {
this.deviceToken = token;
this.log('Device token aquired:', token);
this.tokenAquired = true;
try {
const devicesRef = this.afs.collection('devices');
const data = {
token,
userId: 'testUserId'
};
await devicesRef.doc(token).set(data);
} catch (err) {
this.logErr(err);
}
} else {
this.tokenAquired = false;
this.log('Device token not aquired');
}
}

onNotifications() {
return this.firebase.onNotificationOpen();
}

private async presentToast(message) {
const toast = await this.toastCtrl.create({
message,
duration: 3000
});
toast.present();
}
}
*/